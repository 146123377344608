import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, industryStructureDataAtom, selectedFiltersAtom, selectedSpatialLayerAtom, yearsRangeAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import { numberFormatter } from "../../shared/utils";
import styles from "../../pdfs/styles/PdfStyles";

function IndustryStructurePdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(industryStructureDataAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 1500);
  }, []);

  return (
    <>
      {isLoading || !yearsRange ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#F0A400' }}>Overview</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>INDUSTRY STRUCTURE</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
              </View>
              <View>
                <Text style={styles.paragraph}>
                  Which sectors are the biggest employers in {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  The top 10 sectors ranked here are most important for jobs and livelihoods. The economic outlook depends on the capacity of firms in these sectors to expand, adapt and innovate or otherwise risk becoming a drag on economic performance. Non-tradable sectors such as government services tend to be stable but are inherently constrained by the size of local economy (i.e. local demand). Tradable sectors can compete in surrounding (and even international) markets but must be competitive to thrive.
                </Text>
              </View>
              <View style={styles.section}>
                <Image style={{ ...styles.image, marginTop: 5, marginBottom: 5 }} src={chartImageUrl} />
              </View>
              <View style={styles.legendSection}>
                <Text style={styles.legendHeading}>Acronym - Full Name</Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Gov - Public administration and defense, compulsory social security
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Admin Serv - Administrative and support activities
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Retail - Wholesale and retail trade, repair of motor vehicles and motorcycles
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Manu - Manufacturing
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Constru - Construction
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Educ - Education
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Finance - Finance
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Soc Serv - Human health and social work activities
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Prof - Professional, scientific and Technical activities
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Logistics - Transportation and storage
                </Text>
                <Text style={{ ...styles.legendText, marginBottom: 5 }}>
                  Other - Other Service Activities
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef}>
          <ResponsiveContainer width={1100} height={500}>
            <BarChart
              data={data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 30,
              }}
            >
              <CartesianGrid strokeDasharray='3' vertical={false} />
              <XAxis dataKey='activity_shortname' label={{ value: data[0]?.x_axis_label, dy: 34 }} tickCount={11} interval={0} />
              <YAxis tickFormatter={(value) => numberFormatter(value, 2)} label={{ value: data[0]?.y_axis_label, angle: -90, dx: -50 }} />
              <Bar dataKey='value' fill='#e69d00' />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

export default IndustryStructurePdf;
