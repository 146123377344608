import dayjs from "dayjs";
import { useState, useEffect } from "react";

function DominantSectors() {
	// Descoped
	return (
		<div className='flex'>
			<div className='w-full'>
				<div className='text-3xl px-10 py-10 font-bold flex justify-center'>
					Dominant Sectors {dayjs().subtract(9, "year").format("YYYY")} - {dayjs().subtract(2, "year").format("YYYY")}
				</div>
			</div>
		</div>
	);
}

export default DominantSectors;
