import React from "react";
import background from "../../images/background-optimised.jpg";
import Footer from "../../shared/components/Footer";
import seadboxlogo from "../../images/about-the-project-sead-logo.png";
import primarypartnerlogos from "../../images/about-primary-partners-logos.png";
import nationalpartnerlogos from "../../images/about-national-partners-logos.png";
import technicalpartnerlogos from "../../images/about-technical-partners-logos.png";
import { Scrollbar } from "react-scrollbars-custom";

const AboutUs = () => {
  return (
    <div className="relative">
      <div
        style={{
          height: "calc(100vh - 80.88px)",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-between overflow-y-scroll no-scrollbar"
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* https://www.npmjs.com/package/react-scrollbars-custom, https://github.com/malte-wessel/react-custom-scrollbars/issues/180 */}
        <Scrollbar
          style={{ width: "100%", height: "100%" }}
          thumbYProps={{
            renderer: ({ style, ...props }) => {
              const { elementRef, ...restProps } = props;
              return <div {...restProps} style={{ ...style, backgroundColor: "#FFF", width: "8px", opacity: "0.5" }} ref={elementRef} className="tHuMbY" />;
            },
          }}
        >
          <div className="flex flex-col items-center z-10 p-5 pb-20">
            <h1 className="text-7xl text-white p-8">ABOUT THE PROJECT</h1>
            <div className="bg-white bg-opacity-100 border rounded p-6 border-0 flex flex-col items-center" style={{ width: "80%" }}>
              <p className="font-sans m-10">
                <img style={{ width: "230px" }} src={seadboxlogo} alt="Sead vertical unboxed logo" />
              </p>
              <h3
                style={{
                  fontSize: 16,
                  color: "grey",
                  width: "80%",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
                className="font-sans"
              >
                Why spatial economic data?
              </h3>
              <p style={{ color: "grey", width: "80%" }} className="font-sans">
                There are serious deficiencies in the coverage and quality of spatial economic data in South Africa. This is a handicap for policymakers and government officials who lack reliable information on which to base crucial decisions. Robust data is also essential for monitoring changing economic conditions and sectoral and spatial shifts over time. A lack of reliable data is also a handbrake on research (and advocacy) about the important role of cities in economic development. The
                socio-spatial legacy of apartheid continues to undermine the performance of South Africa’s cities and regions. There is a serious risk that the country’s economic problems are misdiagnosed without a spatial lens.
              </p>
              <h3
                style={{
                  fontSize: 16,
                  color: "grey",
                  width: "80%",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
                className="font-sans"
              >
                A breakthrough mining tax records
              </h3>
              <p style={{ color: "grey", width: "80%" }} className="font-sans">
                The Human Sciences Research Council and National Treasury: City Support Programme entered into a partnership in July 2021 in order to plug this gap in the availability and accessibility of spatial economic data. This helped contribute to a key breakthrough in spatial economic data for the country: the mining of administrative tax records into a Spatial Tax Panel. This was possible through the support and close collaboration of the South African Revenue Services, UNU-WIDER (who
                manage a Secure Data Facility for tax data at the National Treasury) and Statistics South Africa.
              </p>
              <h3
                style={{
                  fontSize: 16,
                  color: "grey",
                  width: "80%",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
                className="font-sans"
              >
                A broader programme of work
              </h3>
              <p style={{ color: "grey", width: "80%" }} className="font-sans">
                These efforts have culminated in the launch of ‘Spatial Economic Activity Data: South Africa’ (SEADsa) which is a consortium of stakeholders who share a common interest in understanding the spatial economy of South Africa. The programme continues to pioneer work in this domain including administrative data mining (including adding new sources), applied research, peer-to-peer learning, data training as well as advocacy for a focus on city regions and the spatial economy.
              </p>
              <p style={{ color: "grey", width: "80%" }} className="font-sans">
                The spatial tax portal was launched in 2023.
              </p>

              <h2
                style={{
                  fontSize: 38,
                  color: "grey",
                  width: "80%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                className="font-sans m-10 mt-20"
              >
                PARTNERS
              </h2>
              <h3
                style={{
                  fontSize: 18,
                  color: "grey",
                  width: "80%",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "1px solid grey",
                  lineHeight: "0.1em",
                }}
                className="font-sans m-10 mt-20"
              >
                <span
                  style={{
                    background: "#fff",
                    padding: "0 10px",
                  }}
                >
                  Primary
                </span>
              </h3>
              <p style={{ color: "grey", textAlign: "center" }} className="font-sans">
                <img style={{}} src={primarypartnerlogos} alt="Primary Partners logos" />
              </p>
              <h3
                style={{
                  fontSize: 18,
                  color: "grey",
                  width: "80%",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "1px solid grey",
                  lineHeight: "0.1em",
                }}
                className="font-sans m-10 mt-20"
              >
                <span
                  style={{
                    background: "#fff",
                    padding: "0 10px",
                  }}
                >
                  National
                </span>
              </h3>
              <p style={{ color: "grey", textAlign: "center" }} className="font-sans">
                <img style={{}} src={nationalpartnerlogos} alt="National Partners logos" />
              </p>
              <h3
                style={{
                  fontSize: 18,
                  color: "grey",
                  width: "80%",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "1px solid grey",
                  lineHeight: "0.1em",
                }}
                className="font-sans m-10 mt-20"
              >
                <span
                  style={{
                    background: "#fff",
                    padding: "0 10px",
                  }}
                >
                  Technical (builders of this portal)
                </span>
              </h3>
              <p style={{ color: "grey", textAlign: "center" }} className="font-sans">
                <img style={{}} src={technicalpartnerlogos} alt="Technical Partners logos" />
              </p>

              <h2
                style={{
                  fontSize: 38,
                  color: "grey",
                  width: "80%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                className="font-sans m-20"
              >
                CONTACT
              </h2>
              <h3
                style={{
                  fontSize: 16,
                  color: "grey",
                  width: "80%",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
                className="font-sans mb-30"
              >
                Technical support and Feedback
              </h3>
              <p style={{ color: "grey", width: "80%" }} className="font-sans mb-20">
                <a href="mailto:team@spatialtaxdata.org.za?subject=Technical support and Feedback: Spatial Tax Data Website - About us">team@spatialtaxdata.org.za</a>
              </p>
            </div>
          </div>
          <Footer />
        </Scrollbar>
      </div>
    </div>
  );
};

export default AboutUs;
