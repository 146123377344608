import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import axiosInstance from "../axiosApi";
import axiosNoAuthInstance from "../axiosApiNoAuth";
import swal from "sweetalert";
import { parseJwt } from "../../shared/utils";
import { routeConstants } from "../../shared/constants";

const UserLogin = () => {

	let { redirect, municipality, dashboard } = useParams();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	useEffect(() => {
		if (localStorage.getItem("accessToken")) {
			window.location.href = "/";
		}
	}, []);

	const canLogin = () => {
		return email.trim() !== "" && password.trim() !== "";
	};

	const navigateToRelevantRoute = () => {
		if (redirect && dashboard && municipality) {
			return window.location.href = `/${redirect}/${municipality}/${dashboard}`
		}

		if (redirect) {
			return window.location.href = `/${redirect}`
		}

		return window.location.href = "/";
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// Check if user email exists and if validated, if error return error to stop flow
			const response = await axiosNoAuthInstance.get(`user-api/user-check-email/${email}/`);
		} catch (error) {
			if (error?.response?.status === 404 || error?.response?.status === 500) {
				return swal("User Login", `${error?.response?.data?.detail}`, "warning", {
					timer: 10000,
					buttons: false,
				});
			} else {
				return swal("User Login", "error", "error", {
					buttons: false,
					timer: 2000,
				});
			}
		}

		try {
			// Attempt Login
			const response = await axiosInstance.post("/user-api/token/obtain/", {
				//This should be email
				username: email,
				password: password,
			});
			if ("access" in response.data) {
				axiosInstance.defaults.headers["Authorization"] = "JWT " + response.data.access;
				localStorage.setItem("accessToken", response.data.access);
				if ("user" in response.data) {
					localStorage.setItem("user", JSON.stringify({ id: response.data.user.id, email: response.data.user.email }));
					if (response.data.is_staff) {
						localStorage.setItem("has_admin_access", response.data.is_staff);
					}
				} else {
					let userId = parseJwt(response.data.access)?.user_id;
					const userResponse = await axiosInstance.get(`user-api/user/${userId}/`);
					localStorage.setItem("user", JSON.stringify({ id: userResponse.data.id, email: userResponse.data.email }));
					if (userResponse.data.is_staff) {
						localStorage.setItem("has_admin_access", userResponse.data.is_staff);
					}
				}
				swal("Success", "Logged in", "success", {
					buttons: false,
					timer: 1000,
				}).finally(() => navigateToRelevantRoute());
			}
		} catch (error) {
			if (error?.response?.status === 404 || error?.response?.status === 500) {
				swal("User Login", `${error?.response?.data?.detail}`, "warning", {
					timer: 10000,
					buttons: false,
				});
			} else if (error?.response?.status === 401) {
				return swal("User Login", "Password incorrect", "warning", { // Only Possible to say this becuase we have already checked whether the email is valide
					timer: 5000,
					buttons: false,
				});
			} else {
				swal("Failed", "error", "error", {
					buttons: false,
					timer: 2000,
				});
			}
		}
	};

	async function resetPassword() {
		if (email.trim() === "" || !email.includes("@")) {
			return swal("Invalid", "Email is required", "error", {
				buttons: false,
				timer: 2000,
			});
		}
		try {
			await axiosInstance.get(`/user-api/user/reset_password/${email}`);
			swal("Reset initiated", `Email sent to ${email}`, "success", {
				buttons: false,
				timer: 2000,
			});
		} catch (error) {
			if (error?.response?.status === 404 || error?.response?.status === 500) {
				swal("Password Reset", `${error?.response?.data?.detail}`, "warning", {
					timer: 10000,
					buttons: false,
				});
			} else {
				swal("Failed", "error", "error", {
					buttons: false,
					timer: 2000,
				});
			}
		}
	}

	return (
		<div className='w-80 mx-auto mt-12 px-4 md:px-0'>
			<h2 className='title'>User Login</h2>
			{redirect === "registered" && (
				<div className='text-red-600 text-[14px] mt-[-15px] mb-[15px]'>
					Please check your email for a verification link to complete your registration. <br /> (Just in case, also check your junk folder.)
				</div>
			)}
			<form noValidate onSubmit={handleSubmit}>
				<input
					type='email'
					className='form-control placeholder-black bg-gray-300'
					placeholder='Enter email'
					onChange={(e) => setEmail(e.target.value)}
				/>
				<input
					type='password'
					className='form-control placeholder-black bg-gray-300'
					placeholder='Enter password'
					onChange={(e) => setPassword(e.target.value)}
				/>
				{/*<div className="text-sm ml-3">
            <label htmlFor="remember" className="font-medium text-gray-900">Remember me</label>
          </div>*/}
				<div className='mt-8'>
					<button type='submit' className={`btn ${canLogin() ? "btn-secondary" : "btn-disabled"} w-full`} disabled={!canLogin()}>
						Login
					</button>
				</div>

				<p className='p-4'>
					<button type='button' className='text-ccre-orange-900' onClick={resetPassword}>
						Forgot Password?
					</button>
					<br />
					<NavLink to={routeConstants.REGISTER} className='text-ccre-orange-900'>
						Register now
					</NavLink>
				</p>
			</form>
		</div>
	);
};

export default UserLogin;
