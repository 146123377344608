import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ minHeight: "90vh" }}>
      <Typography variant="h2" component="div" gutterBottom>
        Oops! An error occurred.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We're sorry for the inconvenience. Please try again later.
      </Typography>
      <button
        style={{
          border: "2px solid #F0A400",
          borderRadius: 50,
          padding: 8,
          color: "#fff",
          fontWeight: 700,
          paddingLeft: 25,
          paddingRight: 25,
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          backgroundColor: "#F0A400",
        }}
        className="font-sans"
        onClick={() => navigate("/", { replace: true })}
      >
        Back to Landing
      </button>
    </Box>
  );
};

export default ErrorPage;
