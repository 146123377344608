import React from 'react'
import { useMemo,useState,useEffect } from 'react'
import swal from 'sweetalert'
import { useTable } from 'react-table'
import UploadFormModal from './UploadFormModal';
import {TableDisplay} from '../TableDisplay/TableDisplay'
import axiosApi from "../axiosApi";

const ManageDataSets = () => {
  const [tableData, settableData] = useState([]);
  const [showUploadForm, setshowUploadForm] = useState(false);
  useEffect(async e => {
    try {
      const response = await axiosApi.get('/api/csv-list/');
      var tempdata = [];
      for (let i = 0; i < response.data.length; i++) {
        tempdata.push({
          filename: response.data[i].name,
          filedate: response.data[i].date
        })
      }
      settableData(tempdata)
    } catch (e) {
      swal("Failed", "error", "error", {
        buttons: false,
        timer: 2000,
      })
    }
  }, []);
  function handleTableUpdate(currentRow) {
    axiosApi.get('/api/table-upload/',{ params: { t:currentRow.filename } })
    .then((response) => {
      swal("Success", "success", "success", {
        buttons: true,
        text: 'Records Uploaded: '+response.data
      })
      //window.location.reload()
    }
    )
    .catch(error => {
      swal("Failed", "error", "error", {
        buttons: true,
        text: error.message
      })
    });;

  }
  function handleUpload(value) {
    setshowUploadForm(!showUploadForm);
  };
  function hideModal() {
    setshowUploadForm(false);
  };
  const columns = useMemo(
    () => [
      {
        Header: "Action",
        accessor: "action",
        Cell: props => (
          <div>
            <button className="bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1" onClick={() => handleTableUpdate(props.cell.row.values)}>Update Database</button>
          </div>
        )
      },
      {
        Header: 'File Name',
        accessor: 'filename', 
      },
      {
        Header: 'Date',
        accessor: 'filedate', 
      },
    ],
    []
  )
  return(
    <div className="bg-slate-200 py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
    <span className="border text-4xl text-blue-400 px-6 pt-10 pb-8 bg-white w-5/6  mx-auto rounded-t-md sm:px-10">Csv Files
    <button className="bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ml-5 mb-1 w-1/6" onClick={() => handleUpload()}>Upload Csv</button>
     {showUploadForm ? (
          <UploadFormModal/>) :null}
          </span>
    <div className="overflow-auto border relative px-4 pt-7 pb-8 bg-white shadow-xl w-5/6  mx-auto sm:px-10 rounded-b-md">
          {<TableDisplay data={tableData} columns={columns} />}
</div>
</div>
  ) 

}

export default ManageDataSets