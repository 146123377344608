import React from 'react';
import { useState } from 'react';
import axiosInstance from "../axiosApi";
import swal from 'sweetalert'

export const ConfigFormModal = ({ currentRow, hideModal }) => {
  const [additional, setAdditional] = useState(currentRow.additional);
  const [additional_dropdown, setAdditional_dropdown] = useState(currentRow.additional_dropdown);
  const [aggregate_variable, setAggregate_variable] = useState(currentRow.aggregate_variable);
  const [csv_file, setCsv_file] = useState(currentRow.csv_file);
  const [excluded, setExcluded] = useState(currentRow.excluded === 'Yes' ? 1 : 0);
  const [id, setId] = useState(currentRow.id);
  const [output_variable, setOutput_variable] = useState(currentRow.output_variable);
  const [shape_file, setShape_file] = useState(currentRow.shape_file);
  const [spatial_variable, setSpatial_variable] = useState(currentRow.spatial_variable);
  const [temporal_variable, setTemporal_variable] = useState(currentRow.temporal_variable);
  const [variable_names, setVariable_names] = useState(currentRow.variable_names);
  const [viewas_chart, setViewas_chart] = useState(currentRow.viewas_chart);
  const [viewas_map, setViewas_map] = useState(currentRow.viewas_map);
  async function onSubmit() {
    const entity = {
      additional,
      additional_dropdown,
      aggregate_variable,
      csv_file,
      excluded,
      output_variable,
      shape_file,
      spatial_variable,
      temporal_variable,
      variable_names,
      viewas_chart,
      viewas_map
    }
    try {
     await axiosInstance.patch('/api/filterconfig/'+id+'/', entity)
      .then((response) => {
        swal("Success", "success", "success", {
          buttons: false,
          timer: 2000,
        })
      }
      )
      .catch(error => {
        swal("Failed", "error", "error", {
          buttons: true,
          text: error
        })
      });
  } catch (error) {
    swal("Failed", "error", "error", {
      buttons: true,
      text: error
    })
  }
    return true;
  }

  return (
    <div className="flex justify-center items-center h-screen modal fade fixed top-0 left-0 w-full outline-none overflow-x-hidden overflow-y-auto" id="exampleModalXl" aria-labelledby="exampleModalXlLabel" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-xl relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalXlLabel">
              Configuration
            </h5>
            <div className="form-check">
              <input onChange={e => setExcluded(e.target.value)} defaultChecked={excluded} className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" id="excluded" />
              <label className="form-check-label inline-block text-gray-800" htmlFor="flexCheckDefault">
                Excluded
              </label>
            </div>
            <button type="button"
              className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              onClick={() => hideModal(false)} aria-label="Close">X</button>
          </div>
          <div className="modal-body relative p-4">
            <form onSubmit={onSubmit}>
              <div className='columns-2'>

                <div className="form-group">
                  <label>Output Variable</label>
                  <input type="text" className="form-control" value={output_variable} onChange={e => setOutput_variable(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>Spatial Variable</label>
                  <input type="text" className="form-control" value={spatial_variable} onChange={e => setSpatial_variable(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>Temporal Variable</label>
                  <input type="text" className="form-control" value={temporal_variable} onChange={e => setTemporal_variable(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>Aggregate Variable</label>
                  <input type="text" className="form-control" value={aggregate_variable} onChange={e => setAggregate_variable(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>Csv File</label>
                  <input type="text" className="form-control" value={csv_file} onChange={e => setCsv_file(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>Shape File</label>
                  <input type="text" className="form-control" value={shape_file} onChange={e => setShape_file(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>Variable Names</label>
                  <input type="text" className="form-control" value={variable_names} onChange={e => setVariable_names(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>Shape File</label>
                  <input type="text" className="form-control" value={shape_file} onChange={e => setShape_file(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>Additional Dropdowns</label>
                  <input type="text" className="form-control" value={additional_dropdown} onChange={e => setAdditional_dropdown(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>ViewAs Map</label>
                  <input type="text" className="form-control" value={viewas_map} onChange={e => setViewas_map(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>ViewAs Chart</label>
                  <input type="text" className="form-control" value={viewas_chart} onChange={e => setViewas_chart(e.target.value)} />
                </div>
                <div className="form-group">
                  <label>Additional</label>
                  <input type="text" className="form-control" value={additional} onChange={e => setAdditional(e.target.value)} />
                </div>
                <div className="form-group">
                  <button className="form-control btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
};
export default ConfigFormModal;
