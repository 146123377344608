import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import swal from "sweetalert";
import { CartesianGrid, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis, Cell } from "recharts";
import { Box, CircularProgress } from "@mui/material";
import { numberFormatter } from "../../../shared/utils";
import { industriesDataAtom, industryPotentialDataAtom, industryPotentialFilterAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, sic7StringAtom, spatialParameterAtom } from "../../../shared/state/atoms";

function IndustryPotential() {
  const [data, setData] = useAtom(industryPotentialDataAtom);
  const [, setIndustryPotentialFilterDisplay] = useAtom(industryPotentialFilterAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [, setIndustries] = useAtom(industriesDataAtom);
  const [sic7String] = useAtom(sic7StringAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-industry-profile-quadrants/?${spatialParameter}=${selectedSpatialValue}${sic7String}&startyear=${selectedFilters?.startYear}&endyear=${selectedFilters?.endYear}`);
        }
        if (response) {
          setData(
            response?.data.map((distribution) => ({
              ...distribution,
            }))
          );
          setErrorMessage(null);
        } else {
          setData([]);
          setErrorMessage(`No Data available for ${selectedFilters?.endYear}`);
        }

        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
          setIsLoading(false);
          setErrorMessage("No Data");
        }
      }
    }

    if (selectedPlace) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialValue, selectedPlace, sic7String, selectedFilters]);

  useEffect(() => {
    async function getData() {
      try {
        const response = await axiosNoAuthInstance.get(`/api/sic-codes/?nextlevel=1`);

        setIndustries(
          response?.data
            .map((industry) => ({
              ...industry,
            }))
            .sort((a, b) => b.code - a.code)
        );
      } catch (e) {
        console.log(e);
      }
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CustomTooltip = ({ active, payload, label, graph }) => {
    const formattedChangeInjobs = payload[1]?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const formattedTotalJobs = payload[2]?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white" key={label}>
          <p className="m-0">{payload[2]?.payload.point_label}</p>
          <p className="m-0">
            {data[0].plot_details[0]["1"][0].z_label} {formattedTotalJobs}
          </p>
          <p className="m-0">
            {data[0].plot_details[0]["1"][0].y_label} : {formattedChangeInjobs}
          </p>
          <p className="m-0">
            {data[0].plot_details[0]["1"][0].x_label} : {payload[0]?.value}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Industry Diagnostic
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">{data[0]?.graph_label}</div>
        {!errorMessage ? (
          <div>
            <div className="flex justify-between w-full">
              <div className="ml-40 font-bold">Emerging</div>
              <div className="mr-40 font-bold">Solid Bet</div>
            </div>
            {isLoading && (
              <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                  <CircularProgress color="success" size="3rem" />
                </Box>
              </div>
            )}
            <ResponsiveContainer width="90%" height={400}>
              <ScatterChart
                width={730}
                height={250}
                margin={{
                  top: 10,
                  right: 50,
                  left: 100,
                  bottom: 26,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  type="number"
                  dataKey="x"
                  domain={[0, 3]}
                  label={{
                    value: data[0]?.plot_details[0]["1"][0].x_label,
                    dy: 32,
                  }}
                />
                <YAxis
                  tickFormatter={(value) => numberFormatter(value, 2)}
                  domain={["auto", "auto"]}
                  type="number"
                  dataKey="y"
                  label={{
                    value: data[0]?.plot_details[0]["1"][0].y_label,
                    angle: -90,
                    dx: -50,
                  }}
                />
                <ZAxis dataKey="z" range={[200, 1000]} />
                <Tooltip content={<CustomTooltip graph={data[0]?.plot_details[0]["1"][0].data} />} />
                {/* {!isLoading && <Scatter data={data[0]?.plot_details[0]["1"][0].data} fill="#e69d00"></Scatter>} */}
                {!isLoading && (
                  <Scatter data={data[0]?.plot_details[0]["1"][0].data}>
                    {data[0]?.plot_details[0]["1"][0].data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color ?? "#e69d00"} />
                    ))}
                  </Scatter>
                )}
                <ReferenceLine y={0} stroke="#000000" />
                <ReferenceLine x={1} stroke="#000000" />
              </ScatterChart>
            </ResponsiveContainer>
            <div className="flex justify-between w-full">
              <div className="ml-40 font-bold">Low Prospect</div>
              <div className="mr-40 font-bold">Underperforming</div>
            </div>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default IndustryPotential;
