import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Box, CircularProgress } from "@mui/material";
import { useAtom } from "jotai";
import { groupedMunicipalitiesAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, spatialParameterAtom, totalJobsGrowthDataAtom } from "../../../shared/state/atoms";

function TotalJobsGrowth() {
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [data, setData] = useAtom(totalJobsGrowthDataAtom);
  const [groupedMunicipalities, setGroupedMunicipalities] = useAtom(groupedMunicipalitiesAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      try {
        let response;
        if (selectedFilters && selectedFilters.baseYear) {
          if (spatialParameter !== "error") {
            response = await axiosNoAuthInstance.get(`/api/dashboard-total-growth-comparison/?${spatialParameter}=${selectedSpatialValue}&metro=${selectedFilters?.isMetroDisplayed ? "1" : "0"}&national=${selectedFilters?.isNationalDisplayed ? "1" : "0"}&same_province=${selectedFilters?.isProvinceDisplayed ? "1" : "0"}&similar_size=${selectedFilters?.isSimilarSizeDisplayed ? "1" : "0"}&base_year=${selectedFilters?.baseYear}`);
          }

          const parsedData = response?.data.map((totalGrowth) => ({
            ...totalGrowth,
            value: parseFloat(totalGrowth.value.toFixed(2)),
          }));

          const groupedData = parsedData.reduce((group, product) => {
            const { year } = product;
            group[year] = group[year] ?? [];
            group[year].push(product);
            return group;
          }, {});

          const mappedData = Object.keys(groupedData).map((year) => {
            let yearObject = groupedData[year].reduce((acc, cur) => {
              acc[cur.place_name] = cur.value;
              return acc;
            }, {});
            return { year, ...yearObject };
          });

          const groupedMunics = parsedData.reduce((acc, cur) => {
            const { place_name, hex_colour } = cur;
            const existingObj = acc.find((obj) => obj.place_name === place_name);

            if (existingObj) {
              existingObj.data.push({ year: cur.year, value: cur.value });
            } else {
              acc.push({
                place_name,
                hex_colour,
                data: [{ year: cur.year, value: cur.value }],
              });
            }

            return acc;
          }, []);

          if (response) {
            setData(mappedData);
            setErrorMessage(null);
          } else {
            setData([]);
            setErrorMessage(`No Data available for ${selectedFilters?.baseYear}`);
          }

          setGroupedMunicipalities(groupedMunics);
          setIsLoading(false);
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      }
    }
    if (spatialParameter && selectedSpatialValue && selectedFilters && !isLoading) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialValue, selectedFilters]);

  const CustomTooltip = ({ active, payload, label, graph }) => {
    payload?.sort((a, b) => b.value - a.value);
    if (payload?.length < 12) {
      if (active && payload && payload?.length) {
        return (
          <div className="p-2 bg-white" key={label}>
            <p className="m-0">{label}</p>
            {payload.map((munic) => (
              <p className="m-0" style={{ color: munic.color }}>
                {munic.name} : {munic.value}
              </p>
            ))}
          </div>
        );
      }
    } else {
      if (active && payload && payload?.length) {
        return (
          <div className="p-2 bg-white" key={label}>
            <p className="m-0">{label}</p>
            {payload.map((munic) => (
              <p className="m-0 text-xs" style={{ color: munic.color }}>
                {munic.name} : {munic.value}
              </p>
            ))}
          </div>
        );
      }
    }

    return null;
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Economic Growth
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">Total Growth Comparison</div>

        {!errorMessage ? (
          <div>
            {isLoading && (
              <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                  <CircularProgress color="success" size="3rem" />
                </Box>
              </div>
            )}
            <ResponsiveContainer width="90%" height={500}>
              <LineChart
                data={data}
                margin={{
                  top: 10,
                  right: 50,
                  left: 100,
                  bottom: 26,
                }}
              >
                <XAxis dataKey="year" label={{ value: "Year", dy: 32 }} />
                <YAxis domain={["auto", "auto"]} label={{ value: "Indexed Total Jobs", angle: -90, dx: -50 }} />
                <CartesianGrid strokeDasharray="3" vertical={false} />
                {!isLoading && groupedMunicipalities.map((munic) => <Line key={munic.place_name} type="monotone" dataKey={`${munic.place_name}`} stroke={`${munic.hex_colour}`} />)}
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default TotalJobsGrowth;
