import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import axiosNoAuthInstance from "../../axiosApiNoAuth";
import swal from "sweetalert";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Box, CircularProgress } from "@mui/material";
import { industriesDataAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, sic7StringAtom, spatialParameterAtom, wageDistributionDataAtom, wageDistributionFilterAtom, wageDistributionUniqueLabelsAtom, yearsRangeAtom } from "../../../shared/state/atoms";

function WageDistributions() {
  const [data, setData] = useAtom(wageDistributionDataAtom);
  const [, setWageDistributionFilterDisplay] = useAtom(wageDistributionFilterAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [, setIndustries] = useAtom(industriesDataAtom);
  const [sic7String] = useAtom(sic7StringAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [uniqueLabels, setUniqueLabels] = useAtom(wageDistributionUniqueLabelsAtom);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-wage-distribution-industry/?${spatialParameter}=${selectedSpatialValue}${sic7String}&year=${selectedFilters?.singleYear}`);
        }
        const responseData = response?.data?.map((distribution) => ({
          ...distribution,
        }));

        setUniqueLabels(
          responseData?.reduce((acc, curr) => {
            curr.line_details?.forEach((item) => {
              Object.values(item)?.forEach((value) => {
                if (!acc.some((x) => x.label === value[0].label)) {
                  acc.push({ label: value[0].label, color: value[0].color, legend_label: value[0].legend_label });
                }
              });
            });
            return acc;
          }, [])
        );

        const processedData = responseData?.map((item) => {
          const graphLabel = item.graph_label;
          const lines = item.line_details.reduce((acc, lines) => [...acc, ...Object.values(lines)], []);
          const yearValues = {};

          // eslint-disable-next-line array-callback-return
          lines?.map((line) => {
            line.forEach((lineItem) => {
              lineItem.data.forEach((date) => {
                if (!yearValues[date.wage_band]) {
                  yearValues[date.wage_band] = {
                    wage_band: date.wage_band,
                    x_axis_label: item.x_axis_label,
                    y_axis_label: item.y_axis_label,
                  };
                }

                yearValues[date.wage_band][lineItem.label] = date.value;
              });
            });
          });

          return {
            graph_label: graphLabel,
            data: Object.values(yearValues),
          };
        });

        if (response) {
          setData(processedData);
          setErrorMessage(null);
        } else {
          setData([]);
          setErrorMessage(`No Data available for ${selectedFilters?.singleYear}`);
        }

        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      }
    }

    if (spatialParameter && selectedSpatialValue) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialValue, sic7String, selectedFilters]);

  useEffect(() => {
    setWageDistributionFilterDisplay("All Industries");
    async function getData() {
      try {
        const response = await axiosNoAuthInstance.get(`/api/sic-codes/?nextlevel=1`);

        setIndustries(
          response?.data
            ?.map((distribution) => ({
              ...distribution,
            }))
            .sort((a, b) => b.code - a.code)
        );
      } catch (e) {
        console.log(e);
      }
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const CustomTooltip = ({ active, payload, label }) => {
    payload?.sort((a, b) => b.value - a.value);
    const formattedLine1 = payload[0]?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const formattedLine2 = payload[1]?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const formattedLine3 = payload[2]?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (active && payload && payload.length) {
      if (spatialParameter !== "province") {
        return (
          <div className="p-2 bg-white" key={label}>
            <p className="m-0">{label}</p>
            <p className="m-0" style={{ color: payload[0]?.color }}>
              {payload[0]?.name} : {formattedLine1} {/* Municipality/District */}
            </p>
            <p className="m-0" style={{ color: payload[1]?.color }}>
              {payload[1]?.name} : {formattedLine2} {/* Province  */}
            </p>
            <p className="m-0" style={{ color: payload[2]?.color }}>
              {payload[2]?.name} : {formattedLine3} {/* National */}
            </p>
          </div>
        );
      } else {
        return (
          <div className="p-2 bg-white" key={label}>
            <p className="m-0">{label}</p>
            <p className="m-0" style={{ color: payload[0]?.color }}>
              {payload[0]?.name} : {formattedLine1} {/* Province */}
            </p>
            <p className="m-0" style={{ color: payload[1]?.color }}>
              {payload[1]?.name} : {formattedLine2} {/* National  */}
            </p>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Equitable Economies
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">Wage Distribution in {yearsRange ? selectedFilters.singleYear : ""}</div>
        {!errorMessage ? (
          <div>
            {isLoading && (
              <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                  <CircularProgress color="success" size="3rem" />
                </Box>
              </div>
            )}
            <ResponsiveContainer width="90%" height={450}>
              <LineChart
                data={data[0]?.data}
                margin={{
                  top: 10,
                  right: 50,
                  left: 100,
                  bottom: 123,
                }}
              >
                <XAxis
                  dataKey="wage_band"
                  label={{
                    value: data[0]?.data[0]?.x_axis_label,
                    dy: 129,
                  }}
                  tick={{
                    fontSize: 12,
                    textAnchor: "end",
                    dy: 2,
                    dx: -5,
                    angle: -90,
                  }}
                  tickCount={14}
                  interval={0}
                />
                <YAxis domain={["auto", "auto"]} label={{ value: data[0]?.data[0]?.y_axis_label, angle: -90, dx: -50 }} />
                <CartesianGrid strokeDasharray="3" vertical={false} />

                {!isLoading && uniqueLabels?.map((line, index) => <Line key={index} type="monotone" dataKey={`${line.label}`} stroke={line.color} strokeWidth={2} />)}

                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
            <div className=" ml-16 mt-10 mb-20">
              {uniqueLabels?.map((line, index) => (
                <div className="flex mb-2 ml-16" key={index}>
                  <div className={`w-8 mx-8`} style={{ backgroundColor: line.color }} />
                  <div>{`${line.legend_label}`}</div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default WageDistributions;
