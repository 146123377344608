const styles = {
  page: {
    flexDirection: "column",
    padding: 30,
    height: "100vh",
  },
  section: {
    position: "relative",
    padding: 10,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    margin: -30,
    padding: 5,
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    fontWeight: 700,
    color: "white",
  },
  heading: {
    marginTop: 25,
    fontSize: 22,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    marginBottom: 3,
  },
  subheading: {
    fontSize: 14,
    textAlign: "center",
    fontWeight: "extrabold",
    marginBottom: 5,
  },
  image: {
    alignSelf: "center",
    width: 550,
    marginLeft: -50,
    marginTop: 50,
    marginBottom: 10,
  },
  paragraph: {
    paddingHorizontal: 10,
    fontSize: 14,
    marginBottom: 10,
    textAlign: "left",
    fontWeight: 500,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    width: "100%",
    textAlign: "center",
    fontSize: 12,
  },
  legendHeading: {
    fontSize: 14,
    color: "#F0A400",
    fontWeight: "bold",
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
  },
  legendSection: {
    marginLeft: 30,
  },
  legendText: {
    fontSize: 12,
  },
  legend: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  legendLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  coverPage: {
    page: {
      flexDirection: "column",
      height: "100vh",
    },
    headerSection: {
      flexDirection: "row",
      marginBottom: 10,
    },
    headerImage: {
      width: '100%',
      height: '100%',
    },
    headerTextSection: {
      marginTop: 50,
      marginLeft: 20,
      width: 400,
      height: 202,
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
    },
    heading: {
      fontSize: 40,
      marginBottom: 5,
    },
    subheading: {
      fontSize: 25,
      marginBottom: 5,
      fontFamily: 'Helvetica-Bold',
    },
    text: {
      fontSize: 16,
      marginBottom: 10,
      fontFamily: 'Helvetica-Bold',
    },
    footer: {
      flexDirection: "row",
      justifyContent: "space-between",
      margin: 30,
    },
    footerImage: {
      height: 38,
    },
  },
  index: {
    logo: {
      height: 59,
      width: 191,
    },
    header: {
      fontSize: 25,
      margin: 30,
    },
    sideImage: {
      position: "absolute",
      right: 0,
      top: 0,
      height: "100vh",
    },
    text: {
      fontSize: 14,
      marginBottom: 10,
    },
    smallText: {
      fontSize: 12,
      marginBottom: 5,
    },
    boldText: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 14,
      marginBottom: 10,
    },
  },
  backPage: {
    page: {
      flexDirection: "column",
      height: "100vh",
      padding: 44,
      paddingLeft: 110,
      paddingRight: 110,
    },
    titleSection: {
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: 20,
      borderBottom: "2px solid #8890A4",
    },
    title: {
      fontSize: 12,
      padding: 2,
      textAlign: "center",
      paddingLeft: 10,
      paddingRight: 10,
      width: 160,
      backgroundColor: "#8890A4",
      color: "white",
    },
    image: {
      marginBottom: 20,
    },
    imageSection: {
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
  },
  headerLogo: {
    position: "absolute",
    top: 20,
    left: 0,
    height: 46,
    width: 149,
  },
  headerText: {
    position: "absolute",
    top: 30,
    right: 10,
    fontSize: 12,
  },
  pageNumberContainer: {
    position: "absolute",
    bottom: 15,
    right: 15,
  },
  pageNumber: {
    fontSize: 12,
  },
  footerContainer: {
    position: "absolute",
    bottom: 1,
    width: "110%",
    flexDirection: "row",
    justifyContent: "center",
  },
};

export default styles;
