import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, multiEstablishmentFirmsDataAtom, selectedFiltersAtom, selectedSpatialLayerAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import styles from "../../pdfs/styles/PdfStyles";
import { useWindowDimensions } from "../../shared/hooks";

function MultiEstablishmentFirmsPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(multiEstablishmentFirmsDataAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [pageOneChartImageUrl, setPageOneChartImageUrl] = useState(null);
  const [pageTwoChartImageUrl, setPageTwoChartImageUrl] = useState(null);
  const [pageThreeChartImageUrl, setPageThreeChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const pageOneChartRef = useRef();
  const pageTwoChartRef = useRef();
  const pageThreeChartRef = useRef();
  const { height, width } = useWindowDimensions();
  const COLORS = ["#f0a400", "#84746c", "#000", "#009640"];

  if (data[0]?.bar_chart[0].data.length === 3) {
    COLORS.splice(2, 1);
  }

  useEffect(() => {
    const convertNodeToImage = (nodeRef, setImageUrl) => {
      setTimeout(() => {
        toPng(nodeRef.current)
          .then((dataUrl) => {
            setImageUrl(dataUrl);
            setIsLoading(false);
          })
          .catch((err) => {
            console.error("Failed to convert node to image:", err);
          });
      }, 1500);
    };

    convertNodeToImage(pageOneChartRef, setPageOneChartImageUrl);
    convertNodeToImage(pageTwoChartRef, setPageTwoChartImageUrl);
    convertNodeToImage(pageThreeChartRef, setPageThreeChartImageUrl);
  }, []);

  const SmallChartComponent = ({ dataSlice }) => (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: 105 }}>
      {dataSlice.map((chart) => (
        <div style={{ marginRight: 20 }}>
          <div style={{ height: 90, width: 400 }}>
            {chart.graph_label.length >= 62 ? chart.graph_label.substring(0, 62) + " ..." : chart.graph_label}
          </div>
          <ResponsiveContainer width={300} height={400}>
            <BarChart
              data={chart.bar_chart[0].data}
              height={height * 0.4}
              width={(width - 400) * 0.3}
              margin={{
                top: 10,
                right: 10,
                left: 15,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray='3' vertical={false} />
              <XAxis dataKey='band_label' interval={0} height={(height * 0.35) * 0.3} tick={{ fontSize: 12, width: 30 }} />
              <YAxis
                label={{
                  value: data[0]?.y_axis_label,
                  angle: -90,
                  dx: -15,
                }}
                domain={[0, 100]}
              />


              <Bar dataKey='value'>
                {chart.bar_chart[0].data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>

            </BarChart>
          </ResponsiveContainer>
        </div>
      ))}
    </div>
  );

  const numberOfPages = data.length > 4 ? data.length > 15 ? 3 : 2 : 1;

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : pageOneChartImageUrl && pageTwoChartImageUrl && pageThreeChartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#009640' }}>Industry Diagnostic</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>MULTI-ESTABLISHMENT FIRMS</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Year: {selectedFilters?.singleYear} | (1/{numberOfPages})</Text>
              </View>
              <View>
                <Text style={styles.paragraph}>
                  What types of firms are located in {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  Larger multi-establishment firms have more than one location or branch whereas single-location firms are dependent on local business conditions. Multi-establishments firms can take advantage of their size and national networks to reach and operate in diverse and even remote locations. Their expansion across multiple sites is a clear sign of business success. Deeper information about multi-establishment firms is difficult to spatialise because functions often differ between branches. The figures do not include industries with less than 10 establishments in total.
                </Text>
              </View>
              <View style={styles.section}>
                <Image style={{ ...styles.image, marginTop: 20 }} src={pageOneChartImageUrl} />
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
            {data.length > 3 && <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#009640' }}>Economic Growth</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>MULTI-ESTABLISHMENT FIRMS</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Year: {selectedFilters?.singleYear} | (2/{numberOfPages})</Text>
              </View>
              <View style={styles.section}>
                <Image style={{ ...styles.image, marginTop: 20 }} src={pageTwoChartImageUrl} />
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>}
            {data.length > 12 && <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#009640' }}>Economic Growth</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>MULTI-ESTABLISHMENT FIRMS</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Year: {selectedFilters?.singleYear} | (3/{numberOfPages})</Text>
              </View>
              <View style={styles.section}>
                <Image style={{ ...styles.image, marginTop: 20 }} src={pageThreeChartImageUrl} />
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>}
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={pageOneChartRef}>
          <SmallChartComponent dataSlice={data.slice(0, 3)} />
          <SmallChartComponent dataSlice={data.slice(3, 6)} />
        </div >
      </div >
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={pageTwoChartRef}>
          <SmallChartComponent dataSlice={data.slice(6, 9)} />
          <SmallChartComponent dataSlice={data.slice(9, 12)} />
          <SmallChartComponent dataSlice={data.slice(12, 15)} />
        </div >
      </div >
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={pageThreeChartRef}>
          <SmallChartComponent dataSlice={data.slice(15, 18)} />
          <SmallChartComponent dataSlice={data.slice(18, 21)} />
        </div >
      </div >
    </>
  );
}

export default MultiEstablishmentFirmsPdf;
