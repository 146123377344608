import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { changeInMetroMapFirmsDataAtom, changeInMetroMapFirmsDataGreyAtom, changeInMetroMapJobsDataAtom, changeInMetroMapJobsDataGreyAtom, changeInMetroMaxFirmsValueAtom, changeInMetroMaxJobsValueAtom, changeInMetroMinFirmsValueAtom, changeInMetroMinJobsValueAtom, changeInMetroReportKeyAtom, displayMunicNameAtom, imageResponseAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import styles from "../../pdfs/styles/PdfStyles";
import { DashboardMap } from "../../components/Dashboards/components";

function ChangeInMetroPdf() {
  const [maxJobsValue] = useAtom(changeInMetroMaxJobsValueAtom);
  const [minJobsValue] = useAtom(changeInMetroMinJobsValueAtom);
  const [maxFirmsValue] = useAtom(changeInMetroMaxFirmsValueAtom);
  const [minFirmsValue] = useAtom(changeInMetroMinFirmsValueAtom);
  const [mapJobsData] = useAtom(changeInMetroMapJobsDataAtom);
  const [mapJobsDataGrey] = useAtom(changeInMetroMapJobsDataGreyAtom);
  const [mapFirmsData] = useAtom(changeInMetroMapFirmsDataAtom);
  const [mapFirmsDataGrey] = useAtom(changeInMetroMapFirmsDataGreyAtom);
  const [reportKey] = useAtom(changeInMetroReportKeyAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  const jobsOutput = "fte";
  const firmsOutput = "firms";

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 4000);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#FB8682' }}>Economic Growth</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>CHANGE IN METRO</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Start year: {selectedFilters?.startYear} End year: {selectedFilters?.endYear}</Text>
              </View>
              <View style={{ ...styles.section, height: 600, position: 'absolute', right: 5, top: 90 }}>
                <Image style={styles.image} src={chartImageUrl} />
              </View>
              <View style={{ marginTop: 550 }}>
                <Text style={styles.paragraph}>
                  Where are jobs and firms being created or lost within {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  Spatial patterns of economic change are a fundamental lens for local economic planning. Cities are made up of a mixed assortment of precincts with different levels of amenities and infrastructure investment. Spatial patterns can be explored in greater detail using the ‘map explorer’ tool.
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef} >
          <div className="flex">
            <div className="w-[45%] ml-10">
              <div className="font-bold flex justify-center mb-5">Change in Jobs {selectedFilters ? `${selectedFilters.startYear} - ${selectedFilters.endYear}` : ""}</div>
              <DashboardMap key={`Jobs ${reportKey}`} reportKey={`Jobs ${reportKey}`} data={mapJobsData} data_grey={mapJobsDataGrey} metro={selectedPlace?.name} output={jobsOutput} viewAs={"Absolute Change"} maxValue={parseFloat(maxJobsValue)} minValue={parseFloat(minJobsValue)} />
            </div>
            <div className="w-[45%] ml-10">
              <div className="font-bold flex justify-center mb-5">Change in Firms {selectedFilters ? `${selectedFilters.startYear} - ${selectedFilters.endYear}` : ""}</div>
              <DashboardMap key={`Firms ${reportKey}`} reportKey={`Firms ${reportKey}`} data={mapFirmsData} data_grey={mapFirmsDataGrey} metro={selectedPlace?.name} output={firmsOutput} viewAs={"Absolute Change"} maxValue={parseFloat(maxFirmsValue)} minValue={parseFloat(minFirmsValue)} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeInMetroPdf;
