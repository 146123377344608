import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, industryProfileDataAtom, industryProfileIndustryTotalAtom, selectedFiltersAtom, selectedSpatialLayerAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import styles from "../../pdfs/styles/PdfStyles";

function IndustryProfilePdf() {
  const [data] = useAtom(industryProfileDataAtom);
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [industryTotal] = useAtom(industryProfileIndustryTotalAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 3000);
  }, []);

  const dataOuter = data[0]?.plot_details[0][1][0].data.flatMap(({ children }) => children);

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Industry Diagnostic</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>INDUSTRY PROFILE</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
              </View>
              <View style={styles.section}>
                <Image style={styles.image} src={chartImageUrl} />
              </View>
              <View>
                <Text style={styles.paragraph}>What is the structure of industry in {displayMunicname}?</Text>
                <Text style={styles.paragraph}>Total jobs are split up across industries for sectors (inner pie) and sub-sectors (outer pie) to provide a detailed overview of the structure of local industry. Industries are defined according to the Standard Industrial Classification of all Economic Activities (seventh edition) which includes up to 90 divisions (sub-sectors in the outer pie) for an in-depth review.</Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef} style={{ marginLeft: -120 }}>
          <ResponsiveContainer width={920} height={500}>
            <PieChart width="100%" height="100%" barSize={400}>
              <Pie
                data={data[0]?.plot_details[0][1][0].data}
                dataKey="size"
                cx="50%"
                cy="50%"
                outerRadius={160}
                startAngle={-270}
                fill="#1e6091"
                labelLine={false}
                label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = -50 + innerRadius + (outerRadius - innerRadius);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  if (((100 / industryTotal) * value) > 2) {
                    return (
                      <text x={x - 15} y={y} fill="#000000" textAnchor={"start"} dominantBaseline="central" style={{ fontSize: 12 }}>
                        {((100 / industryTotal) * value).toFixed(2)}%
                      </text>
                    );
                  }
                }}
              >
                {data[0]?.plot_details[0]["1"][0].data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.hex_colour} />
                ))}
              </Pie>
              <Pie data={dataOuter} dataKey="size" cx="50%" cy="50%" innerRadius={170} outerRadius={220} startAngle={-270} fill="#82ca9d">
                {dataOuter?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.hex_colour} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div style={{ position: "absolute", left: 600, top: 50, width: 330 }}>
            {data[0]?.plot_details[0]["1"][0].data
              ?.slice()
              ?.reverse()
              .map((industry, index) => (
                <div className="flex mb-2" key={index}>
                  <div style={{ backgroundColor: industry.hex_colour, width: 32, height: 24, marginRight: 5 }} />
                  <div style={{ fontSize: 10, wordWrap: "break-word" }}>{industry.name}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default IndustryProfilePdf;
