import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, jobsGrowthDataAtom, selectedSpatialLayerAtom, yearsRangeAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import { getLowestValue, numberFormatter } from "../../shared/utils";
import styles from "../../pdfs/styles/PdfStyles";

function JobsGrowthPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(jobsGrowthDataAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 1500);
  }, []);

  const lowestValue = getLowestValue(data);

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#F0A400' }}>Overview</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>JOBS GROWTH {yearsRange?.length > 0 ? `${yearsRange[0]} - ${yearsRange[yearsRange?.length - 1]}` : ""}</Text>
              </View>
              <View style={styles.section}>
                <Image style={styles.image} src={chartImageUrl} />
              </View>
              <View style={{ ...styles.legendSection, marginBottom: 20, marginTop: 10, display: 'flex', flexDirection: 'row', width: 300, justifyContent: 'space-between' }}>
                <Text style={{ ...styles.legendText, position: 'absolute', top: -20, left: 260, fontSize: 14, textDecoration: 'underline', marginBottom: 2 }}>{data[data.length - 1].year}</Text>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.legend, backgroundColor: "#f0a400" }} />
                  <Text style={styles.text}>Total jobs</Text>
                </View>
                <Text style={styles.text}>{data[data.length - 1].value}</Text>
              </View>
              <View>
                <Text style={styles.paragraph}>
                  How many jobs are available in {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  Jobs reported in the spatial tax panel are based on IRP5 certificates which are converted into 'Full Time Equivalents' (FTE), or in other words, full time employment. The data covers employment by firms in the formal sector but excludes informal jobs where firms are not tax compliant. The total number of jobs is a critical indicator of the level of 'decent work' or size of the formal 'mainstream' economy.
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef}>
          <ResponsiveContainer width={1100} height={500}>
            <LineChart
              data={data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 26,
              }}
              animationDuration={1000}
            >
              <XAxis dataKey='year' label={{ value: data[0]?.x_axis_label, dy: 32 }} />
              <YAxis
                domain={[lowestValue, "auto"]}
                tickFormatter={(value) => numberFormatter(value, 2)}
                label={{
                  value: data[0]?.y_axis_label,
                  angle: -90,
                  dx: -50,
                }}
              />
              <CartesianGrid strokeDasharray='3' vertical={false} />
              <Line type='monotone' dataKey='value' stroke='#f0a400' strokeWidth={3} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

export default JobsGrowthPdf;
