import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { Box, CircularProgress } from "@mui/material";
import { industryProfileDataAtom, industryProfileIndustryTotalAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, spatialParameterAtom } from "../../../shared/state/atoms";

function IndustryProfile() {
  const [data, setData] = useAtom(industryProfileDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [industryTotal, setIndustryTotal] = useAtom(industryProfileIndustryTotalAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-industry-hierarchy/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`);
        }
        if (response) {
          setData(
            response?.data.map((industry) => ({
              ...industry,
            }))
          );
          setErrorMessage(null);
        } else {
          setData([]);
          setErrorMessage(`No Data available for ${selectedFilters?.singleYear}`);
        }

        const totalSize = response?.data[0].plot_details[0][1][0]?.data?.reduce((acc, category) => {
          return acc + category.size;
        }, 0);
        setIndustryTotal(totalSize);
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      }
    }

    if (spatialParameter && selectedSpatialValue) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialValue, selectedFilters]);

  const dataOuter = data[0]?.plot_details[0][1][0].data.flatMap(({ children }) => children);

  const CustomTooltip = ({ active, payload, label, graph }) => {
    const formattedNumber = payload[0]?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white" key={label}>
          <p className="m-0">
            {payload[0]?.name} : {formattedNumber}; {((100 / industryTotal) * payload[0]?.value).toFixed(2)}% of total
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Industry Diagnostic
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">{data[0]?.graph_label}</div>

        {!errorMessage ? (
          <div>
            {isLoading && (
              <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                  <CircularProgress color="success" size="3rem" />
                </Box>
              </div>
            )}
            <div className="h-full flex">
              <ResponsiveContainer width="70%" height={500}>
                <PieChart width="100%" height="100%" barSize={400}>
                  {!isLoading && (
                    <Pie data={data[0]?.plot_details[0][1][0].data} dataKey="size" cx="50%" cy="50%" outerRadius={160} startAngle={-270} fill="#1e6091">
                      {data[0]?.plot_details[0]["1"][0].data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.hex_colour} />
                      ))}
                    </Pie>
                  )}
                  {!isLoading && (
                    <Pie data={dataOuter} dataKey="size" cx="50%" cy="50%" innerRadius={170} outerRadius={220} startAngle={-270} fill="#82ca9d">
                      {dataOuter?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.hex_colour} />
                      ))}
                    </Pie>
                  )}
                  <Tooltip content={<CustomTooltip graph={data[0]?.plot_details[0][1][0].data} />} />
                </PieChart>
              </ResponsiveContainer>
              <div className="mt-16">
                {data[0]?.plot_details[0]["1"][0].data
                  ?.slice()
                  ?.reverse()
                  ?.map((industry, index) => (
                    <div className="flex mb-2" key={index}>
                      <div className={`w-8 mr-8`} style={{ backgroundColor: industry.hex_colour }} />
                      <div>{industry.short_name}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default IndustryProfile;
