import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { printInfoMessage, routeConstants } from "../../../../shared/constants";
import { growthBySectorIndustryFilterAtom, growthBySectorSic7Atom, industriesDataAtom, selectedFiltersAtom, selectedSpatialValueAtom, spatialParameterAtom, yearsRangeAtom } from "../../../../shared/state/atoms";
import { useAtom } from "jotai";
import swal from "sweetalert";

function GrowthBySectorFilter() {
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersAtom);
  const [, setGrowthBySectorIndustryFilter] = useAtom(growthBySectorIndustryFilterAtom);
  const [sic7, setSic7] = useAtom(growthBySectorSic7Atom);
  const [industries] = useAtom(industriesDataAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedFilters.baseYear || selectedFilters.baseYear < yearsRange[0]) {
      setSelectedFilters({
        ...selectedFilters,
        baseYear: yearsRange[0],
      });
    }
  }, [selectedFilters, setSelectedFilters, yearsRange]);

  function handleIndustryChange(event) {
    setSic7(event.target.value);
    const selectedIndustry = industries.find((industry) => industry.code === Number(event.target.value));

    setGrowthBySectorIndustryFilter(selectedIndustry?.activity);
  }

  const handlePrintNavigation = async () => {
    await swal("Please Note", printInfoMessage, "info");
    window.open(`${routeConstants.PDF_PREVIEW}/growthBySectorPdf`, "_blank");
  };

  const handlePrintAllNavigation = async () => {
    await swal("Please Note", printInfoMessage, "info");
    navigate(`${routeConstants.PDF_PREVIEW}/all`);
  };

  const handleExportData = async (exportType) => {
    window.open(`${process.env.REACT_APP_API_URL}/api-export/dashboard-growth-by-sector/?${spatialParameter}=${selectedSpatialValue}&sic7_1d=${sic7}&export=${exportType}`);
  };

  return (
    <div className="w-full">
      <div style={{ marginTop: "30px", borderBottom: "1px solid lightgrey", flexDirection: "column" }} className="flex justify-between mb-4 pb-8">
        <div style={{ fontSize: "16px" }} className="font-bold pb-5">
          Toggles:
        </div>
        <div className="flex justify-between mb-4">
          <label>Industry:</label>
          <select className="w-2/3 border" name="industry" id="industry" placeholder="Select an industry" value={sic7} onChange={(event) => handleIndustryChange(event)}>
            <option value={0} disabled selected></option>
            {industries.map((industry) => (
              <option key={industry.code} value={industry.code}>
                {industry.code} {industry.activity}
              </option>
            ))}
          </select>
        </div>
        <div style={{ fontSize: "16px" }} className="font-bold pb-5">
          Actions:
        </div>
        <div style={{ flexDirection: "row" }}>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              color: "#F0A400",
              width: "100%",
              marginBottom: "10px",
            }}
            className="font-sans"
            onClick={handlePrintNavigation}
          >
            Print Current Dashboard
          </button>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              color: "#F0A400",
              width: "100%",
            }}
            className="font-sans "
            onClick={handlePrintAllNavigation}
          >
            Print All Dashboards
          </button>
        </div>
        <div style={{ flexDirection: "row" }} className="pt-5">
          <div style={{ fontSize: "16px" }} className="font-bold pb-5">
            Download Data:
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: "50px",
                padding: "8px",
                paddingLeft: "14px",
                marginRight: "5px",
                color: "#F0A400",
                width: "100%",
              }}
              className="font-sans"
              title="Export a CSV or XLSX which is the dataset used to generate the dashboard currently being viewed"
              onClick={() => handleExportData("csv")}
            >
              CSV
            </button>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: "50px",
                padding: "8px",
                paddingLeft: "14px",
                marginLeft: "5px",
                color: "#F0A400",
                width: "100%",
              }}
              className="font-sans"
              title="Export a CSV or XLSX which is the dataset used to generate the dashboard currently being viewed"
              onClick={() => handleExportData("xlsx")}
            >
              XLSX
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrowthBySectorFilter;
