import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, selectedFiltersAtom, selectedSpatialLayerAtom, wageDistributionDataAtom, wageDistributionFilterAtom, wageDistributionUniqueLabelsAtom, yearsRangeAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import styles from "../../pdfs/styles/PdfStyles";

function WageDistributionPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(wageDistributionDataAtom);
  const [industryFilter] = useAtom(wageDistributionFilterAtom);
  const [uniqueLabels] = useAtom(wageDistributionUniqueLabelsAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 2000);
  }, []);

  return (
    <>
      {isLoading || !yearsRange ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#84746C' }}>Equitable Economies</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>Wage Distribution</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.subheading}>Year: {selectedFilters?.singleYear} | {industryFilter}</Text>
              </View>
              <View style={styles.section}>
                <Image style={{ ...styles.image, marginTop: 10 }} src={chartImageUrl} />
              </View>
              <View style={{ ...styles.legendSection, marginBottom: 20, marginTop: 20 }}>
                {Object.entries(data[0].data[data[0].data.length - 1]).map(([key, value], index) => {
                  if (key !== 'wage_band' && key !== 'x_axis_label' && key !== 'y_axis_label') {
                    return (
                      <View style={{ ...styles.legendLine, width: 300, justifyContent: 'space-between' }}>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <View style={{ ...styles.legend, backgroundColor: uniqueLabels[index - 3]?.color }} />
                          <Text style={styles.legendText}>{key}</Text>
                        </View>
                      </View>
                    )
                  }
                })}
              </View>
              <View>
                <Text style={styles.paragraph}>
                  How do earnings compare in {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  The wage distribution offers a more detailed review of earnings than the median wage level or the wage gini. It shows the percentage of formal workers who fall in wage bands across the spectrum ranging from very low to very high earners. A bias to the right (left) suggests a concentration of higher (lower) income earners. The industry toggle allows for wage comparisons for specific industries.
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef}>
          <ResponsiveContainer width={1100} height={500}>
            <LineChart
              data={data[0]?.data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 123,
              }}
            >
              <XAxis
                dataKey='wage_band'
                label={{
                  value: data[0]?.data[0]?.x_axis_label,
                  dy: 129,
                }}
                tick={{
                  fontSize: 16,
                  textAnchor: "end",
                  dy: 2,
                  dx: -5,
                  angle: -90,
                }}
                tickCount={14}
                interval={0}
              />
              <YAxis domain={["auto", "auto"]} label={{ value: data[0]?.data[0]?.y_axis_label, angle: -90, dx: -50 }} />
              <CartesianGrid strokeDasharray='3' vertical={false} />
              {uniqueLabels.map((line, index) => (
                <Line key={index} type='monotone' dataKey={`${line.label}`} stroke={line.color} strokeWidth={2} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

export default WageDistributionPdf;
