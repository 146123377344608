import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { styled } from "@mui/material/styles";
import { Box, CircularProgress, Grid, Tooltip as TooltipMat, tooltipClasses } from "@mui/material";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { Bar, BarChart, CartesianGrid, Cell, Tooltip, XAxis, YAxis, Text } from "recharts";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useWindowDimensions } from "../../../shared/hooks";
import { useAtom } from "jotai";
import { multiEstablishmentFirmsDataAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, spatialParameterAtom } from "../../../shared/state/atoms";

function MultiEstablishmentFirms() {
  const { height, width } = useWindowDimensions();
  const [data, setData] = useAtom(multiEstablishmentFirmsDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-establishment-multiest-industry/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`);
        }

        if (response) {
          setData(
            response?.data.map((firm) => ({
              ...firm,
            }))
          );
          setErrorMessage(null);
        } else {
          setData([]);
          setErrorMessage(`No Data available for ${selectedFilters?.singleYear}. Please select an earlier year.`);
        }

        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      }
    }

    if (spatialParameter && selectedSpatialValue) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialValue, selectedFilters]);

  const COLORS = ["#f0a400", "#84746c", "#000", "#009640"];

  if (data[0]?.bar_chart[0].data.length === 3) {
    COLORS.splice(2, 1);
  }

  const CustomTooltip = ({ active, payload, label, graph }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white" key={label}>
          <p className="m-0">{label}</p>
          <p className="m-0">{payload[0]?.value}%</p>
        </div>
      );
    }

    return null;
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    payload.value = payload.value.replace("-", "- ");

    function findLongestWord(str) {
      var longestWord = str.split(" ").sort(function (a, b) {
        return b.length - a.length;
      });
      return longestWord[0].length;
    }

    if (payload && payload.value) {
      return (
        <Text fontSize={spatialParameter === "province" ? "0.75em" : findLongestWord(payload.value) <= 13 ? "1em" : "0.85em"} width={(width - 400) * 0.3 * 0.25} x={x} y={y} textAnchor="middle" verticalAnchor="start">
          {payload.value}
        </Text>
      );
    }
    return null;
  };

  const LightTooltip = styled(({ className, ...props }) => <TooltipMat {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: "1em",
      border: 0,
      borderRadius: 0,
    },
  }));

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Industry Diagnostic
        </div>
        <div className="text-3xl font-bold px-10 font-roboto mb-9">Multi-Establishment Firms {data[0]?.year}</div>

        {!errorMessage ? (
          <div>
            <div style={{ height: `${height * 0.75}px` }} className="overflow-y-auto remove-overflow-during-print">
              {isLoading && (
                <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                  <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                    <CircularProgress color="success" size="3rem" />
                  </Box>
                </div>
              )}
              <Grid container spacing={0}>
                {data.map((chart) => (
                  <Grid item xs={4} key={chart.graph_label}>
                    <div className="px-10 mb-3 font-bold flex justify-left items-end" style={{ height: 60 }}>
                      <LightTooltip title={chart.graph_label} placement="top-start">
                        <div>{chart.graph_label.length >= 62 ? chart.graph_label.substring(0, 62) + " ..." : chart.graph_label}</div>
                      </LightTooltip>
                    </div>
                    <BarChart
                      data={chart.bar_chart[0].data}
                      height={height * 0.4}
                      width={(width - 400) * 0.3}
                      margin={{
                        top: 10,
                        right: 10,
                        left: 15,
                        bottom: 10,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3" vertical={false} />
                      <XAxis dataKey="band_label" interval={0} tick={<CustomXAxisTick />} height={height * 0.35 * 0.3} />
                      <YAxis
                        label={{
                          value: data[0]?.y_axis_label,
                          angle: -90,
                          dx: -15,
                        }}
                        domain={[0, 100]}
                      />
                      <Tooltip content={<CustomTooltip />} />
                      {!isLoading && (
                        <Bar dataKey="value">
                          {chart.bar_chart[0].data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Bar>
                      )}
                    </BarChart>
                  </Grid>
                ))}
              </Grid>
            </div>
            <div className="pt-2 flex justify-center hidden-during-print">
              <KeyboardDoubleArrowDownIcon />
            </div>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default MultiEstablishmentFirms;
