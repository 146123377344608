import React, { useEffect, useState } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import background from "../../images/background-optimised.jpg";
import Footer from "../../shared/components/Footer";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { routeConstants } from "../../shared/constants";
import axiosNoAuthInstance from "../axiosApiNoAuth";
import { CircularProgress } from "@mui/material";

import "./Resource.css";

const Resource = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const res = await axiosNoAuthInstance.get(`/wagtail-api/v2/resources/${slug}/?fields=date,title,teaser,body,resource_type,keywords,authors(name),organisations(name),thumbnail_image_206x293,access_document,access_link,featured,featured_weight,publication_date,publication_date_year`);
        if (res) {
          setData(res?.data);
        } else {
          navigate(routeConstants.RESOURCES);
        }
        setTimeout(() => setIsLoading(false), 500);

        const links = document.querySelectorAll(".injected-html a");
        links.forEach((link) => {
          link.setAttribute("target", "_blank");
        });
      } catch (error) {
        navigate(routeConstants.RESOURCES);
      }
    };
    loadData();
  }, []);

  return (
    <div className="relative">
      <div
        style={{
          height: "calc(100vh - 80.88px)",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-between overflow-y-scroll no-scrollbar"
      >
        <Scrollbar
          style={{ width: "100%", height: "100%" }}
          thumbYProps={{
            renderer: ({ style, ...props }) => {
              const { elementRef, ...restProps } = props;
              return <div {...restProps} style={{ ...style, backgroundColor: "#FFF", width: "8px", opacity: "0.5" }} ref={elementRef} className="tHuMbY" />;
            },
          }}
        >
          <div className="flex flex-col items-center z-10 p-5 pb-20">
            <div className="flex justify-start" style={{ width: "80%" }}>
              <NavLink to={`${routeConstants.RESOURCES}`} className="mb-5">
                <button
                  style={{
                    border: "2px solid #F0A400",
                    borderRadius: 50,
                    padding: 8,
                    paddingLeft: 14,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F0A400",
                  }}
                  className="font-sans"
                >
                  <ArrowBackIosIcon className="ml-2" fontSize="10" />
                  Back to Resources
                </button>
              </NavLink>
            </div>
            <div className="bg-white bg-opacity-100 border rounded pt-20 pb-20 border-0" style={{ width: "80%", paddingRight: "10%", paddingLeft: "10%" }}>
              {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                  <CircularProgress style={{ color: "#F0A400" }} size="50px" />
                </div>
              ) : (
                <>
                  <h2 className="font-sans pb-5" style={{ fontSize: "50px", fontWeight: 600, lineHeight: "40px" }}>
                    {data.title}
                  </h2>
                  <div className="border-b-2 border-t-2 mt-10 pb-5" style={{ borderColor: "#D8D8D8" }}>
                    <div className="flex flex-wrap justify-start mt-5">
                      <div className="flex items-center mb-4 pl-2 pr-2" style={{ flex: "0 0 32%", wordWrap: "break-word" }}>
                        <PermIdentityIcon className="mr-2" style={{ color: "#F0A400" }} />
                        <div className="font-sans" style={{ fontSize: "14px", fontWeight: 400, lineHeight: "17.6px" }}>
                          {data.authors?.map((author) => author.name).join(", ")}
                        </div>
                      </div>
                      <div className="flex items-center mb-4 pl-2 pr-2" style={{ flex: "0 0 32%", wordWrap: "break-word" }}>
                        <PeopleAltOutlinedIcon className="mr-2" style={{ color: "#F0A400" }} />
                        <div className="font-sans" style={{ fontSize: "14px", fontWeight: 400, lineHeight: "17.6px" }}>
                          {data.organisations?.map((organisation) => organisation.name).join(", ")}
                        </div>
                      </div>
                      <div className="flex items-center mb-4 pl-2 pr-2" style={{ flex: "0 0 32%", wordWrap: "break-word" }}>
                        <DateRangeOutlinedIcon className="mr-2" style={{ color: "#F0A400" }} />
                        <div className="font-sans" style={{ fontSize: "14px", fontWeight: 400, lineHeight: "17.6px" }}>
                          {data.publication_date}
                        </div>
                      </div>
                      <div className="flex items-center mb-4 pl-2 pr-2" style={{ flex: "0 0 32%", wordWrap: "break-word" }}>
                        <FileCopyOutlinedIcon className="mr-2" style={{ color: "#F0A400" }} />
                        <div className="font-sans" style={{ fontSize: "14px", fontWeight: 400, lineHeight: "17.6px" }}>
                          {data.resource_type}
                        </div>
                      </div>
                      <div className="flex items-center mb-4 pl-2 pr-2" style={{ flex: "0 0 32%", wordWrap: "break-word" }}>
                        <LocalOfferOutlinedIcon className="mr-2" style={{ color: "#F0A400" }} />
                        <div className="font-sans" style={{ fontSize: "14px", fontWeight: 400, lineHeight: "17.6px" }}>
                          {data.keywords?.map((keyword) => keyword).join(", ")}
                        </div>
                      </div>
                    </div>
                    <button
                      style={{
                        border: "2px solid #F0A400",
                        borderRadius: 50,
                        padding: 8,
                        paddingLeft: 14,
                        color: "white",
                        backgroundColor: "#F0A400",
                        fontWeight: 700,
                        fontSize: 14,
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="font-sans mt-5"
                      onClick={() => window.open(data?.access_document?.meta?.download_url, "_blank")}
                    >
                      Access Resource
                      <ArrowForwardIosIcon className="ml-2" fontSize="10" />
                    </button>
                  </div>
                </>
              )}
              <div className="mt-20 font-sans injected-html" dangerouslySetInnerHTML={{ __html: data.body }}></div>
            </div>
          </div>
        </Scrollbar>
        <Footer />
      </div>
    </div>
  );
};

export default Resource;
