import React, { useState } from "react";
import saveAs from "file-saver";
import axiosInstance from "../axiosApi";
import swal from "sweetalert";

function getExtension(filename) {
	var n = filename.lastIndexOf('.');
	var result = filename.substring(n + 1)
	return result
}

function DownloadFile({ file }) {
	const [isGettingFile, setIsGettingFile] = useState(false);

	async function handleDownload(event) {
		event.preventDefault();
		try {
			setIsGettingFile(true);
			//NOTE: responseType: 'arraybuffer' was added for binary files to work
			let csvarchiveext = ".zip"; //May change to .7z
			let extension = (getExtension(file).toLowerCase() === "7z" || getExtension(file).toLowerCase() === "zip") ? "" : csvarchiveext; // Ignore files that already have the archive extension in the name
			const response = await axiosInstance.get(`/download_history/download/${file}${extension}`, { timeout: 0, responseType: 'arraybuffer' });
			saveAs(new Blob([response.data]), `${file}${extension}`);
			setIsGettingFile(false);
		} catch (error) {
			setIsGettingFile(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	return (
		<span>
			{isGettingFile ? (
				<span className='ml-1 mb-1 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-green-900'></span>
			) : (
				<button className='btn text-ccre-green-900 hover:underline' onClick={(e) => handleDownload(e)}>
					Download
				</button>
			)}
		</span>
	);
}
export default DownloadFile;
