import React, { useCallback } from 'react'
import axiosApi from "../axiosApi";
import { useDropzone } from 'react-dropzone'
import swal from 'sweetalert'

function UploadFormModal() {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles:1
  });
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  async function UploadFile() {
    const formData = new FormData();
    const [file] = acceptedFiles;
    formData.append("file_uploaded", file);
    const response = await axiosApi.post('/api/csv-upload/',formData)
    .then((response) => {
      swal("Success", "success", "success", {
        buttons: false,
        timer: 2000,
      })
      window.location.reload()
    }
    )
    .catch(error => {
      swal("Failed", "error", "error", {
        buttons: true,
        text: 'Unable to Upload, make sure you are logged in'
      })
    });;

    
  };

  return (
    <div>
     <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {acceptedFiles.length > 0 && <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
        <button className="bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ml-5 mb-1 w-1/6" onClick={() => UploadFile()}>Upload File</button>
      </aside>}
    </section>
    </div>
  );
}
export default UploadFormModal