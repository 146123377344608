import { Box, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import swal from "sweetalert";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { numberFormatter } from "../../../shared/utils";
import { industryStructureDataAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, spatialParameterAtom, yearsRangeAtom } from "../../../shared/state/atoms";

function IndustryStructure() {
  const [data, setData] = useAtom(industryStructureDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-industry-structure/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`);
        }
        let jobsData = response?.data?.map((jobs) => ({
          ...jobs,
          value: jobs.value,
        }));

        jobsData.sort(function (a, b) {
          return b.value - a.value;
        });

        let sum = 0;
        for (let i = 10; i < jobsData.length; i++) {
          sum += jobsData[i].value;
        }
        jobsData = jobsData.slice(0, 10);
        jobsData.push({
          cat_b: "CPT",
          year: selectedFilters?.baseYear,
          code: -1,
          activity: "Other",
          activity_shortname: "Other",
          value: sum,
          count: 0,
        });

        if (response) {
          setData(jobsData);
          setErrorMessage(null);
        } else {
          setData([]);
          setErrorMessage(`No Data available for ${selectedFilters?.singleYear}`);
        }

        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        }
      }
    }

    if (spatialParameter && selectedSpatialValue && yearsRange?.length > 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialLayer, selectedSpatialValue, yearsRange, selectedFilters]);

  function getPointLabel(data, label) {
    for (const item of data) {
      if (item.activity_shortname === label) {
        return item.activity;
      }
    }
    return null;
  }

  function getPointValue(data, label) {
    for (const item of data) {
      if (item.activity_shortname === label) {
        return item.value;
      }
    }
    return null;
  }

  const CustomTooltip = ({ active, payload, label, graph }) => {
    const formattedNumber = getPointValue(graph, label)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white" key={label}>
          <p className="m-0">{getPointLabel(graph, label)}</p>
          <p className="m-0">Total Jobs: {formattedNumber}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex">
      <div className="w-full ">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Overview
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">Industry Structure {selectedFilters ? selectedFilters.singleYear : ""}</div>
        {!errorMessage ? (
          <div>
            {isLoading && (
              <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                  <CircularProgress color="success" size="3rem" />
                </Box>
              </div>
            )}
            <ResponsiveContainer width="100%" height={500}>
              <BarChart
                data={data}
                margin={{
                  top: 10,
                  right: 50,
                  left: 100,
                  bottom: 30,
                }}
              >
                <CartesianGrid strokeDasharray="3" vertical={false} />
                <XAxis dataKey="activity_shortname" label={{ value: data[0]?.x_axis_label, dy: 34 }} tickCount={11} interval={0} />
                <YAxis tickFormatter={(value) => numberFormatter(value, 2)} label={{ value: data[0]?.y_axis_label, angle: -90, dx: -50 }} />
                <Tooltip content={<CustomTooltip graph={data} />} />
                {!isLoading && <Bar dataKey="value" fill="#e69d00" />}
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default IndustryStructure;
