import cspLogo from "../../images/csp_logo.png";
import hsrcLogoReverse from "../../images/hsrc-logo-reverse.png";
import ufsLogoReverse from "../../images/ufs-logo-reverse.png";
import ukidfcdoLogoReverse from "../../images/ukid-fcdo-logo-reversed.png";
import citieschairlogo from "../../images/city-chair-logo-sidebar.png";

export default function Footer() {
  return (
    <div className="z-10">
      <div className="flex">
        <div
          style={{
            width: "25%",
            height: 5,
            backgroundColor: "#84746C",
          }}
        />
        <div
          style={{
            width: "25%",
            height: 5,
            backgroundColor: "#F0A400",
          }}
        />
        <div
          style={{
            width: "25%",
            height: 5,
            backgroundColor: "#009641",
          }}
        />
        <div
          style={{
            width: "25%",
            height: 5,
            backgroundColor: "#E53812",
          }}
        />
      </div>
      <div className="bg-gray-300 flex items-center">
        <img 
          src={citieschairlogo} 
          alt="Cities Chair Logo" 
          style={{ height: 55, marginLeft: 35, marginRight: 40, marginTop: 5, marginBottom: 5 }} 
        />
        <img
          src={cspLogo}
          alt="CSP Logo"
          style={{ height: 55, marginRight: 50, marginTop: 5, marginBottom: 5 }}
        />
        <img
          src={hsrcLogoReverse}
          alt="HSRC Logo"
          style={{ height: 50, marginRight: 40, marginTop: 5, marginBottom: 5 }}
        />
        <img 
          src={ufsLogoReverse} 
          alt="UFS Logo" 
          style={{ height: 50, marginRight: 40, marginTop: 5, marginBottom: 5 }} 
        />        
        <img 
        src={ukidfcdoLogoReverse} 
        alt="UKID FCDO Logo" 
        style={{ height: 50, marginRight: 40, marginTop: 5, marginBottom: 5 }} 
        />
      </div>
    </div>
  );
}
