import React from "react";
import { useParams } from "react-router-dom";
import axiosNoAuthInstance from "../axiosApiNoAuth";
import swal from "sweetalert";
import { routeConstants } from "../../shared/constants";

const VerifyEmail = () => {
	let { email } = useParams();

	React.useEffect(() => {
		async function verifyEmail() {
			try {
				await axiosNoAuthInstance.get(`/user-api/user/verify/${email}/`);
				await swal("Success", "Email Verified", "success", {
					buttons: false,
					timer: 2000,
				}).finally(() => (window.location.href = routeConstants.LOGIN));
			} catch (error) {
				if (error?.response?.status === 404) {
					swal("Email Verify", `${error?.response?.data?.detail}`, "info", {
						timer: 10000,
						buttons: false,
					}).finally(() => (window.location.href = routeConstants.LOGIN));
				} else if (error?.response?.status === 500) {
					swal("Email Verify", `${error?.response?.data?.detail}`, "error", {
						timer: 10000,
						buttons: false,
					}).finally(() => (window.location.href = routeConstants.LOGIN));
				} else {
					swal("Failed", "error", "error", {
						buttons: false,
						timer: 2000,
					}).finally(() => (window.location.href = routeConstants.LOGIN));
				}
			}
		}
		if (email) {
			verifyEmail();
		}
	}, [email]);

	return (
		<div className='w-80 mx-auto mt-20 px-4 md:px-0'>
			<h2 className='title text-center'>Verifying Email</h2>
			<div className='flex justify-center items-center'>
				<div className='spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-gray-400'>
					<span className='visually-hidden'>Loading...</span>
				</div>
			</div>
		</div>
	);
};

export default VerifyEmail;
