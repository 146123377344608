import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, groupedMunicipalitiesAtom, imageResponseAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, totalJobsGrowthDataAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import styles from "../styles/PdfStyles";

function TotalJobsGrowthPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(totalJobsGrowthDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [groupedMunicipalities] = useAtom(groupedMunicipalitiesAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 1500);
  }, []);

  const LegendSection = () => (
    <View style={{ ...styles.legendSection, marginBottom: 10, marginTop: 10 }}>
      <Text style={{ ...styles.legendText, position: 'absolute', top: -20, left: 220, fontSize: 14, textDecoration: 'underline', marginBottom: 2 }}>{data[data.length - 1].year}</Text>
      {groupedMunicipalities.map((munic) => (
        <View style={{ ...styles.legendLine, width: 300, justifyContent: 'space-between' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ ...styles.legend, backgroundColor: munic.hex_colour }} />
            <Text style={styles.legendText}>{munic.place_name}</Text>
          </View>
          <Text style={{ ...styles.legendText, width: 80 }}>{munic.data[munic.data.length - 1].value}</Text>
        </View>
      ))}
    </View>
  );

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page} break>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>TOTAL ECONOMIC GROWTH</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Base year: {selectedFilters?.baseYear} | Toggles: {selectedFilters.isMetroDisplayed && 'metros'}{selectedFilters.isNationalDisplayed && ', national'}{selectedFilters.isProvinceDisplayed && ', provinces'}{selectedFilters.isSimilarSizeDisplayed && ', similar size'} </Text>
              </View>
              <View style={styles.section}>
                <Image style={{ ...styles.image, marginTop: 10 }} src={chartImageUrl} />
              </View>
              <LegendSection />
              <View>
                <Text style={styles.paragraph}>How does total jobs growth compare in {displayMunicname}?</Text>
                <Text style={styles.paragraph}>
                  Indexed total jobs shows the relative performance of each region adjusted for its size in the starting year. It can also be interpreted as the total percentage change in jobs from the starting year by simply subtracting 100. Instability over time can be a consequence of a small economic base but also if the economy is stagnant which compresses the y-axis. Getting positioned above (or below) the ‘national’ benchmark implies that this local economy is pulling ahead (or falling
                  behind) the rest of the country.
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef}>
          <ResponsiveContainer width={900} height={400}>
            <LineChart
              data={data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 26,
              }}
            >
              <XAxis dataKey="year" label={{ value: "Year", dy: 32 }} />
              <YAxis domain={["auto", "auto"]} label={{ value: "Indexed Total Jobs", angle: -90, dx: -50 }} />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              {groupedMunicipalities.map((munic) => (
                <Line key={munic.place_name} type="monotone" dataKey={`${munic.place_name}`} stroke={`${munic.hex_colour}`} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

export default TotalJobsGrowthPdf;
