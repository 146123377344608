import React, { useState, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { Map } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { scaleThreshold } from "d3-scale";
import { numberFormatter } from "../../../shared/utils";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

//const DATA_URL = process.env.REACT_APP_API_URL+'/api/hex-firms/';
const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiaHlsdG9ubWlsbGFyIiwiYSI6ImNreGFiejJzZzBhamoycXBnZWY0eWN4Z2oifQ.jxfjL2uzFG02Km9kmvcVeg";

const DashboardMap = ({ reportKey, data, data_grey, metro, viewAs, output, maxValue, minValue }) => {
	const divergingScale = viewAs?.includes("Change");
	const [hoverInfo, setHoverInfo] = useState();
	const [firsttime, setFirsttime] = useState(0);
	const [selectedScale, setSelectedScale] = useState(divergingScale ? "Linear" : "Log");
	const [viewState, setViewState] = useState({
		longitude: 24,
		latitude: -30,
		zoom: 5.2,
		minZoom: 5.1,
		maxZoom: 5.3,
		pitch: 0,
		bearing: 0,
	});
	const numberSymbols = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: "k" },
		{ value: 1e6, symbol: "M" },
		{ value: 1e9, symbol: "G" },
		{ value: 1e12, symbol: "T" },
		{ value: 1e15, symbol: "P" },
		{ value: 1e18, symbol: "E" },
	];

	useEffect(() => {
		if (firsttime === 0) {
			setFirsttime(1);
			const default_zoom = 8.5;
			const default_minZoom = 8;
			const default_pitch = 0;
			const default_bearing = 0;
			switch (metro) {
				case "Buffalo City":
					setViewState({
						longitude: 27.8,
						latitude: -33,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Cape Town":
					setViewState({
						longitude: 18.5,
						latitude: -33.9,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "City of Joburg":
					setViewState({
						longitude: 28,
						latitude: -26.2,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "eThekwini":
					setViewState({
						longitude: 30.9,
						latitude: -30,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Ekurhuleni":
					setViewState({
						longitude: 28.4,
						latitude: -26.2,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Mangaung":
					setViewState({
						longitude: 26.2,
						latitude: -29.2,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Nelson Mandela Bay":
					setViewState({
						longitude: 25.5,
						latitude: -34,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Tshwane":
					setViewState({
						longitude: 28.3,
						latitude: -25.7,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				default:
					setViewState({
						longitude: 24,
						latitude: -30,
						zoom: 5.2,
						minZoom: 5.1,
						maxZoom: 5.3,
						pitch: default_pitch,
						bearing: 0,
					});
					break;
			}
		}
	}, []);

	const SEQUENTIAL_COLOR_RANGE = generateColor([149, 215, 63, 255], [250, 230, 34, 255], 60).concat(
		generateColor([42, 176, 125, 255], [149, 215, 63, 255], 60),
		generateColor([41, 119, 142, 255], [42, 176, 125, 255], 60),
		generateColor([64, 67, 135, 255], [41, 119, 142, 255], 60),
		generateColor([68, 3, 87, 255], [64, 67, 135, 255], 60)
	);

	const DIVERGING_POSITIVE_COLOR_RANGE = generateColor([207, 234, 132, 255], [254, 232, 153, 255], 50).concat(
		generateColor([113, 193, 100, 255], [207, 234, 132, 255], 50),
		generateColor([1, 105, 55, 255], [113, 193, 100, 255], 50)
	);

	const DIVERGING_NEGATIVE_COLOR_RANGE = generateColor([246, 129, 76, 255], [166, 1, 38, 255], 50).concat(
		generateColor([254, 232, 153, 255], [246, 129, 76, 255], 50),
		generateColor([207, 234, 132, 255], [254, 232, 153, 255], 50)
	);

	const numberDisplay = (num) => {
		if (output === "Gini Coefficient") {
			return numberFormatter(num, 2);
		} else {
			return numberFormatter(num, 4);
		}
	};

	const legendMax = () => {
		if (maxValue < 10) {
			return parseFloat(maxValue.toFixed(4));
		} else {
			return Math.ceil(parseFloat(maxValue));
		}
	};

	const legendMin = () => {
		if (divergingScale && minValue > 0) {
			return 0;
		}
		if (maxValue < 10) {
			return parseFloat(minValue.toFixed(4));
		} else {
			return Math.floor(parseFloat(minValue));
		}
	};

	const legendAtPoint = (pointOnScale) => {
		if (divergingScale) {
			if (pointOnScale === 150 || (pointOnScale <= 150 && legendMin() >= 0)) {
				return 0;
			}
			if (selectedScale === "Linear") {
				if (pointOnScale < 150) {
					return parseFloat((legendMin() - (legendMin() / 150) * pointOnScale).toFixed(4));
				} else {
					return parseFloat((0 + (legendMax() / 150) * (pointOnScale - 150)).toFixed(4));
				}
			}

			if (selectedScale === "Log") {
				if (pointOnScale < 150) {
					let minLog = Math.log(0.0001);
					let maxLog = Math.log(Math.abs(legendMin()));
					let scale = (maxLog - minLog) / 150;

					return (parseFloat(Math.exp(minLog + scale * pointOnScale)) * -1).toFixed(4);
				} else {
					let minLog = Math.log(0.0001);
					let maxLog = Math.log(legendMax());
					let scale = (maxLog - minLog) / 150;

					return parseFloat(Math.exp(minLog + scale * (pointOnScale - 150))).toFixed(4);
				}
			}
		} else {
			if (selectedScale === "Linear") {
				if (maxValue < 10) {
					return parseFloat((legendMin() + ((legendMax() - legendMin()) / 300) * pointOnScale).toFixed(4));
				} else {
					return parseInt(legendMin() + ((legendMax() - legendMin()) / 300) * pointOnScale);
				}
			}

			if (selectedScale === "Log") {
				let minLog = Math.log(legendMin() > 0 ? legendMin() : 0.0000001);
				let maxLog = Math.log(legendMax());
				let scale = (maxLog - minLog) / 300;

				return parseFloat(Math.exp(minLog + scale * pointOnScale)).toFixed(5);
			}
		}

		return "";
	};

	const getLinearScale = () => {
		let interval = parseFloat((legendMax() - legendMin()) / 300);
		let start = legendMin();
		let vscale = [legendMin()];
		for (let i = 0; i < 300; i++) {
			start = start + interval;
			vscale.push(start);
		}
		return vscale;
	};

	const getPositiveLinearScale = () => {
		let interval = parseFloat(legendMax() / 150);
		let start = 0;
		let vscale = [0];
		for (let i = 0; i < 150; i++) {
			start = start + interval;
			vscale.push(start);
		}
		return vscale;
	};

	const getNegativeLinearScale = () => {
		let interval = parseFloat(-legendMin() / 150);
		let start = legendMin();
		let vscale = [legendMin()];
		for (let i = 0; i < 150; i++) {
			start = start + interval;
			vscale.push(start);
		}
		return vscale;
	};

	const getLogScale = () => {
		var startInterVal = 1,
			endInterval = 300,
			minLog = Math.log(legendMin() > 0 ? legendMin() : 0.0000001),
			maxLog = Math.log(legendMax()),
			scale = (maxLog - minLog) / (endInterval - startInterVal),
			vscale = [];
		for (let i = 1; i < 300; i++) {
			vscale.push(Math.exp(minLog + scale * (i - startInterVal)));
		}
		vscale.push(legendMax());
		return vscale;
	};

	const getPositiveLogScale = () => {
		var startInterVal = 1,
			endInterval = 150,
			minLog = Math.log(0.0000001),
			maxLog = Math.log(legendMax()),
			scale = (maxLog - minLog) / (endInterval - startInterVal),
			vscale = [];
		for (let i = 1; i < 150; i++) {
			vscale.push(Math.exp(minLog + scale * (i - startInterVal)));
		}
		vscale.push(legendMax());
		return vscale;
	};

	const getNegativeLogScale = () => {
		if (legendMin() >= 0) {
			return [0];
		}

		var startInterVal = 1,
			endInterval = 150,
			minLog = Math.log(0.0001),
			maxLog = Math.log(Math.abs(legendMin())),
			scale = (maxLog - minLog) / (endInterval - startInterVal),
			vscale = [];
		for (let i = 1; i < 150; i++) {
			vscale.push(Math.exp(minLog + scale * (i - startInterVal)) * -1);
		}
		vscale.push(legendMin());
		return vscale.reverse();
	};

	const COLOR_SCALE =
		selectedScale === "Linear"
			? scaleThreshold().domain(getLinearScale()).range(SEQUENTIAL_COLOR_RANGE)
			: scaleThreshold().domain(getLogScale()).range(SEQUENTIAL_COLOR_RANGE);

	const POSITIVE_COLOR_SCALE =
		selectedScale === "Linear"
			? scaleThreshold().domain(getPositiveLinearScale()).range(DIVERGING_POSITIVE_COLOR_RANGE)
			: scaleThreshold().domain(getPositiveLogScale()).range(DIVERGING_POSITIVE_COLOR_RANGE);

	const NEGATIVE_COLOR_SCALE =
		selectedScale === "Linear"
			? scaleThreshold().domain(getNegativeLinearScale()).range(DIVERGING_NEGATIVE_COLOR_RANGE)
			: scaleThreshold().domain(getNegativeLogScale()).range(DIVERGING_NEGATIVE_COLOR_RANGE);

	function generateColor(colorStart, colorEnd, colorCount) {
		var alpha = 0.0;
		var colors = [];

		for (let i = 0; i < colorCount; i++) {
			var c = [];
			alpha += 1.0 / colorCount;

			c[0] = colorStart[0] * alpha + (1 - alpha) * colorEnd[0];
			c[1] = colorStart[1] * alpha + (1 - alpha) * colorEnd[1];
			c[2] = colorStart[2] * alpha + (1 - alpha) * colorEnd[2];

			colors.push(c);
		}

		return colors;
	}

	let layers = [];
	if (divergingScale) {
		layers = [
			new GeoJsonLayer({
				id: "geojson-layer-positive",
				data: Object.values(data?.features || {}).filter((hex) => hex?.properties?.value >= 0),
				opacity: 0.3,
				filled: true,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: (f) => Math.sqrt(f.properties.value) * 200,
				getFillColor: (f) => POSITIVE_COLOR_SCALE(f.properties.value),
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
			new GeoJsonLayer({
				id: "geojson-layer-negative",
				data: Object.values(data?.features || {}).filter((hex) => hex.properties.value < 0),
				opacity: 0.3,
				filled: true,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: (f) => Math.sqrt(f.properties.value) * 200,
				getFillColor: (f) => NEGATIVE_COLOR_SCALE(f.properties.value),
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
			new GeoJsonLayer({
				id: "geojson-layer-grey",
				data: data_grey,
				opacity: 0.3,
				filled: true,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: 1,
				getFillColor: [229, 228, 226],
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
		];
	} else {
		layers = [
			new GeoJsonLayer({
				id: "geojson-layer",
				data: data,
				opacity: 0.3,
				filled: true,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: (f) => Math.sqrt(f.properties.value) * 200,
				getFillColor: (f) => COLOR_SCALE(f.properties.value),
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
			new GeoJsonLayer({
				id: "geojson-layer-grey",
				data: data_grey,
				opacity: 0.3,
				filled: true,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: 1,
				getFillColor: [229, 228, 226],
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
		];
	}

	function getTooltip({ object }) {
		let vtext = "";
		if (object && object.properties && object.properties.label) {
			for (let i = 0; i < object.properties.label.length; i++) {
				vtext = vtext + "<div>" + object.properties.label[i] + "</div>";
			}
		}
		return (
			object && {
				html: vtext,
			}
		);
	}

	const determineLegendGradient = () => {
		if (divergingScale) {
			return (
				<defs>
					<linearGradient id='linear-gradient' x1='0%' x2='100%' y1='0%' y2='0%'>
						<stop offset='0%' stopColor='#a50026'></stop>
						<stop offset='25%' stopColor='#f89053'></stop>
						<stop offset='50%' stopColor='#f9fcb7'></stop>
						<stop offset='75%' stopColor='#85ca66'></stop>
						<stop offset='100%' stopColor='#006837'></stop>
					</linearGradient>
				</defs>
			);
		} else {
			return (
				<defs>
					<linearGradient id='linear-gradient' x1='0%' x2='100%' y1='0%' y2='0%'>
						<stop offset='0%' stopColor='#fae622'></stop>
						<stop offset='25%' stopColor='#4ac26c'></stop>
						<stop offset='50%' stopColor='#218c8d'></stop>
						<stop offset='75%' stopColor='#3d4c89'></stop>
						<stop offset='100%' stopColor='#440357'></stop>
					</linearGradient>
				</defs>
			);
		}
	};

	return (
		<div>
			<div className='relative sm:h-[60vh] overflow-hidden'>
				<DeckGL key={reportKey} initialViewState={viewState} controller={true} layers={layers} getTooltip={getTooltip}>
					<Map reuseMaps mapStyle='mapbox://styles/mapbox/light-v9' mapboxAccessToken={MAPBOX_ACCESS_TOKEN} />
				</DeckGL>
			</div>

			<div className='relative w-[500px]'>
				<div className='grid py-4 bg-white'>
					<div className='flex flex-direction-row mt-1'>
						<svg style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", borderRadius: "5px", width: "430px", height: "50px" }}>
							{determineLegendGradient()}
							<rect x='30' y='0' width='350' height='20' className='rect-fill'></rect>
							<g transform='translate(20, 23)' fill='none' fontSize='10' fontFamily='sans-serif' textAnchor='middle'>
								<path stroke='currentColor' d='M10,6V0H359V6'></path>
							</g>
							<g opacity='1' transform={`translate(${30 - numberDisplay(legendMin()).toString().length * 3}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendMin())}
								</text>
							</g>
							<g opacity='1' transform='translate(115,23)'>
								<line stroke='currentColor' y2='6'></line>
							</g>
							<g opacity='1' transform={`translate(${115 - numberDisplay(legendAtPoint(75)).toString().length * 4}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendAtPoint(75))}
								</text>
							</g>
							<g opacity='1' transform='translate(200,23)'>
								<line stroke='currentColor' y2='6'></line>
							</g>
							<g opacity='1' transform={`translate(${200 - numberDisplay(legendAtPoint(150)).toString().length * 4}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendAtPoint(150))}
								</text>
							</g>
							<g opacity='1' transform='translate(290,23)'>
								<line stroke='currentColor' y2='6'></line>
							</g>
							<g opacity='1' transform={`translate(${290 - numberDisplay(legendAtPoint(225)).toString().length * 4}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendAtPoint(225))}
								</text>
							</g>
							<g opacity='1' transform={`translate(${375 - numberDisplay(legendMax()).toString().length * 2}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendMax())}
								</text>
							</g>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};
export default DashboardMap;
