import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { growthBySectorIndustryFilterAtom, growthBySectorMainDataAtom, growthBySectorOtherDataAtom, displayMunicNameAtom, growthBySectorDataAtom, selectedPlaceAtom, imageResponseAtom, selectedSpatialLayerAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import styles from "../../pdfs/styles/PdfStyles";
import { useWindowDimensions } from "../../shared/hooks";

function GrowthBySectorPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [industryFilter] = useAtom(growthBySectorIndustryFilterAtom);
  const [mainData] = useAtom(growthBySectorMainDataAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [otherData] = useAtom(growthBySectorOtherDataAtom);
  const [data] = useAtom(growthBySectorDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [pageOneChartImageUrl, setPageOneChartImageUrl] = useState(null);
  const [pageTwoChartImageUrl, setPageTwoChartImageUrl] = useState(null);
  const [pageThreeChartImageUrl, setPageThreeChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const pageOneChartRef = useRef();
  const pageTwoChartRef = useRef();
  const pageThreeChartRef = useRef();
  const { height, width } = useWindowDimensions();
  const COLORS = ["#F0A400", "#009641"];

  useEffect(() => {
    const convertNodeToImage = (nodeRef, setImageUrl) => {
      setTimeout(() => {
        toPng(nodeRef.current)
          .then((dataUrl) => {
            setImageUrl(dataUrl);
            setIsLoading(false);
          })
          .catch((err) => {
            console.error("Failed to convert node to image:", err);
          });
      }, 3000);
    };

    convertNodeToImage(pageOneChartRef, setPageOneChartImageUrl);
    convertNodeToImage(pageTwoChartRef, setPageTwoChartImageUrl);
    convertNodeToImage(pageThreeChartRef, setPageThreeChartImageUrl);
  }, []);

  const SmallChartComponent = ({ dataSlice }) => (
    <div style={{ display: "flex", flexWrap: "wrap", marginLeft: 105, marginTop: 20 }}>
      {dataSlice.map((chart) => (
        <div style={{ marginRight: 20 }}>
          <div style={{ height: 90, width: 400 }}>{chart?.graph_label?.length >= 62 ? chart?.graph_label.substring(0, 62) + " ..." : chart?.graph_label}</div>
          <ResponsiveContainer width={300} height={300}>
            <LineChart
              data={chart?.data}
              height={height * 0.4}
              width={(width - 400) * 0.3}
              margin={{
                top: 10,
                bottom: 10,
              }}
            >
              <XAxis dataKey="year" />
              <YAxis
                domain={["auto", "auto"]}
                label={{
                  value: data[0]?.y_axis_label,
                  angle: -90,
                  dx: -17,
                  fontSize: 12,
                }}
              />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <Line type="monotone" dataKey={`${selectedPlace?.name}`} stroke="#f0a400" />
              <Line type="monotone" dataKey="South Africa" stroke="#009640" />
            </LineChart>
          </ResponsiveContainer>
          <div style={{ marginLeft: 70, height: 95 }}>
            <p style={{ margin: 0, fontSize: 14, textDecoration: "underline", marginLeft: 170 }}>{chart.data[chart.data.length - 1].year}</p>
            {Object.entries(chart.data[chart.data.length - 1]).map(([key, value], index) => {
              if (key !== "year") {
                return (
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 200 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: 10, height: 10, marginRight: 10, backgroundColor: COLORS[index - 1] }} />
                      <p style={{ margin: 0, width: 150 }}>{key}</p>
                    </div>

                    <p style={{ margin: 0, width: 50 }}>{value}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))}
    </div>
  );

  const numberOfPages = data.length > 3 ? (data.length > 9 ? 3 : 2) : 1;

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : pageOneChartImageUrl && pageTwoChartImageUrl && pageThreeChartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>
                {selectedSpatialLayer?.name} | {displayMunicname}
              </Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={{ ...styles.heading, marginBottom: 0 }}>GROWTH BY SECTOR</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>
                  {industryFilter} | (1/{numberOfPages})
                </Text>
              </View>
              <View>
                <Text style={styles.paragraph}>How does jobs growth compare between and within sectors in {displayMunicname}?</Text>
                <Text style={styles.paragraph}>
                  Total jobs growth hides important differences in performance both between and within sectors of the local economy. National trends are provided as a useful benchmark in each case. Exploring which particular sub-sectors are leading or lagging in jobs growth is critical for policy makers in better supporting local industry. Jobs growth has been indexed to show performance relative to industry size in all figures. Very small industries with less than or equal to 200 employees have
                  been omitted.
                </Text>
              </View>
              <View style={styles.section}>
                <Image style={{ ...styles.image, marginTop: 20 }} src={pageOneChartImageUrl} />
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
              </View>
            </Page>
            {otherData.length > 3 && (
              <Page size="A4" style={styles.page}>
                <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                <Text style={styles.headerText}>
                  {selectedSpatialLayer?.name} | {displayMunicname}
                </Text>
                <View>
                  <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
                </View>
                <View style={{ ...styles.section, paddingBottom: 0 }}>
                  <Text style={{ ...styles.heading, marginBottom: 0 }}>GROWTH BY SECTOR</Text>
                </View>
                <View style={{ ...styles.section, paddingBottom: 0 }}>
                  <Text style={{ ...styles.subheading, marginBottom: 0 }}>
                    {industryFilter} | (2/{numberOfPages})
                  </Text>
                </View>
                <View style={styles.section}>
                  <Image style={{ ...styles.image, marginTop: 0 }} src={pageTwoChartImageUrl} />
                </View>
                <View style={styles.footerContainer}>
                  <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                </View>
              </Page>
            )}
            {otherData.length > 12 && (
              <Page size="A4" style={styles.page}>
                <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                <Text style={styles.headerText}>
                  {selectedSpatialLayer?.name} | {displayMunicname}
                </Text>
                <View>
                  <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
                </View>
                <View style={{ ...styles.section, paddingBottom: 0 }}>
                  <Text style={{ ...styles.heading, marginBottom: 0 }}>GROWTH BY SECTOR</Text>
                </View>
                <View style={{ ...styles.section, paddingBottom: 0 }}>
                  <Text style={{ ...styles.subheading, marginBottom: 0 }}>
                    {industryFilter} | (3/{numberOfPages})
                  </Text>
                </View>
                <View style={styles.section}>
                  <Image style={{ ...styles.image, marginTop: 0 }} src={pageThreeChartImageUrl} />
                </View>
                <View style={styles.footerContainer}>
                  <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                </View>
              </Page>
            )}
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={pageOneChartRef}>
          <div className="px-10 flex justify-center">{mainData?.graph_label?.length >= 62 ? mainData?.graph_label.substring(0, 62) + " ..." : mainData?.graph_label}</div>
          <ResponsiveContainer width={1400} height={500}>
            <LineChart
              data={mainData?.data}
              height={height * 0.45}
              width={width * 0.7}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 50,
              }}
              animationDuration={1000}
            >
              <XAxis dataKey="year" />
              <YAxis
                domain={["auto", "auto"]}
                label={{
                  value: data[0]?.y_axis_label,
                  angle: -90,
                  dx: -50,
                }}
              />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <Line type="monotone" dataKey={`${selectedPlace?.name}`} stroke="#f0a400" strokeWidth={3} />
              <Line type="monotone" dataKey="South Africa" stroke="#009640" strokeWidth={3} />
            </LineChart>
          </ResponsiveContainer>
          <div style={{ marginLeft: 150, marginBottom: 20 }}>
            {mainData?.data && (
              <>
                <p style={{ margin: 0, fontSize: 14, textDecoration: "underline", marginLeft: 250 }}>{mainData.data[mainData.data.length - 1].year}</p>
                {Object.entries(mainData.data[mainData.data.length - 1]).map(([key, value], index) => {
                  if (key !== "year") {
                    return (
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 300 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ width: 10, height: 10, marginRight: 10, backgroundColor: COLORS[index - 1] }} />
                          <p style={{ margin: 0, width: 150 }}>{key}</p>
                        </div>

                        <p style={{ margin: 0, width: 50 }}>{value}</p>
                      </div>
                    );
                  }
                })}
              </>
            )}
          </div>
          <SmallChartComponent dataSlice={otherData.slice(0, 3)} />
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={pageTwoChartRef}>
          <SmallChartComponent dataSlice={otherData.slice(3, 6)} />
          <SmallChartComponent dataSlice={otherData.slice(6, 9)} />
          <SmallChartComponent dataSlice={otherData.slice(9, 12)} />
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={pageThreeChartRef}>
          <SmallChartComponent dataSlice={otherData.slice(12, 15)} />
          <SmallChartComponent dataSlice={otherData.slice(15, 18)} />
        </div>
      </div>
    </>
  );
}

export default GrowthBySectorPdf;
