import React from "react";
import background from "../../images/background-optimised.jpg";
import Footer from "../../shared/components/Footer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { routeConstants } from "../../shared/constants";
import { NavLink } from "react-router-dom";

function DownloadDataLandingPage() {
  return (
    <div className="relative">
      <div
        style={{
          height: "calc(100vh - 80.88px)",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-between"
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="flex flex-col items-center z-10 p-5">
          <h1 className="text-7xl text-white p-8">DOWNLOAD DATA</h1>
          <div className="bg-white bg-opacity-20 border rounded p-6 border-0 flex flex-col items-center" style={{ width: "70%", backgroundColor: "#282F3980" }}>
            <h2
              style={{
                fontSize: 20,
                color: "white",
                width: "80%",
                textAlign: "center",
              }}
              className="font-sans"
            >
              Raw tax data is curated as an open access database for you to easily download! There are no paywalls or user barriers so sign up to get free and immediate access.
              <br />
              <br />
              Our metadata and methodology are also available for download along with the raw data. Please do share widely with your networks but remember to acknowledge us as the source.
            </h2>
            <div className="flex justify-between mx-10 mt-10">
              <p style={{ width: "45%", color: "white", margin: 0 }} className="font-sans">
                Step 1: Register / Login
              </p>
              <p style={{ width: "45%", color: "white" }} className="font-sans">
                Step 2: Visit Download Data page where you can download select CSVs or all CSVs
              </p>
            </div>
          </div>
          <NavLink to={routeConstants.DOWNLOAD_DATA_FILTER_FORM}>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: 50,
                padding: 8,
                paddingLeft: 14,
                color: "#F0A400",
                marginTop: 50,
              }}
              className="font-sans"
            >
              Go To Download Data
              <ArrowForwardIosIcon />
            </button>
          </NavLink>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default DownloadDataLandingPage;
