import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import saveAs from "file-saver";
import swal from "sweetalert";
import axiosInstance from "../axiosApi";
import { routeConstants } from "../../shared/constants";

const HsrcAdminHome = () => {
	const navigate = useNavigate();

	const [isGettingDownloads, setIsGettingDownloads] = useState(false);

	const isAdmin = () => {
		return localStorage.getItem("has_admin_access");
	};

	async function getDownloads() {
		try {
			setIsGettingDownloads(true);
			let response = await axiosInstance.get("/download_history/download_report_file/", { timeout: 0, responseType: 'arraybuffer' });
			setIsGettingDownloads(false);

			saveAs(new Blob([response.data]), "Download-Report.csv"); //TODO: Add date and fulltime to end of filename
			swal("Success", "Report generated", "success", {
				buttons: false,
				timer: 2000,
			});
		} catch (error) {
			setIsGettingDownloads(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 2000,
			});
		}
	}

	return (
		<div>
			{isAdmin() ? (
				<div>
					<div className='bg-stone-200 py-6 flex flex-col justify-center relative overflow-hidden sm:py-12'>
						<div className='text-3xl px-10 font-bold'>HSRC Administration</div>
						<div className='text-lg px-10 italic'>Manage data and users</div>
						<div className='grid grid-cols-1 md:grid-cols-4 mt-8'>
							{/* <div className='mx-auto'>
								<button
									onClick={() => navigate(routeConstants.HSRC_ADMIN_MANAGE_DATA_SETS)}
									className='btn btn-primary w-80'
								>
									Manage Datasets
								</button>
							</div>
							<div className='mx-auto'>
								<button
									onClick={() => navigate(routeConstants.HSRC_ADMIN_MANAGE_CONFIG_TABLE)}
									className='btn btn-primary w-80'
								>
									Manage Configuration Table
								</button>
							</div> */}
							<div className='mx-auto'>
								<button
									onClick={() => navigate(routeConstants.HSRC_ADMIN_MANAGE_USERS)}
									className='btn btn-primary w-80'
								>
									Manage Users
								</button>
							</div>
							<div className='mx-auto'>
								<button
									onClick={() => getDownloads()}
									className='btn btn-primary w-80'
								>
									{isGettingDownloads ? <span className='spinner-border animate-spin inline-block w-5 h-5 border-4 rounded-full'></span> : "Download Report"}
								</button>
							</div>
						</div>
					</div>
					<div className="m-10">
						<h2 className='title'>About these tools</h2>
						<div className='text-lg'>
							<p>These tools allow administrators of the Online Geospatial Analysis Tool to upload new CSV files, manage datasets configure, manage and
							review usage of the system.</p>
							<p>NOTE: CSV Dataset and Filter Config Management currently done manually.</p>
						</div>
					</div>
				</div>
			) : (
				<Navigate to='/' replace={true} />
			)}
		</div>
	);
};

export default HsrcAdminHome;
