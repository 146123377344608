import axios from "axios";
import swal from "sweetalert";

const axiosNoAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axiosNoAuthInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 500) {
      // window.location = "/error";
    }
    if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
      // window.location = "/logout";
      localStorage.removeItem("accessToken");
      axiosNoAuthInstance.defaults.headers["Authorization"] = null;
    }
    if (error.response.status === 404) {
      return null;
    }

    return Promise.reject(error);
  }
);

export default axiosNoAuthInstance;
