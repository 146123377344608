import React, { Fragment } from 'react'
import { useMemo, useState, useEffect } from 'react'
import swal from 'sweetalert'
import { TableDisplay } from '../TableDisplay/TableDisplay'
import axiosNoAuthInstance from "../axiosApiNoAuth";
import { ConfigFormModal } from './ConfigFormModal';

const ManageConfigTable = () => {
  const [tableData, settableData] = useState([]);
  const [showConfigForm, setshowConfigForm] = useState(false);
  const [tableRow, setTableRow]  = useState(null);

  useEffect(async e => {
    try {
      const response = await axiosNoAuthInstance.get('/api/filterconfig/');
      var tempdata = [];
      for (let i = 0; i < response.data.length; i++) {
        tempdata.push({
          id: response.data[i].id,
          excluded: response.data[i].excluded,
          output_variable: response.data[i].output_variable,
          spatial_variable: response.data[i].spatial_variable,
          temporal_variable: response.data[i].temporal_variable,
          aggregate_variable: response.data[i].aggregate_variable,
          csv_file: response.data[i].csv_file,
          shape_file: response.data[i].shape_file,
          variable_names: response.data[i].variable_names,
          additional_dropdown: response.data[i].additional_dropdown,
          viewas_map: response.data[i].viewas_map,
          viewas_chart: response.data[i].viewas_chart,
          additional: response.data[i].additional,
        })
      }
      settableData(tempdata)
    } catch (e) {
      swal("Failed", "error", "error", {
        buttons: false,
        timer: 2000,
      })
    }
  }, []);
  function handleEdit(value) {
    setshowConfigForm(!showConfigForm);
    setTableRow(value);
  };
  function hideModal() {
    setshowConfigForm(false);
  };
  const columns = useMemo(
    () => [
      {
        Header: "Action",
        accessor: "action",
        Cell: props => (
          <div>
            <button className="bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1" onClick={() => handleEdit(props.cell.row.values)}>Edit</button>
          </div>
        )
      },
      {
        Header: 'Id',
        accessor: 'id', // accessor is the "key" in the data
      },
      {
        id: 'excluded',
        Header: 'Excluded',
        accessor: row => { return row.excluded ? 'Yes' : 'No' },
      },
      {
        Header: 'Output Variable',
        accessor: 'output_variable',
      },
      {
        Header: 'Spatial Variable',
        accessor: 'spatial_variable',
      },
      {
        Header: 'Temporal Variable',
        accessor: 'temporal_variable',
      },
      {
        Header: 'Aggregate Variable',
        accessor: 'aggregate_variable',
      },
      {
        Header: 'Csv File',
        accessor: 'csv_file',
      },
      {
        Header: 'Shape File',
        accessor: 'shape_file',
      },
      {
        Header: 'Variable Name',
        accessor: 'variable_names',
      },
      {
        Header: 'Additional Dropdown',
        accessor: 'additional_dropdown',
      },
      {
        Header: 'ViewAs Map',
        accessor: 'viewas_map',
      },
      {
        Header: 'ViewAs Chart',
        accessor: 'viewas_chart',
      },
      {
        Header: 'Additional',
        accessor: 'additional',
      },
    ],
    []
  )

  return (
    <div className="bg-slate-200 py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
      <span className="border text-4xl text-blue-400 px-6 pt-10 pb-8 bg-white w-5/6  mx-auto rounded-t-md sm:px-10">Filter Configuration</span>
      <div className="overflow-auto border relative px-4 pt-7 pb-8 bg-white shadow-xl w-5/6  mx-auto sm:px-10 rounded-b-md">

        {showConfigForm ? (
          <ConfigFormModal currentRow = {tableRow} hideModal={hideModal}>
            <p>Modal</p>
          </ConfigFormModal>) :null}
            {<TableDisplay data={tableData} columns={columns} />}
          
  
</div>
</div>  )
}

export default ManageConfigTable