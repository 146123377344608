// import dayjs from "dayjs"; // {dayjs().subtract(1, "year").format("YYYY")}
import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Box, CircularProgress } from "@mui/material";
import { numberFormatter } from "../../../shared/utils";
import { useWindowDimensions } from "../../../shared/hooks";
import { jobsForYouthDataAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, spatialParameterAtom } from "../../../shared/state/atoms";
import { useAtom } from "jotai";

function JobsForYouth() {
  const { height, width } = useWindowDimensions();
  const [data, setData] = useAtom(jobsForYouthDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-jobs-for-youth-barchart/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`);
        }
        if (response) {
          setData(
            response?.data.map((item) => {
              const { year, graph_label, plot_details } = item;
              const [
                {
                  1: [{ data, y_label, z_label }],
                },
              ] = plot_details;

              return {
                year,
                graph_label,
                y_label,
                z_label,
                data: data.map((point) => ({
                  x: point.x,
                  y: point.y,
                  z: point.z,
                  point_label: point.point_label,
                  point_short_label: point.point_short_label,
                })),
              };
            })
          );
          setErrorMessage(null);
        } else {
          setData([]);
          setErrorMessage(`No Data available for ${selectedFilters?.singleYear}`);
        }
        setIsLoading(false);
      } catch (error) {
        swal("Failed", `${error}`, "error", {
          timer: 4000,
          buttons: false,
        });
      }
    }

    if (spatialParameter && selectedSpatialValue) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialValue, selectedFilters]);

  function getPointLabel(data, label) {
    for (const item of data) {
      if (item.point_short_label === label) {
        return item.point_label;
      }
    }
    return null;
  }

  function getYouthJobs(data, label) {
    for (const item of data) {
      if (item.point_short_label === label) {
        return item.y;
      }
    }
    return null;
  }

  function getPercOfSector(data, label) {
    for (const item of data) {
      if (item.point_short_label === label) {
        return item.z;
      }
    }
    return null;
  }

  const CustomTooltip = ({ active, payload, label, graph }) => {
    const formattedNumber = getYouthJobs(graph.data, label)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white" key={label}>
          <p className="m-0">{getPointLabel(graph.data, label)}</p>
          <p className="m-0">
            {graph.y_label}: {formattedNumber}
          </p>
          <p className="m-0">
            {graph.z_label}: {getPercOfSector(graph.data, label)}%
          </p>
        </div>
      );
    }

    return null;
  };

  const COLORS = ["#f0a400", "#009640"];

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Equitable Economies
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">Jobs For Youth {data[0]?.year}</div>

        {!errorMessage ? (
          <div>
            <div style={{ height: `${height * 0.7}px` }} className="overflow-y-auto remove-overflow-during-print">
              {isLoading && (
                <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                  <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                    <CircularProgress color="success" size="3rem" />
                  </Box>
                </div>
              )}
              {data.map((graph, index) => (
                <div className="mb-5" key={index}>
                  <div className="px-10 font-bold flex justify-center">{graph.graph_label}</div>
                  <BarChart
                    data={graph.data}
                    width={(width - 400) * 0.9}
                    height={400}
                    margin={{
                      top: 20,
                      right: 50,
                      left: 100,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3" vertical={false} />
                    <XAxis dataKey="point_short_label" />
                    <YAxis
                      tickFormatter={(value) => numberFormatter(value, 2)}
                      label={{
                        value: "Total Jobs",
                        angle: -90,
                        dx: -50,
                      }}
                    />
                    <Tooltip content={<CustomTooltip graph={graph} />} />
                    {!isLoading && <Bar dataKey="y" fill={COLORS[index]} />}
                  </BarChart>
                </div>
              ))}
            </div>
            <div className="pt-2 flex justify-center hidden-during-print">
              <KeyboardDoubleArrowDownIcon />
            </div>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default JobsForYouth;
