import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axiosNoAuthInstance from "../axiosApiNoAuth";
import swal from "sweetalert";
import { routeConstants } from "../../shared/constants";

const ResetPassword = () => {
	let { email, token } = useParams();
	const [password, setPassword] = useState("");
	const [isResetting, setIsResetting] = useState(false);

	const canSubmit = () => {
		return password.trim() !== "";
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password.trim().length < 9) {
			return swal("Invalid", "Password length must be longer than 8", "info", {
				buttons: false,
				timer: 2500,
			});
		}
		
		try {
			setIsResetting(true);
			await axiosNoAuthInstance.put(`/user-api/user/confirm_reset_password/${email}/${token}/`, {
				password: password,
			});
			setIsResetting(false);
			window.location.href = routeConstants.LOGIN;
		} catch (error) {
			setIsResetting(false);
			swal("Failed", error?.response?.data?.message ?? "error", "error", {
				buttons: false,
				timer: 2000,
			});
		}
	};

	return (
		<div className='w-80 mx-auto mt-12 px-4 md:px-0'>
			<h2 className='title'>Reset Password</h2>
			<form noValidate onSubmit={handleSubmit}>
				<input
					type='password'
					className='form-control placeholder-black bg-gray-100'
					placeholder='Enter password'
					onChange={(e) => setPassword(e.target.value)}
				/>
				<div className='mt-8'>
					<button type='submit' className={`btn ${canSubmit() ? "btn-secondary" : "btn-disabled"} w-full`} disabled={!canSubmit()}>
						{isResetting ? <span className='spinner-border animate-spin inline-block w-5 h-5 border-4 rounded-full'></span> : "Submit"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default ResetPassword;
