import React from 'react'

const TaxDataHome = () => {
  return(
    <div className="bg-slate-200 py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
      <span className="border text-4xl text-blue-400 px-6 pt-10 pb-8 bg-white w-1/2 max-w-md mx-auto rounded-t-md sm:px-10">Tax Analysis</span>
      <div className="border relative px-4 pt-7 pb-8 bg-white shadow-xl w-1/2 max-w-md mx-auto sm:px-10 rounded-b-md">

      Introduction<br/>
      <br/>
      How to use<br/>
      <br/>
      Login<br/>
      <br/>
      <p ><a href="/tax-data-filter-form" className="btn" >View Data</a></p>
      </div>
      </div>
  )
}

export default TaxDataHome