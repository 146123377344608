import React, {useEffect} from 'react'

const UserLogout = () => {
    useEffect(() => {
      localStorage.clear();
      window.location.href = '/';
    });

  return (
    <div>
    </div>
  )
}

export default UserLogout