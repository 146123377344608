import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, spatialParameterAtom, yearsRangeAtom } from "../../shared/state/atoms";
import { dashboardGroupings, dashboardLabels, dashboardRoutes } from "../../shared/constants";
import axiosNoAuthInstance from "../axiosApiNoAuth";
import {
  ChangeInMetro,
  DashboardMenus,
  DominantSectors,
  Exporters,
  GrowthBySector,
  IndustryProfile,
  IndustryPotential,
  IndustryStructure,
  JobDensity,
  JobsForYouth,
  JobsGrowth,
  MultiEstablishmentFirms,
  NewJobs,
  TotalJobsGrowth,
  WageDistributionOverview,
  WageDistributions,
  WageInequality,
  WageLevels,
  JobsGrowthFilter,
  IndustryStructureFilter,
  WageDistributionOverviewFilter,
  TotalJobsGrowthFilters,
  NewJobsFilter,
  GrowthBySectorFilter,
  IndustryProfileFilter,
  IndustryPotentialFilter,
  ExportersFilter,
  MultiEstablishmentFirmsFilter,
  JobDensityFilter,
  WageInequalityFilter,
  WageLevelsFilter,
  WageDistributionFilter,
  JobsForYouthFilter,
} from "./components";
import { transformKeys } from "../../shared/utils";
import { ChangeInMetroFilter } from "./components/filters";

function DashboardsOverview() {
  let { dashboard } = useParams();
  const [yearsRange, setYearsRange] = useAtom(yearsRangeAtom);
  const [globablFiltersState, setGlobablFiltersState] = useAtom(selectedFiltersAtom);
  const [routesArray, setRoutesArray] = useState([]);
  const [selectedDashboardIndex, setSelectedDashboardIndex] = useState();
  const [selectedDashboard, setSelectedDashboard] = useState(dashboard);
  const [selectedFilters] = useAtom(selectedFiltersAtom);

  const [municipalities, setMunicipalities] = useState([]);
  const [districts, setDistricts] = useState();
  const [provinces, setProvinces] = useState();

  const [availableSpatialLayers, setAvailableSpatialLayers] = useState();
  const [spatialParameter, setSpatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialValue, setSelectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [selectedSpatialLayer, setSelectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);

  const [selectedPlace, setSelectedPlace] = useAtom(selectedPlaceAtom);

  const [content, setContent] = useState();
  const [description, setDescription] = useState();
  const [filters, setFilters] = useState();

  useEffect(() => {
    if (selectedSpatialLayer && !selectedSpatialLayer) {
      const availableSpatialLayers = [
        { id: "municipality", name: "Municipality" },
        { id: "district", name: "District" },
        { id: "province", name: "Province" },
      ];
      setAvailableSpatialLayers(availableSpatialLayers);
      const spatialLayer = availableSpatialLayers.find((spatialLayer) => spatialLayer.id === selectedSpatialLayer);
      setSelectedSpatialLayer(spatialLayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpatialLayer, selectedSpatialLayer]);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await axiosNoAuthInstance.get("/api/municipality-list/");
        transformKeys(response.data, "name", "municname");
        response.data.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
        setMunicipalities(response.data);
      } catch (e) {
        console.log(e);
      }
    }
    loadData();
  }, []);

  // Districts

  useEffect(() => {
    const districts_dup = municipalities.map((element) => {
      // Only district info in duplicate, renamed keys
      return {
        id: element.district,
        name: element.district_n,
        category: element.category,
        province: element.province,
        province_n: element.province_n,
      };
    });

    const districts = districts_dup.filter(
      // De-duplicated district info
      ({ id, name }, index) => {
        return districts_dup.findIndex((item) => item.id === id && item.name === name) === index;
      }
    );
    setDistricts(districts);
  }, [municipalities]);

  // Provinces

  useEffect(() => {
    const provinces_dup = municipalities.map((element) => {
      // Only province info in duplicate, renamed keys
      return {
        id: element.province,
        name: element.province_n,
        category: element.category,
        province: element.province,
      };
    });

    const provinces = provinces_dup.filter(
      // De-duplicated province info
      ({ id, name }, index) => {
        return provinces_dup.findIndex((item) => item.id === id && item.name === name) === index;
      }
    );

    setProvinces(provinces);
  }, [municipalities]);

  useEffect(() => {
    if (selectedSpatialLayer?.id === "municipality" && selectedPlace?.category === "A") {
      setRoutesArray(Object.values(dashboardRoutes));
    } else {
      const routes = Object.values(dashboardRoutes);
      const filteredRoutes = routes.filter((route) => route !== dashboardRoutes.CHANGE_WITHIN_METRO && route !== dashboardRoutes.JOB_DENSITY);
      setRoutesArray(filteredRoutes);
    }
  }, [selectedSpatialLayer, selectedPlace]);

  useEffect(() => {
    let index = routesArray.findIndex((route) => window.location.pathname.includes(`/${route}`));
    setSelectedDashboardIndex(index);
  }, [selectedDashboard, routesArray]);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await axiosNoAuthInstance.get("/api/metro-year/");
        let years = response.data.map((year) => year.taxyear);
        years.sort((a, b) => {
          return a > b ? 1 : -1;
        });
        setYearsRange(years);
        setGlobablFiltersState({
          ...globablFiltersState,
          baseYear: years[0],
          startYear: years[0],
          endYear: years[years.length - 1],
          singleYear: years[years.length - 1],
        });
      } catch (e) {
        console.log(e);
      }
    }
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter]);

  useEffect(() => {
    const parameters = selectedSpatialLayer?.id === "municipality" ? "cat_b" : selectedSpatialLayer?.id === "province" ? "province" : selectedSpatialLayer?.id === "district" ? "district" : "error";
    setSpatialParameter(parameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpatialLayer]);

  useEffect(() => {
    if (selectedSpatialLayer?.id === "municipality" && selectedPlace?.name === "Emalahleni") {
      const newname = selectedPlace?.name + " (" + selectedPlace?.province + ")";
      setSelectedPlace({ ...selectedPlace, name: newname });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpatialLayer, selectedPlace]);

  useEffect(() => {
    if (yearsRange?.length > 0 && selectedPlace && selectedSpatialValue && selectedSpatialValue !== "" && selectedSpatialLayer && selectedDashboard)
      switch (selectedDashboard) {
        case dashboardRoutes.JOBS_GROWTH:
          setContent(<JobsGrowth />);
          setFilters(<JobsGrowthFilter />);
          setDescription(
            <>
              <p>
                How many jobs are available in <b>{selectedPlace?.name}</b>?
              </p>
              <p>Jobs reported in the spatial tax panel are based on IRP5 certificates which are converted into 'Full Time Equivalents' (FTE), or in other words, full time employment. The data covers employment by firms in the formal sector but excludes informal jobs where firms are not tax compliant. The total number of jobs is a critical indicator of the level of 'decent work' or size of the formal 'mainstream' economy. </p>
            </>
          );
          break;
        case dashboardRoutes.INDUSTRY_STRUCTURE:
          setContent(<IndustryStructure />);
          setFilters(<IndustryStructureFilter />);
          setDescription(
            <>
              <p>
                Which sectors are the biggest employers in <b>{selectedPlace?.name}</b>?
              </p>
              <p>
                The top 10 sectors ranked here are most important for jobs and livelihoods. The economic outlook depends on the capacity of firms in these sectors to expand, adapt and innovate or otherwise risk becoming a drag on economic performance. Non-tradable sectors such as government services tend to be stable but are inherently constrained by the size of local economy (i.e. local demand). Tradable sectors can compete in surrounding (and even international) markets but must be competitive
                to thrive.
              </p>
            </>
          );
          break;
        case dashboardRoutes.WAGE_DISTRIBUTION_OVERVIEW:
          setContent(<WageDistributionOverview />);
          setFilters(<WageDistributionOverviewFilter />);
          setDescription(
            <>
              <p>
                What do jobs pay for people working in <b>{selectedPlace?.name}</b>?
              </p>
              <p>The wage distribution shows the number of people who earn at a particular level in Rands per month. The level of earnings is closely related to demand-side factors such as sector of employment as well as supply-side factors such as worker experience, education and occupation. The number of people in each wage category is important to bear in mind considering the local population that these jobs support.</p>
            </>
          );
          break;
        case dashboardRoutes.TOTAL_JOBS_GROWTH:
          setContent(<TotalJobsGrowth />);
          setFilters(<TotalJobsGrowthFilters />);
          setDescription(
            <>
              <p>
                How does total jobs growth compare in <b>{selectedPlace?.name}</b>?
              </p>
              <p>
                Indexed total jobs shows the relative performance of each region adjusted for its size in the starting year. It can also be interpreted as the total percentage change in jobs from the starting year by simply subtracting 100. Instability over time can be a consequence of a small economic base but also if the economy is stagnant which compresses the y-axis. Getting positioned above (or below) the ‘national’ benchmark implies that this local economy is pulling ahead (or falling
                behind) the rest of the country.
              </p>
            </>
          );
          break;
        case dashboardRoutes.NEW_JOBS:
          setContent(<NewJobs />);
          setFilters(<NewJobsFilter />);
          setDescription(
            <>
              <p>
                Which sectors created the most new jobs in <b>{selectedPlace?.name}</b>?
              </p>
              <p>The sectors which create the most new jobs have a critical role to play within the local economy. Job creation can be driven by economic expansion in dynamic sectors. However total jobs growth can also be related to industry size (or dominance) because modest and even low growth will add up to more jobs in large industries. In either case, these sectors mattered most for job creation.</p>
            </>
          );
          break;
        case dashboardRoutes.GROWTH_BY_SECTOR:
          setContent(<GrowthBySector />);
          setFilters(<GrowthBySectorFilter />);
          setDescription(
            <>
              <p>
                How does jobs growth compare between and within sectors in <b>{selectedPlace?.name}</b>?
              </p>
              <p>
                Total jobs growth hides important differences in performance both between and within sectors of the local economy. National trends are provided as a useful benchmark in each case. Exploring which particular sub-sectors are leading or lagging in jobs growth is critical for policy makers in better supporting local industry. Jobs growth has been indexed to show performance relative to industry size in all figures. Very small industries with <i>less than or equal to 200 employees</i>{" "}
                have been omitted.
              </p>
            </>
          );
          break;
        case dashboardRoutes.CHANGE_WITHIN_METRO:
          setContent(<ChangeInMetro />);
          setFilters(<ChangeInMetroFilter />);
          setDescription(
            <>
              <p>
                Where are jobs and firms being created or lost within <b>{selectedPlace?.name}</b>?
              </p>
              <p>Spatial patterns of economic change are a fundamental lens for local economic planning. Cities are made up of a mixed assortment of precincts with different levels of amenities and infrastructure investment. Spatial patterns can be explored in greater detail using the ‘map explorer’ tool.</p>
            </>
          );
          break;
        case dashboardRoutes.INDUSTRY_PROFILE:
          setContent(<IndustryProfile />);
          setFilters(<IndustryProfileFilter />);
          setDescription(
            <>
              <p>
                What is the structure of industry in <b>{selectedPlace?.name}</b>?
              </p>
              <p>Total jobs are split up across industries for sectors (inner pie) and sub-sectors (outer pie) to provide a detailed overview of the structure of local industry. Industries are defined according to the Standard Industrial Classification of all Economic Activities (seventh edition) which includes up to 90 divisions (sub-sectors in the outer pie) for an in-depth review.</p>
            </>
          );
          break;
        case dashboardRoutes.INDUSTRY_POTENTIAL:
          setContent(<IndustryPotential />);
          setFilters(<IndustryPotentialFilter />);
          setDescription(
            <>
              <p>
                Which sectors show the most potential in <b>{selectedPlace?.name}</b>?
              </p>
              <p>
                The four quadrants is a tool to sort economic performance for industries across three dimensions: size of industry (bubble size), the location quotient (LQ) or industry specialisation (x-axis, horizontal) and jobs growth (y-axis, vertical). The LQ is a statistical way of a way of quantifying how concentrated a particular industry is (relative to the national average) and therefore the extent of specialisation. Together, these dimensions are indicative of ‘winners’ and ‘losers’
                where industries in the top right (i.e. solid bet) display the greatest potential. The industry toggle allows the quadrants model to be rerun to at the level of sub-sectors for any industry.
              </p>
            </>
          );
          break;
        case dashboardRoutes.EXPORTERS:
          setContent(<Exporters />);
          setFilters(<ExportersFilter />);
          setDescription(
            <>
              <p>
                How do the level of exports compare in <b>{selectedPlace?.name}</b>?
              </p>
              <p>
                Data about exports is based on customs submissions which represents a sub-sample of for-profit firms (i.e. excludes government agencies and non-profit organisations). Exports data is only available at enterprise level which means that the local establishment (or branch) is part of a firm involved in international trade but may not import or export directly itself. For these reasons, the results are only suggestive of the extent of international trade within the local economy. The
                figures do not include industries with <i>less than 10 establishments in total.</i>
              </p>
            </>
          );
          break;
        case dashboardRoutes.MULTI_ESTABLISHMENT_FIRMS:
          setContent(<MultiEstablishmentFirms />);
          setFilters(<MultiEstablishmentFirmsFilter />);
          setDescription(
            <>
              <p>
                What types of firms are located in <b>{selectedPlace?.name}</b>?
              </p>
              <p>
                Larger multi-establishment firms have more than one location or branch whereas single-location firms are dependent on local business conditions. Multi-establishments firms can take advantage of their size and national networks to reach and operate in diverse and even remote locations. Their expansion across multiple sites is a clear sign of business success. Deeper information about multi-establishment firms is difficult to spatialise because functions often differ between
                branches. The figures do not include industries with <i>less than 10 establishments in total.</i>
              </p>
            </>
          );
          break;
        case dashboardRoutes.JOB_DENSITY:
          setContent(<JobDensity />);
          setFilters(<JobDensityFilter />);
          setDescription(
            <>
              <p>
                Where are jobs concentrated within <b>{selectedPlace?.name}</b>?
              </p>
              <p>The density or concentration of jobs is indicative of the form and function of cities. Cities sometimes include a number of sub-centres of economic activity with distinct specialisations. These hubs depend on sufficient investment in infrastructure to densify without becoming overloaded and congested. Spatial patterns can be explored in greater detail using the ‘map explorer’ tool.</p>
            </>
          );
          break;
        case dashboardRoutes.DOMINANT_SECTORS:
          setContent(<DominantSectors />);
          setFilters();
          setDescription();
          break;
        case dashboardRoutes.WAGE_INEQUALITY:
          setContent(<WageInequality />);
          setFilters(<WageInequalityFilter />);
          setDescription(
            <>
              <p>
                What is the level of wage inequality in <b>{selectedPlace?.name}</b>?
              </p>
              <p>
                The gini coefficient is a statistical calculation of inequality where a value of 0 represents absolute equality and a value of 100 absolute inequality. The wage gini calculated from tax data is a measure of inequality amongst wage earners who work in the formal sector and is very different to measuring income inequality amongst all workers or amongst households (which would also include the unemployed). Wage inequality remains a fundamental driver of income inequality in South
                Africa.
              </p>
            </>
          );
          break;
        case dashboardRoutes.WAGE_LEVELS:
          setContent(<WageLevels />);
          setFilters(<WageLevelsFilter />);
          setDescription(
            <>
              <p>
                How have average wages changed in <b>{selectedPlace?.name}</b>?
              </p>
              <p>Median income measures the middle or halfway point in wage rankings amongst formal workers. It is a less sensitive measurement of average earnings because it is not impacted by outliers. Median wage levels need to rise each year just to keep up with inflation. The average worker is better off where median wage levels are higher. Median wage levels reported in tax data exclude informal work and the unemployed.</p>
            </>
          );
          break;
        case dashboardRoutes.WAGE_DISTRIBUTIONS:
          setContent(<WageDistributions />);
          setFilters(<WageDistributionFilter />);
          setDescription(
            <>
              <p>
                How do earnings compare in <b>{selectedPlace?.name}</b>?
              </p>
              <p>The wage distribution offers a more detailed review of earnings than the median wage level or the wage gini. It shows the percentage of formal workers who fall in wage bands across the spectrum ranging from very low to very high earners. A bias to the right (left) suggests a concentration of higher (lower) income earners. The industry toggle allows for wage comparisons for specific industries. </p>
            </>
          );
          break;
        case dashboardRoutes.JOBS_FOR_YOUTH:
          setContent(<JobsForYouth />);
          setFilters(<JobsForYouthFilter />);
          setDescription(
            <>
              <p>
                Which industries offer the most jobs for youth in <b>{selectedPlace?.name}</b>?
              </p>
              <p>
                Industries which create more job opportunities for young people can also play a development role within society. The total number of jobs for youth (<i>aged 15 – 25</i>) related to the intensity of youth employment in that sector along with total industry size. A higher youth jobs intensity (share of jobs for youth within the sector) is preferable although sheer industry size can lead to jobs for youth because of volume.{" "}
              </p>
            </>
          );
          break;
        default:
          setContent(<JobsGrowth />);
          setFilters(<JobsGrowthFilter />);
          setDescription();
          break;
      }
  }, [selectedSpatialValue, selectedDashboard, spatialParameter, selectedSpatialLayer, selectedPlace, selectedFilters, yearsRange]);

  const handleNextClick = () => {
    const newPageRoute = routesArray[selectedDashboardIndex + 1];
    setSelectedDashboard(newPageRoute);
    window.history.pushState({}, "", `/dashboards/${newPageRoute}`);
  };

  const handlePrevClick = () => {
    const newPageRoute = routesArray[selectedDashboardIndex - 1];
    setSelectedDashboard(newPageRoute);
    window.history.pushState({}, "", `/dashboards/${newPageRoute}`);
  };

  const isCurrentDashboardGroup = (group) => {
    return group.some((route) => window.location.pathname.includes(`/${route}`));
  };

  const isFirstDashboardGroup = (group) => {
    return routesArray[selectedDashboardIndex] === group[0];
  };

  const isLastDashboardGroup = (group) => {
    if (selectedPlace?.category === "A" && selectedSpatialLayer?.id === "municipality") {
      return routesArray[selectedDashboardIndex] === group[group.length - 1];
    } else {
      const filteredDashboards = group.filter((dashboard) => dashboard !== dashboardRoutes.CHANGE_WITHIN_METRO && dashboard !== dashboardRoutes.JOB_DENSITY);
      return routesArray[selectedDashboardIndex] === filteredDashboards[filteredDashboards.length - 1];
    }
  };

  return (
    <div className="flex">
      <div className="w-[400px] hidden-during-print">
        <DashboardMenus municipalities={municipalities} districts={districts} provinces={provinces} selectedDashboard={selectedDashboard} availableSpatialLayers={availableSpatialLayers} setSelectedPlace={setSelectedPlace} setSelectedDashboard={setSelectedDashboard} setSelectedSpatialLayer={setSelectedSpatialLayer} setSelectedSpatialValue={setSelectedSpatialValue} setAvailableSpatialLayers={setAvailableSpatialLayers} />
        <div style={{ position: "absolute", top: 125, right: 50 }}>
          {selectedDashboard !== routesArray[0] && (
            <button
              onClick={handlePrevClick}
              className="h-8 pr-3"
              style={{
                fontSize: 16,
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              disabled={selectedDashboard === routesArray[0]}
            >
              {"< PREV"}
            </button>
          )}
          {selectedDashboard !== routesArray[0] && (
            <button
              onClick={handlePrevClick}
              className="h-8"
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color:
                  (isFirstDashboardGroup(dashboardGroupings.overview) && "#f0a400") ||
                  (isFirstDashboardGroup(dashboardGroupings.economicGrowth) && "#f0a400") ||
                  (isFirstDashboardGroup(dashboardGroupings.industryDiagnostic) && "#fa7570") ||
                  (isFirstDashboardGroup(dashboardGroupings.equitableEconomies) && "#009640") ||
                  (isCurrentDashboardGroup(dashboardGroupings.overview) && "#f0a400") ||
                  (isCurrentDashboardGroup(dashboardGroupings.economicGrowth) && "#fa7570") ||
                  (isCurrentDashboardGroup(dashboardGroupings.industryDiagnostic) && "#009640") ||
                  (isCurrentDashboardGroup(dashboardGroupings.equitableEconomies) && "#84746c"),
                background: "none",
                border: "none",
                cursor: "pointer",
                textDecoration: "none",
                textUnderlineOffset: 3,
              }}
              disabled={selectedDashboard === routesArray[0]}
            >
              {dashboardLabels[routesArray[selectedDashboardIndex - 1]]}
            </button>
          )}
          {selectedDashboard !== routesArray[routesArray.length - 1] && selectedDashboard !== routesArray[0] && <span className="mx-2">|</span>}
          {selectedDashboard !== routesArray[routesArray.length - 1] && (
            <button
              onClick={handleNextClick}
              className="h-8"
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color:
                  (isLastDashboardGroup(dashboardGroupings.overview) && "#fa7570") ||
                  (isLastDashboardGroup(dashboardGroupings.economicGrowth) && "#009640") ||
                  (isLastDashboardGroup(dashboardGroupings.industryDiagnostic) && "#84746c") ||
                  (isLastDashboardGroup(dashboardGroupings.equitableEconomies) && "#84746c") ||
                  (isCurrentDashboardGroup(dashboardGroupings.overview) && "#f0a400") ||
                  (isCurrentDashboardGroup(dashboardGroupings.economicGrowth) && "#fa7570") ||
                  (isCurrentDashboardGroup(dashboardGroupings.industryDiagnostic) && "#009640") ||
                  (isCurrentDashboardGroup(dashboardGroupings.equitableEconomies) && "#84746c"),
                background: "none",
                border: "none",
                cursor: "pointer",
                textDecoration: "none",
                textUnderlineOffset: 3,
              }}
              disabled={selectedDashboard === routesArray[routesArray.length - 1]}
            >
              {dashboardLabels[routesArray[selectedDashboardIndex + 1]]}
            </button>
          )}
          {selectedDashboard !== routesArray[routesArray.length - 1] && (
            <button
              onClick={handleNextClick}
              className="h-8 pl-3"
              style={{
                fontSize: 16,
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              disabled={selectedDashboard === routesArray[routesArray.length - 1]}
            >
              {"NEXT >"}
            </button>
          )}
        </div>
        <div style={{ fontSize: "14px" }} className="w-[400px] my-5 pl-[50px] pr-[50px] font-roboto">
          {filters}
        </div>
        <div style={{ fontSize: "14px" }} className="w-[400px] my-5 pl-[50px] pr-[50px] font-roboto">
          {description}
        </div>
      </div>
      <div className="w-full overflow-y-auto resized-during-print">{content}</div>
    </div>
  );
}

export default DashboardsOverview;
