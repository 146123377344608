export const routeConstants = Object.freeze({
  DOWNLOAD_DATA_LANDING: "/download-data-landing",
  DOWNLOAD_DATA_HOME: "/download-data-home",
  DOWNLOAD_DATA_FILTER_FORM: "/download-data-filter-form",
  DOWNLOAD_DATA_HISTORY: "/download-data-history",
  TAX_DATA_LANDING: "/tax-data-landing",
  TAX_DATA_HOME: "/tax-data-home",
  TAX_DATA_FORM: "/tax-data-filter-form",
  HSRC_ADMIN_HOME: "/hsrc-admin-home",
  HSRC_ADMIN_MANAGE_CONFIG_TABLE: "/hsrc-admin-manage-config-table",
  HSRC_ADMIN_MANAGE_DATA_SETS: "/hsrc-admin-manage-data-sets",
  HSRC_ADMIN_MANAGE_USERS: "/hsrc-admin-manage-users",
  LOGIN: "/login",
  PROFILE: "/profile",
  REGISTER: "/register",
  LOGOUT: "/logout",
  VERIFY_EMAIL: "/verify-email",
  RESET_PASSWORD: "/reset-password",
  RESOURCES: "/resources",
  ABOUT_THE_PROJECT: "/about-the-project",
  MOBILE_HOLDING: "/screen-too-small",
  DASHBOARDS: "/dashboards",
  DASHBOARDS_LANDING: "/dashboards-landing",
  PDF_PREVIEW: "/pdf-preview",
  ERROR: "/error",
});

export const dashboardRoutes = Object.freeze({
  JOBS_GROWTH: "jobs-growth",
  INDUSTRY_STRUCTURE: "industry-structure",
  WAGE_DISTRIBUTION_OVERVIEW: "wage-distribution-overview",
  TOTAL_JOBS_GROWTH: "total-jobs-growth",
  NEW_JOBS: "new-jobs",
  GROWTH_BY_SECTOR: "growth-by-sector",
  CHANGE_WITHIN_METRO: "change-within-metro",
  INDUSTRY_PROFILE: "industry-profile",
  INDUSTRY_POTENTIAL: "industry-potential",
  EXPORTERS: "exporters",
  MULTI_ESTABLISHMENT_FIRMS: "multi-establishment-firms",
  JOB_DENSITY: "job-density",
  //DOMINANT_SECTORS: "dominant-sectors"
  WAGE_INEQUALITY: "wage-inequality",
  WAGE_LEVELS: "wage-levels",
  WAGE_DISTRIBUTIONS: "wage-distributions",
  JOBS_FOR_YOUTH: "jobs-for-youth",
});

export const dashboardGroupings = Object.freeze({
  overview: [dashboardRoutes.JOBS_GROWTH, dashboardRoutes.INDUSTRY_STRUCTURE, dashboardRoutes.WAGE_DISTRIBUTION_OVERVIEW],
  economicGrowth: [dashboardRoutes.TOTAL_JOBS_GROWTH, dashboardRoutes.NEW_JOBS, dashboardRoutes.GROWTH_BY_SECTOR, dashboardRoutes.CHANGE_WITHIN_METRO],
  industryDiagnostic: [dashboardRoutes.INDUSTRY_PROFILE, dashboardRoutes.INDUSTRY_POTENTIAL, dashboardRoutes.EXPORTERS, dashboardRoutes.MULTI_ESTABLISHMENT_FIRMS, dashboardRoutes.JOB_DENSITY],
  equitableEconomies: [dashboardRoutes.WAGE_INEQUALITY, dashboardRoutes.WAGE_LEVELS, dashboardRoutes.WAGE_DISTRIBUTIONS, dashboardRoutes.JOBS_FOR_YOUTH],
});

export const dashboardLabels = Object.freeze({
  [dashboardRoutes.JOBS_GROWTH]: "Jobs Growth",
  [dashboardRoutes.INDUSTRY_STRUCTURE]: "Industry Structure",
  [dashboardRoutes.WAGE_DISTRIBUTION_OVERVIEW]: "Wage Distribution",
  [dashboardRoutes.TOTAL_JOBS_GROWTH]: "Total Jobs Growth",
  [dashboardRoutes.NEW_JOBS]: "New Jobs",
  [dashboardRoutes.GROWTH_BY_SECTOR]: "Growth by Sector",
  [dashboardRoutes.CHANGE_WITHIN_METRO]: "Change within Metro",
  [dashboardRoutes.INDUSTRY_PROFILE]: "Industry Profile",
  [dashboardRoutes.INDUSTRY_POTENTIAL]: "Industry Potential",
  [dashboardRoutes.EXPORTERS]: "Exporters",
  [dashboardRoutes.MULTI_ESTABLISHMENT_FIRMS]: "Multi-Establishment Firms",
  [dashboardRoutes.JOB_DENSITY]: "Job Density",
  // [dashboardRoutes.DOMINANT_SECTORS]: "Dominant Sectors",
  [dashboardRoutes.WAGE_INEQUALITY]: "Wage Inequality",
  [dashboardRoutes.WAGE_LEVELS]: "Wage Levels",
  [dashboardRoutes.WAGE_DISTRIBUTIONS]: "Wage Distribution",
  [dashboardRoutes.JOBS_FOR_YOUTH]: "Jobs for Youth",
});

export const printInfoMessage = "All current selected filters will be applied to the print version. This may take a few minutes to load.";

export const sectorOptions = Object.freeze([
  { label: "Academia/University", value: "academia/university" },
  { label: "Civil Society", value: "civil_society" },
  { label: "Government", value: "government" },
  { label: "Media", value: "media" },
  { label: "Private Industry", value: "private_industry" },
  { label: "Other", value: "other" },
]);

export const sexOptions = Object.freeze([
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
  { label: "Unknown", value: "U" },
]);

export const exportStatusOptions = Object.freeze([
  { label: "None", value: "" },
  { label: "Non-Exporting", value: "0" },
  { label: "Exporting", value: "1" },
]);

export const importStatusOptions = Object.freeze([
  { label: "None", value: "" },
  { label: "Non-Importing", value: "0" },
  { label: "Importing", value: "1" },
]);

export const firmSizeSARSOptions = Object.freeze([
  { label: "None", value: "" },
  { label: "Dormant", value: "D" },
  { label: "Medium-to-Large", value: "ML" },
  { label: "Micro", value: "M" },
  { label: "Body Corporate/Share Block", value: "SBC" },
  { label: "Small", value: "S" },
  { label: "Unclassified", value: "U" },
]);

export const multiEstablishmentOptions = Object.freeze([
  { label: "None", value: "" },
  { label: "Single-Establishment", value: "SE" },
  { label: "Multi-Establishment (>1)", value: "ME1" },
  { label: "Multi-Establishment (>5)", value: "ME5" },
  { label: "Unclassified", value: "U" },
]);

export const immigrationOptions = Object.freeze([
  { label: "None", value: "" },
  { label: "Foreign National", value: "FN" },
  { label: "Permanent Resident", value: "PR" },
  { label: "SA Citizen", value: "ZAC" },
]);

export const wageBandOptions = Object.freeze([0.0, 400.0, 800.0, 1600.0, 3200.0, 6400.0, 12800.0, 25600.0, 51200.0, 102400.0, 204800, 409600.0, 819200.0, 1638400.0, 10000000.0]);

export const ageGroupOptions = Object.freeze([15.0, 20.0, 25.0, 30.0, 35.0, 40.0, 45.0, 50.0, 55.0, 60.0, 65.0, 120.0]);

export const firmSizeFTEOptions = Object.freeze([0.0, 5.0, 10.0, 20.0, 50.0, 100.0, 250.0, 500.0, 1000.0, 2000000.0]);

export const establishmentSizeFTEOptions = Object.freeze([0.0, 1.0, 2.0, 5.0, 10.0, 20.0, 50.0, 100.0, 250.0, 500.0, 2000000.0]);

export const youthOptions = Object.freeze([15, 25, 35, 120]);

export const monthOptions = Object.freeze([
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
]);

export const reCaptchaSiteKey = Object.freeze("6LfnCgsjAAAAADTQL2ZeplYdkZ3DJ_8Y6PYaDDwa");

export const countryOptions = Object.freeze([
  "South Africa",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahrain",
  "Bahamas",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia, Plurinational State of",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, the Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela, Bolivarian Republic of",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
]);
