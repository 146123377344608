import React from "react";
import { useParams, NavLink } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";
import { TotalJobsGrowthPdf, NewJobsPdf, IndustryStructurePdf, JobsGrowthPdf, WageDistributionOverviewPdf, GrowthBySectorPdf, IndustryPotentialPdf, IndustryProfilePdf, ExportersPdf, MultiEstablishmentFirmsPdf, JobDensityPdf, WageInequalityPdf, WageLevelsPdf, JobsForYouthPdf, WageDistributionPdf, AllDashboardsPdf, ChangeInMetroPdf } from "../../pdfs/dashboards";
import { dashboardRoutes, routeConstants } from "../../shared/constants";

const PdfPreview = () => {
  let { pdf } = useParams();

  let DocumentToRender;

  switch (pdf) {
    case "all":
      DocumentToRender = AllDashboardsPdf;
      break;
    case "totalJobsGrowthPdf":
      DocumentToRender = TotalJobsGrowthPdf;
      break;
    case "newJobsPdf":
      DocumentToRender = NewJobsPdf;
      break;
    case "industryStructurePdf":
      DocumentToRender = IndustryStructurePdf;
      break;
    case "jobsGrowthPdf":
      DocumentToRender = JobsGrowthPdf;
      break;
    case "wageDistributionsPdf":
      DocumentToRender = WageDistributionOverviewPdf;
      break;
    case "growthBySectorPdf":
      DocumentToRender = GrowthBySectorPdf;
      break;
    case "industryPotentialPdf":
      DocumentToRender = IndustryPotentialPdf;
      break;
    case "industryProfilePdf":
      DocumentToRender = IndustryProfilePdf;
      break;
    case "exportersPdf":
      DocumentToRender = ExportersPdf;
      break;
    case "multiEstablishmentFirmsPdf":
      DocumentToRender = MultiEstablishmentFirmsPdf;
      break;
    case "jobDensityPdf":
      DocumentToRender = JobDensityPdf;
      break;
    case "wageInequalityPdf":
      DocumentToRender = WageInequalityPdf;
      break;
    case "wageLevelsPdf":
      DocumentToRender = WageLevelsPdf;
      break;
    case "jobsForYouthPdf":
      DocumentToRender = JobsForYouthPdf;
      break;
    case "wageDistributionPdf":
      DocumentToRender = WageDistributionPdf;
      break;
    case "changeInMetroPdf":
      DocumentToRender = ChangeInMetroPdf;
      break;
    default:
      DocumentToRender = null;
  }

  return (
    <Box>
      {pdf === "all" && (
        <Box className="p-2">
          <NavLink to={`${routeConstants.DASHBOARDS}/${dashboardRoutes.JOBS_GROWTH}`}
            style={{
              border: "2px solid #F0A400",
              borderRadius: 50,
              padding: 8,
              paddingLeft: 14,
              paddingRight: 20,
              color: "#F0A400",
              fontWeight: 700,
              fontSize: 14,
              display: "flex",
              alignItems: "center",
              width: "fit-content",
            }}
            className="font-sans"
          >
            <ArrowBackIosIcon className="ml-2" fontSize="10" />
            Back to Dashboard
          </NavLink>
        </Box>
      )}
      <DocumentToRender key={pdf} />
    </Box>
  );
};

export default PdfPreview;
