import React, { useState, useEffect, Fragment } from "react";
import logo from "../../images/sead-logo.png";
import { NavLink } from "react-router-dom";
import { routeConstants } from "../../shared/constants";
import Media from "react-media";

const SidebarRight = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("accessToken") !== null) {
      setIsAuth(true);
    }

    if (localStorage.getItem("has_admin_access")) {
      setIsAdmin(true);
    }
  }, []);

  return (
    <>
      <nav className="flex justify-between items-center shadow-md">
        <a className="inline-block py-3 pl-4 whitespace-nowrap uppercase text-white" href="/">
          <img src={logo} alt="Logo" />
        </a>
        <Media query="(min-width: 1024px)">
          {(matches) =>
            matches ? (
              <div className="flex z-20 hidden-during-print">
                <ul className="list-none ml-auto flex items-center">
                  {isAuth === true ? (
                    <Fragment>
                      {isAdmin === true && (
                        <li className="nav-item font-sans">
                          <NavLink to={routeConstants.HSRC_ADMIN_HOME} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                            HSRC ADMINISTRATION
                          </NavLink>
                        </li>
                      )}
                      <li className="nav-item font-sans">
                        <NavLink to={routeConstants.PROFILE} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                          PROFILE
                        </NavLink>
                      </li>
                      <li className="nav-item font-sans">
                        <NavLink to={routeConstants.DOWNLOAD_DATA_HISTORY} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                          DOWNLOAD HISTORY
                        </NavLink>
                      </li>
                      <li className="nav-item font-sans">
                        <NavLink to={routeConstants.LOGOUT} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                          LOGOUT
                        </NavLink>
                      </li>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <li className="nav-item font-sans">
                        <NavLink to={window.location.pathname.includes(routeConstants.LOGIN) ? window.location.pathname : `${routeConstants.LOGIN}${window.location.pathname}`} className={({ isActive }) => (isActive ? "link-active" : "text-amber-500")}>
                          LOGIN
                        </NavLink>
                      </li>
                      <li className="nav-item font-sans">
                        <NavLink to={routeConstants.REGISTER} className={({ isActive }) => (isActive ? "link-active" : "text-amber-500")}>
                          REGISTER
                        </NavLink>
                      </li>
                    </Fragment>
                  )}
                </ul>
                <ul className="list-none ml-auto flex items-center">
                  <li className="nav-item font-sans">
                    <NavLink to={routeConstants.DASHBOARDS_LANDING} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                      DASHBOARDS
                    </NavLink>
                  </li>
                  <li className="nav-item font-sans">
                    <NavLink to={routeConstants.TAX_DATA_LANDING} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                      MAP EXPLORER
                    </NavLink>
                  </li>
                  <li className="nav-item font-sans">
                    <NavLink to={routeConstants.DOWNLOAD_DATA_LANDING} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                      DOWNLOAD DATA
                    </NavLink>
                  </li>
                  <li className="nav-item font-sans">
                    <NavLink to={routeConstants.RESOURCES} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                      RESOURCES
                    </NavLink>
                  </li>
                  <li className="nav-item font-sans">
                    <NavLink to={routeConstants.ABOUT_THE_PROJECT} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                      ABOUT THE PROJECT
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : (
              <div></div>
            )
          }
        </Media>
      </nav>
    </>
  );
};

export default SidebarRight;
