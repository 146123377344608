import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, jobDensityMapDataAtom, jobDensityMapDataGreyAtom, jobDensityMaxValueAtom, jobDensityMinValueAtom, jobDensityReportKeyAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import styles from "../../pdfs/styles/PdfStyles";
import { DashboardMap } from "../../components/Dashboards/components";

function JobDensityPdf() {
  const [maxValue] = useAtom(jobDensityMaxValueAtom);
  const [minValue] = useAtom(jobDensityMinValueAtom);
  const [mapData] = useAtom(jobDensityMapDataAtom);
  const [mapDataGrey] = useAtom(jobDensityMapDataGreyAtom);
  const [reportKey] = useAtom(jobDensityReportKeyAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 3000);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#009640' }}>Industry Diagnostic</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>JOB DENSITY</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
              </View>
              <View style={{ ...styles.section, height: 600, position: 'absolute', right: 5, top: 90 }}>
                <Image style={styles.image} src={chartImageUrl} />
              </View>
              <View style={{ marginTop: 530 }}>
                <Text style={styles.paragraph}>
                  Where are jobs concentrated within {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  The density or concentration of jobs is indicative of the form and function of cities. Cities sometimes include a number of sub-centres of economic activity with distinct specialisations. These hubs depend on sufficient investment in infrastructure to densify without becoming overloaded and congested. Spatial patterns can be explored in greater detail using the ‘map explorer’ tool.
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef} >
          <div style={{ height: 1000, aspectRatio: 1 }}>
            <DashboardMap
              key={reportKey}
              reportKey={reportKey}
              data={mapData}
              data_grey={mapDataGrey}
              metro={selectedPlace?.name}
              output={"fte"}
              viewAs={"Absolute"}
              maxValue={parseFloat(maxValue)}
              minValue={parseFloat(minValue)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default JobDensityPdf;
