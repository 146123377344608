import { useState, useEffect } from "react";
import swal from "sweetalert";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { numberFormatter } from "../../../shared/utils";
import { Box, CircularProgress } from "@mui/material";
import { useAtom } from "jotai";
import { imageResponseAtom, jobsGrowthDataAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, spatialParameterAtom, yearsRangeAtom } from "../../../shared/state/atoms";

function JobsGrowth() {
  const [data, setData] = useAtom(jobsGrowthDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [, setImageResponse] = useAtom(imageResponseAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let imageResponse;

        if (spatialParameter !== "error") {
          imageResponse = await axiosNoAuthInstance.get(`/api/pdfprint-images/?${spatialParameter}=${selectedSpatialValue}`);
        }

        if (imageResponse) {
          setImageResponse(imageResponse.data);
        }

        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-jobs-growth/?${spatialParameter}=${selectedSpatialValue}`);
        }

        if (response) {
          setData(
            response?.data?.map((growth) => ({
              ...growth,
              value: parseFloat(growth.value.toFixed(2)),
            }))
          );
          setErrorMessage(null);
        } else {
          setData([]);
          setErrorMessage(`No Data available`);
        }

        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          swal("Failed", `${error?.response?.data?.detail}`, "warning", {
            timer: 10000,
            buttons: false,
          });
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      }
    }

    if (spatialParameter && selectedSpatialValue && yearsRange?.length > 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialValue, yearsRange]);

  let min = Number.MAX_SAFE_INTEGER;
  let max = Number.MIN_SAFE_INTEGER;

  for (let i = 0; i < data?.length; i++) {
    if (data[i].value < min) {
      min = data[i].value;
    }
    if (data[i].value > max) {
      max = data[i].value;
    }
  }

  const difference = max - min;
  const decrease = difference * 0.25;
  const lowestValue = min - decrease;

  function getPointValue(data, label) {
    for (const item of data) {
      if (item.year === label) {
        return item.value;
      }
    }
    return null;
  }

  const CustomTooltip = ({ active, payload, label, graph }) => {
    const formattedNumber = getPointValue(graph, label)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (active && payload && payload?.length) {
      return (
        <div className="p-2 bg-white" key={label}>
          <p className="m-0">{label}</p>
          <p className="m-0">Total jobs: {formattedNumber}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Overview
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">Jobs Growth {yearsRange?.length > 0 ? `${yearsRange[0]} - ${yearsRange[yearsRange?.length - 1]}` : ""}</div>

        {!errorMessage ? (
          <div>
            {isLoading && (
              <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                  <CircularProgress color="success" size="3rem" />
                </Box>
              </div>
            )}
            <ResponsiveContainer width="90%" height={500}>
              <LineChart
                data={data}
                margin={{
                  top: 10,
                  right: 50,
                  left: 100,
                  bottom: 26,
                }}
                animationDuration={1000}
              >
                <XAxis dataKey="year" label={{ value: data[0]?.x_axis_label, dy: 32 }} />
                <YAxis
                  domain={[lowestValue, "auto"]}
                  tickFormatter={(value) => numberFormatter(value, 2)}
                  label={{
                    value: data[0]?.y_axis_label,
                    angle: -90,
                    dx: -50,
                  }}
                />
                <CartesianGrid strokeDasharray="3" vertical={false} />
                {!isLoading && <Line type="monotone" dataKey="value" stroke="#f0a400" strokeWidth={3} />}
                <Tooltip content={<CustomTooltip graph={data} />} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default JobsGrowth;
