import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { Map } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { scaleThreshold } from "d3-scale";
import gear from "../../images/icons/gear.svg";
import { numberFormatter, parseFromFormattedNumber } from "../../shared/utils";
import "./DisplayMap.css";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

//const DATA_URL = process.env.REACT_APP_API_URL+'/api/hex-firms/';
const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiaHlsdG9ubWlsbGFyIiwiYSI6ImNreGFiejJzZzBhamoycXBnZWY0eWN4Z2oifQ.jxfjL2uzFG02Km9kmvcVeg";

// DeckGL react component
const DisplayMap = ({
	data,
	data_grey,
	metro,
	viewAs,
	output,
	maxValue,
	minValue,
	isLoading,
	opacityLevel,
	colourToggle,
	extrudeToggle,
	displayConfig,
	setOpacityLevel,
	setColourToggle,
	setExtrudeToggle,
	setDisplayConfig,
}) => {
	const divergingScale = viewAs?.includes("Change");
	const [hoverInfo, setHoverInfo] = useState();
	const [redrawCount, setredrawCount] = useState(100);
	const [firsttime, setFirsttime] = useState(0);
	const [minMax, setMinMax] = useState([
		Math.abs(parseFloat(minValue)) < 100 ? minValue.toFixed(2) : Math.floor(parseFloat(minValue)),
		Math.abs(parseFloat(maxValue)) < 100 ? maxValue.toFixed(2) : Math.ceil(parseFloat(maxValue)),
	]);
	const [range, setRange] = useState([minValue, maxValue]);
	const [selectedScale, setSelectedScale] = useState(output === "Gini Coefficient" || divergingScale || viewAs?.includes("Percentage") ? "Linear" : "Log");
	const [initialViewState, setInitialViewState] = useState({
		longitude: 24,
		latitude: -30,
		zoom: 5.2,
		minZoom: 5.1,
		maxZoom: 8,
		pitch: 0,
		bearing: 0,
	});
	const numberSymbols = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: "k" },
		{ value: 1e6, symbol: "M" },
		{ value: 1e9, symbol: "G" },
		{ value: 1e12, symbol: "T" },
		{ value: 1e15, symbol: "P" },
		{ value: 1e18, symbol: "E" },
	];

	useEffect(() => {
		if (firsttime == 0) {
			setFirsttime(1);
			const default_zoom = 8.5;
			const default_minZoom = 8;
			const default_pitch = extrudeToggle ? 45 : 0;
			const default_bearing = 0;
			switch (metro) {
				case "Buffalo City":
					setInitialViewState({
						longitude: 28,
						latitude: -33,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Cape Town":
					setInitialViewState({
						longitude: 18.5,
						latitude: -34,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "City of Joburg":
					setInitialViewState({
						longitude: 28,
						latitude: -26,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "eThekwini":
					setInitialViewState({
						longitude: 31,
						latitude: -30,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Ekurhuleni":
					setInitialViewState({
						longitude: 28.5,
						latitude: -26,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Mangaung":
					setInitialViewState({
						longitude: 26,
						latitude: -29,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Nelson Mandela Bay":
					setInitialViewState({
						longitude: 25.5,
						latitude: -33.7,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				case "Tshwane":
					setInitialViewState({
						longitude: 28.3,
						latitude: -26,
						zoom: default_zoom,
						minZoom: default_minZoom,
						pitch: default_pitch,
						bearing: default_bearing,
					});
					break;
				default:
					setInitialViewState({
						longitude: 24,
						latitude: -30,
						zoom: 5.2,
						minZoom: 5.1,
						maxZoom: 8,
						pitch: default_pitch,
						bearing: 0,
					});
					break;
			}
		}
	}, [extrudeToggle]);

	const getMultiplier = () => {
		return 200;
	};

	const SEQUENTIAL_COLOR_RANGE = generateColor([149, 215, 63, 255], [250, 230, 34, 255], 60).concat(
		generateColor([42, 176, 125, 255], [149, 215, 63, 255], 60),
		generateColor([41, 119, 142, 255], [42, 176, 125, 255], 60),
		generateColor([64, 67, 135, 255], [41, 119, 142, 255], 60),
		generateColor([68, 3, 87, 255], [64, 67, 135, 255], 60)
	);
	const SEQUENTIAL_MONO_RANGE = generateColor([196, 225, 235], [209, 234, 242], 60).concat(
		generateColor([157, 196, 210], [196, 225, 235], 60),
		generateColor([115, 167, 185], [157, 196, 210], 60),
		generateColor([72, 136, 158], [115, 167, 185], 60),
		generateColor([34, 110, 135], [72, 136, 158], 60)
	);

	const DIVERGING_POSITIVE_COLOR_RANGE = generateColor([207, 234, 132, 255], [254, 232, 153, 255], 50).concat(
		generateColor([113, 193, 100, 255], [207, 234, 132, 255], 50),
		generateColor([1, 105, 55, 255], [113, 193, 100, 255], 50)
	);

	// const DIVERGING_POSITIVE_COLOR_RANGE = generateColor([207, 234, 132, 255] > light green, [254, 232, 153, 255] > yellow 1, 50).concat(
	// 	generateColor([113, 193, 100, 255] > olive green, [207, 234, 132, 255] > light green, 50),
	// 	generateColor([1, 105, 55, 255] > dark green, [113, 193, 100, 255] > olive green, 50)
	// );
	// yellow 1 > light green > olive green > dark green

	const DIVERGING_NEGATIVE_COLOR_RANGE = generateColor([206, 71, 60, 255], [166, 1, 38, 255], 50).concat(
		generateColor([249, 191, 127, 255], [206, 71, 60, 255], 50),
		generateColor([254, 232, 153, 255], [249, 191, 127, 255], 50)
	);

	// Original:
	// const DIVERGING_NEGATIVE_COLOR_RANGE = generateColor([246, 129, 76, 255] > Orange 1, [166, 1, 38, 255] > Red 1, 50 > Alpha).concat(
	// 	generateColor([254, 232, 153, 255] > yellow 1, [246, 129, 76, 255] > Orange 1, 50 > Alpha),
	// 	generateColor([207, 234, 132, 255] > light green, [254, 232, 153, 255] > yellow 1, 50)
	// );
	// Red 1 > Orange 1 > .... missing ... > yellow 1
	// Updated to:
	// Red 1 > Dark Orange new 2 [206, 71, 60, 255] > Light Orange new 3 [249, 191, 127, 255] > yellow 1
	// const DIVERGING_NEGATIVE_COLOR_RANGE = generateColor([206, 71, 60, 255] > Dark Orange new 2, [166, 1, 38, 255] > Red 1, 50 > Alpha).concat(
	// 	generateColor([249, 191, 127, 255] > Light Orange new 3, [206, 71, 60, 255] > Dark Orange new 2, 50 > Alpha),
	// 	generateColor([254, 232, 153, 255] > yellow 1, [249, 191, 127, 255] > Light Orange new 3, 50)
	// );

	const DIVERGING_POSITIVE_MONO_RANGE = generateColor([196, 225, 235], [209, 234, 242], 30).concat(
		generateColor([157, 196, 210], [196, 225, 235], 30),
		generateColor([115, 167, 185], [157, 196, 210], 30),
		generateColor([72, 136, 158], [115, 167, 185], 30),
		generateColor([34, 110, 135], [72, 136, 158], 30)
	);

	const DIVERGING_NEGATIVE_MONO_RANGE = generateColor([72, 136, 158], [34, 110, 135], 30).concat(
		generateColor([115, 167, 185], [72, 136, 158], 30),
		generateColor([157, 196, 210], [115, 167, 185], 30),
		generateColor([196, 225, 235], [157, 196, 210], 30),
		generateColor([209, 234, 242], [196, 225, 235], 30)
	);

	const numberDisplay = (num) => {
		if (output === "Gini Coefficient") {
			return numberFormatter(num, 2);
		} else {
			return numberFormatter(num, 4);
		}
	};

	const legendMax = () => {
		if (maxValue < 10) {
			return parseFloat(range[1].toFixed(4));
		} else {
			return Math.ceil(parseFloat(range[1]));
		}
	};

	const legendMin = () => {
		if (divergingScale && range[0] > 0) {
			return 0;
		}
		if (maxValue < 10) {
			return parseFloat(range[0].toFixed(4));
		} else {
			return Math.floor(parseFloat(range[0]));
		}
	};

	const legendAtPoint = (pointOnScale) => {
		if (divergingScale) {
			if (pointOnScale === 150 || (pointOnScale <= 150 && legendMin() >= 0)) {
				return 0;
			}
			if (selectedScale === "Linear") {
				if (pointOnScale < 150) {
					return parseFloat((legendMin() - (legendMin() / 150) * pointOnScale).toFixed(4));
				} else {
					return parseFloat((0 + (legendMax() / 150) * (pointOnScale - 150)).toFixed(4));
				}
			}

			if (selectedScale === "Log") {
				if (pointOnScale < 150) {
					let minLog = Math.log(0.0001);
					let maxLog = Math.log(Math.abs(legendMin()));
					let scale = (maxLog - minLog) / 150;

					return (parseFloat(Math.exp(minLog + scale * pointOnScale)) * -1).toFixed(4);
				} else {
					let minLog = Math.log(0.0001);
					let maxLog = Math.log(legendMax());
					let scale = (maxLog - minLog) / 150;

					return parseFloat(Math.exp(minLog + scale * (pointOnScale - 150))).toFixed(4);
				}
			}
		} else {
			if (selectedScale === "Linear") {
				if (maxValue < 10) {
					return parseFloat((legendMin() + ((legendMax() - legendMin()) / 300) * pointOnScale).toFixed(4));
				} else {
					return parseInt(legendMin() + ((legendMax() - legendMin()) / 300) * pointOnScale);
				}
			}

			if (selectedScale === "Log") {
				let minLog = Math.log(legendMin() > 0 ? legendMin() : 0.0000001);
				let maxLog = Math.log(legendMax());
				let scale = (maxLog - minLog) / 300;

				return parseFloat(Math.exp(minLog + scale * pointOnScale)).toFixed(5);
			}
		}

		return "";
	};

	const getLinearScale = () => {
		let interval = parseFloat((legendMax() - legendMin()) / 300);
		let start = legendMin();
		let vscale = [legendMin()];
		for (let i = 0; i < 300; i++) {
			start = start + interval;
			vscale.push(start);
		}
		return vscale;
	};

	const getPositiveLinearScale = () => {
		let interval = parseFloat(legendMax() / 150);
		let start = 0;
		let vscale = [0];
		for (let i = 0; i < 150; i++) {
			start = start + interval;
			vscale.push(start);
		}
		return vscale;
	};

	const getNegativeLinearScale = () => {
		let interval = parseFloat(-legendMin() / 150);
		let start = legendMin();
		let vscale = [legendMin()];
		for (let i = 0; i < 150; i++) {
			start = start + interval;
			vscale.push(start);
		}
		return vscale;
	};

	const getLogScale = () => {
		var startInterVal = 1,
			endInterval = 300,
			minLog = Math.log(legendMin() > 0 ? legendMin() : 0.0000001),
			maxLog = Math.log(legendMax()),
			scale = (maxLog - minLog) / (endInterval - startInterVal),
			vscale = [];
		for (let i = 1; i < 300; i++) {
			vscale.push(Math.exp(minLog + scale * (i - startInterVal)));
		}
		vscale.push(legendMax());
		return vscale;
	};

	const getPositiveLogScale = () => {
		var startInterVal = 1,
			endInterval = 150,
			minLog = Math.log(0.0000001),
			maxLog = Math.log(legendMax()),
			scale = (maxLog - minLog) / (endInterval - startInterVal),
			vscale = [];
		for (let i = 1; i < 150; i++) {
			vscale.push(Math.exp(minLog + scale * (i - startInterVal)));
		}
		vscale.push(legendMax());
		return vscale;
	};

	const getNegativeLogScale = () => {
		if (legendMin() >= 0) {
			return [0];
		}

		var startInterVal = 1,
			endInterval = 150,
			minLog = Math.log(0.0001),
			maxLog = Math.log(Math.abs(legendMin())),
			scale = (maxLog - minLog) / (endInterval - startInterVal),
			vscale = [];
		for (let i = 1; i < 150; i++) {
			vscale.push(Math.exp(minLog + scale * (i - startInterVal)) * -1);
		}
		vscale.push(legendMin());
		return vscale.reverse();
	};

	const getThresholdRange = () => {
		if (colourToggle) {
			return SEQUENTIAL_COLOR_RANGE;
		} else {
			return SEQUENTIAL_MONO_RANGE;
		}
	};

	const getPositiveThresholdRange = () => {
		if (colourToggle) {
			return DIVERGING_POSITIVE_COLOR_RANGE;
		} else {
			return DIVERGING_POSITIVE_MONO_RANGE;
		}
	};

	const getNegativeThresholdRange = () => {
		if (colourToggle) {
			return DIVERGING_NEGATIVE_COLOR_RANGE;
		} else {
			return DIVERGING_NEGATIVE_MONO_RANGE;
		}
	};

	const COLOR_SCALE =
		selectedScale === "Linear"
			? scaleThreshold().domain(getLinearScale()).range(getThresholdRange())
			: scaleThreshold().domain(getLogScale()).range(getThresholdRange());

	const POSITIVE_COLOR_SCALE =
		selectedScale === "Linear"
			? scaleThreshold().domain(getPositiveLinearScale()).range(getPositiveThresholdRange())
			: scaleThreshold().domain(getPositiveLogScale()).range(getPositiveThresholdRange());

	const NEGATIVE_COLOR_SCALE =
		selectedScale === "Linear"
			? scaleThreshold().domain(getNegativeLinearScale()).range(getNegativeThresholdRange())
			: scaleThreshold().domain(getNegativeLogScale()).range(getNegativeThresholdRange());

	function generateColor(colorStart, colorEnd, colorCount) {
		//Alpha blending amount
		var alpha = 0.0;
		var colors = [];

		for (let i = 0; i < colorCount; i++) {
			var c = [];
			alpha += 1.0 / colorCount;

			c[0] = colorStart[0] * alpha + (1 - alpha) * colorEnd[0];
			c[1] = colorStart[1] * alpha + (1 - alpha) * colorEnd[1];
			c[2] = colorStart[2] * alpha + (1 - alpha) * colorEnd[2];

			colors.push(c);
		}

		return colors;
	}

	let layers = [];
	if (divergingScale) {
		layers = [
			new GeoJsonLayer({
				id: "geojson-layer-positive",
				data: Object.values(data.features || {}).filter((hex) => hex.properties.value >= 0),
				opacity: opacityLevel,
				filled: true,
				extruded: extrudeToggle,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: (f) => Math.sqrt(f.properties.value) * getMultiplier(maxValue),
				getFillColor: (f) => POSITIVE_COLOR_SCALE(f.properties.value),
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
			new GeoJsonLayer({
				id: "geojson-layer-negative",
				data: Object.values(data.features || {}).filter((hex) => hex.properties.value < 0),
				opacity: opacityLevel,
				filled: true,
				extruded: extrudeToggle,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: (f) => Math.sqrt(f.properties.value) * getMultiplier(maxValue),
				getFillColor: (f) => NEGATIVE_COLOR_SCALE(f.properties.value),
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
			new GeoJsonLayer({
				id: "geojson-layer-grey",
				data: data_grey, // Changed for Grey Hexes
				opacity: opacityLevel,
				filled: true,
				extruded: extrudeToggle,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: 1, // Changed for Grey Hexes
				getFillColor: [229, 228, 226], // Changed for Grey Hexes // Platinum from https://htmlcolorcodes.com/colors/shades-of-gray/
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
		];
	} else {
		layers = [
			new GeoJsonLayer({
				id: "geojson-layer",
				data: data,
				opacity: opacityLevel,
				filled: true,
				extruded: extrudeToggle,
				//getElevation: 50000,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: (f) => Math.sqrt(f.properties.value) * getMultiplier(maxValue),
				getFillColor: (f) => COLOR_SCALE(f.properties.value),
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
			new GeoJsonLayer({
				id: "geojson-layer-grey",
				data: data_grey, // Changed for Grey Hexes
				opacity: opacityLevel,
				filled: true,
				extruded: extrudeToggle,
				material: false,
				pickable: true,
				getPosition: (d) => d.position,
				getElevation: 1, // Changed for Grey Hexes
				getFillColor: [229, 228, 226], // Changed for Grey Hexes // Platinum from https://htmlcolorcodes.com/colors/shades-of-gray/
				wireframe: true,
				getLineColor: [202, 210, 211],
				lineWidthUnits: "pixels",
				getLineWidth: 1,
				onHover: (info) => setHoverInfo(info),
			}),
		];
	}

	function getTooltip({ object }) {
		let vtext = "";
		if (object && object.properties && object.properties.label) {
			for (let i = 0; i < object.properties.label.length; i++) {
				vtext = vtext + "<div>" + object.properties.label[i] + "</div>";
			}
		}
		return (
			object && {
				html: vtext,
			}
		);
	}

	const handleExtrudeToggle = () => {
		setExtrudeToggle(!extrudeToggle);
		setFirsttime(0);
	};

	const handleColourToggle = () => {
		setColourToggle(!colourToggle);
		var newredraw = redrawCount + 1;
		setredrawCount(newredraw);
	};

	const updateOpacityLevel = (value) => {
		if (value !== opacityLevel) {
			setOpacityLevel(value);
		}
	};

	const updateMin = (newValue) => {
		setMinMax([newValue, minMax[1]]);
	};

	const updateMax = (newValue) => {
		setMinMax([minMax[0], newValue]);
	};

	const updateRange = () => {
		setRange([parseFromFormattedNumber(minMax[0].toString(), 2), parseFromFormattedNumber(minMax[1].toString(), 2)]);
		setredrawCount(redrawCount + 1);
	};

	const resetRange = () => {
		setRange([minValue, maxValue]);
		setMinMax([
			Math.abs(parseFloat(minValue)) < 100 ? minValue.toFixed(2) : Math.floor(parseFloat(minValue)),
			Math.abs(parseFloat(maxValue)) < 100 ? maxValue.toFixed(2) : Math.ceil(parseFloat(maxValue)),
		]);
		setredrawCount(redrawCount + 1);
	};

	const updateScale = (scale) => {
		setSelectedScale(scale);
		setredrawCount(redrawCount + 1);
	};

	const determineLegendGradient = () => {
		if (divergingScale) {
			return (
				<defs>
					<linearGradient id='linear-gradient' x1='0%' x2='100%' y1='0%' y2='0%'>
						<stop offset='0%' stopColor={colourToggle ? "#a50026" : "#226e87"}></stop>
						<stop offset='25%' stopColor={colourToggle ? "#f89053" : "#73a7b9"}></stop>
						<stop offset='50%' stopColor={colourToggle ? "#f9fcb7" : "#d1eaf2"}></stop>
						<stop offset='75%' stopColor={colourToggle ? "#85ca66" : "#73a7b9"}></stop>
						<stop offset='100%' stopColor={colourToggle ? "#006837" : "#226e87"}></stop>
					</linearGradient>
				</defs>
			);
		} else {
			return (
				<defs>
					<linearGradient id='linear-gradient' x1='0%' x2='100%' y1='0%' y2='0%'>
						<stop offset='0%' stopColor={colourToggle ? "#fae622" : "#d1eaf2"}></stop>
						<stop offset='25%' stopColor={colourToggle ? "#4ac26c" : "#9dc4d2"}></stop>
						<stop offset='50%' stopColor={colourToggle ? "#218c8d" : "#73a7b9"}></stop>
						<stop offset='75%' stopColor={colourToggle ? "#3d4c89" : "#48889e"}></stop>
						<stop offset='100%' stopColor={colourToggle ? "#440357" : "#226e87"}></stop>
					</linearGradient>
				</defs>
			);
		}
	};

	return (
		<div>
			<div className='absolute top-0 right-0 m-4 z-10 p-4 text-right'>Hold down CTRL to tilt</div>
			<div className='absolute right-0 bottom-0 m-6 z-10 w-[500px]'>
				<div className='grid p-4 bg-white border border-primary'>
					{displayConfig && (
						<div>
							<div className='flex flex-direction-row justify-around'>
								<label className='form-check-label inline-block text-gray-800' htmlFor='flexSwitchCheckDefault'>
									3D:
								</label>
								<div className='form-check form-switch'>
									<input
										checked={extrudeToggle}
										onChange={handleExtrudeToggle}
										className='form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm'
										type='checkbox'
										role='switch'
										id='flexSwitchCheckDefault'
									/>
								</div>
								<label className='form-check-label inline-block text-gray-800' htmlFor='flexSwitchCheckDefault'>
									Colour:
								</label>
								<div className='form-check form-switch'>
									<input
										checked={colourToggle}
										onChange={handleColourToggle}
										className='form-check-input appearance-none pl-10 w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm'
										type='checkbox'
										role='switch'
										id='flexSwitchCheckDefault'
									/>
								</div>
								<div>
									<label className='pr-2'>Opacity:</label>
									<input type='range' min='0.1' max='1' step='0.1' value={opacityLevel} onChange={(e) => updateOpacityLevel(e.target.value)} />
								</div>
							</div>
							<div className='flex justify-start mt-2'>
								<label className='form-check-label inline-block text-gray-800 mx-[10px]' htmlFor='flexSwitchCheckDefault'>
									Scale:
								</label>
								<div onChange={(e) => updateScale(e.target.value)}>
									<input readOnly type='radio' value='Linear' name='linear' className='mx-3' checked={selectedScale === "Linear"} />
									Linear
									{output !== "Gini Coefficient" && (
										<span>
											<input readOnly type='radio' value='Log' name='log' className='mx-3' checked={selectedScale === "Log"} />
											Log
										</span>
									)}
								</div>
							</div>
							<div className='flex justify-around my-2 px-1'>
								<label className='form-check-label text-gray-800 justify-content pt-1' htmlFor='flexSwitchCheckDefault'>
									Min
								</label>
								<div className='flex'>
									<Tooltip title={`Initial Min: ${minValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`} placement='top'>
										<input
											type='text'
											className='px-2 w-[135px] border border-solid border-gray-400'
											placeholder='Max'
											onChange={(e) => updateMin(e.target.value)}
											value={minMax[0]}
										/>
									</Tooltip>
								</div>
								<div className='flex'>
									<Tooltip title={`Initial Max: ${maxValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`} placement='top'>
										<input
											type='text'
											className='px-2 w-[135px] border border-solid border-gray-400'
											placeholder='Max'
											onChange={(e) => updateMax(e.target.value)}
											value={minMax[1]}
										/>
									</Tooltip>
								</div>
								<label className='form-check-label text-gray-800 justify-content pt-1' htmlFor='flexSwitchCheckDefault'>
									Max
								</label>
								<div className='flex flex-row'>
									<button className='text-green-600 underline text-sm' onClick={updateRange}>
										Set
									</button>
									<div>&nbsp;|&nbsp;</div>
									<button className='text-orange-500 underline text-sm' onClick={resetRange}>
										Reset
									</button>
								</div>
							</div>
						</div>
					)}
					<div className='flex flex-direction-row mt-1'>
						<svg style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", borderRadius: "5px", width: "430px", height: "50px" }}>
							{determineLegendGradient()}
							<rect x='30' y='0' width='350' height='20' className='rect-fill'></rect>
							<g transform='translate(20, 23)' fill='none' fontSize='10' fontFamily='sans-serif' textAnchor='middle'>
								<path stroke='currentColor' d='M10,6V0H359V6'></path>
							</g>
							<g opacity='1' transform={`translate(${30 - numberDisplay(legendMin()).toString().length * 3}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendMin())}
								</text>
							</g>
							<g opacity='1' transform='translate(115,23)'>
								<line stroke='currentColor' y2='6'></line>
							</g>
							<g opacity='1' transform={`translate(${115 - numberDisplay(legendAtPoint(75)).toString().length * 4}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendAtPoint(75))}
								</text>
							</g>
							<g opacity='1' transform='translate(200,23)'>
								<line stroke='currentColor' y2='6'></line>
							</g>
							<g opacity='1' transform={`translate(${200 - numberDisplay(legendAtPoint(150)).toString().length * 4}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendAtPoint(150))}
								</text>
							</g>
							<g opacity='1' transform='translate(290,23)'>
								<line stroke='currentColor' y2='6'></line>
							</g>
							<g opacity='1' transform={`translate(${290 - numberDisplay(legendAtPoint(225)).toString().length * 4}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendAtPoint(225))}
								</text>
							</g>
							<g opacity='1' transform={`translate(${375 - numberDisplay(legendMax()).toString().length * 2}, 0)`}>
								<text fill='currentColor' y='35' dy='0.71em'>
									{numberDisplay(legendMax())}
								</text>
							</g>
						</svg>
						<img className='ml-2 cursor-pointer' src={gear} alt='configuration options' onClick={() => setDisplayConfig(!displayConfig)} />
					</div>
				</div>
			</div>

			{isLoading && (
				<div className='absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20'>
					<Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
						<CircularProgress color='success' size='3rem' />
					</Box>
				</div>
			)}

			<div className='relative sm:h-[86vh] lg:h-[92vh] overflow-hidden'>
				<DeckGL key={redrawCount} initialViewState={initialViewState} controller={true} layers={layers} getTooltip={getTooltip}>
					<Map reuseMaps mapStyle='mapbox://styles/mapbox/light-v9' mapboxAccessToken={MAPBOX_ACCESS_TOKEN} />
				</DeckGL>
			</div>
		</div>
	);
};
export default DisplayMap;
