import React, { useState, useEffect } from "react";
import { routeConstants } from "../../shared/constants";
import { TableDisplay } from "../TableDisplay/TableDisplay";
import axiosInstance from "../axiosApi";
import swal from "sweetalert";
import moment from "moment";

const HsrcAdminUsers = () => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		async function getUsers() {
			try {
				const response = await axiosInstance.get("/user-api/user/");
				var tempdata = [];
				for (let i = 0; i < response.data.length; i++) {
					tempdata.push({
						name: response.data[i].first_name + " " + response.data[i].last_name,
						email: response.data[i].email,
						date_joined: moment(response.data[i].date_joined).format('YYYY-MM-DD HH:MM:SS'),
						id: response.data[i].id,
						is_active: (response.data[i].is_active === true ? "Yes" : "No"),
					})
				}
				setUsers(tempdata);
			} catch (error) {
				swal("Failed", "error", "error", {
					buttons: false,
					timer: 1000,
				});
			}
		}

		getUsers();
	}, []);

	const columns = React.useMemo(() => [
		{
			Header: "Name",
			accessor: "name",
		},
		{
			Header: "Email",
			accessor: "email",
		},
		{
			Header: "Options",
			accessor: "id",
			Cell: (props) => (
				<span>
					<a className='text-ccre-orange-900 hover:text-black' href={`${routeConstants.PROFILE}/${props.value}`}>
						View Profile
					</a>
					<br />
					<a className='text-ccre-orange-900 hover:text-black' href={`${routeConstants.DOWNLOAD_DATA_HISTORY}/${props.value}`}>
						Download History
					</a>
				</span>
			),
		},
		{
			Header: "Register Form Completed",
			accessor: "date_joined",
		},
		{
			Header: "Email Verified",
			accessor: "is_active",
		},
	]);

	return (
		<div className='p-10'>
			<h2 className='title'>HSRC Admin {">"} Manage Users</h2>
			<TableDisplay columns={columns} data={users} />
		</div>
	);
};

export default HsrcAdminUsers;
