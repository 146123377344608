import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { routeConstants, countryOptions, sectorOptions } from "../../shared/constants";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import validator from "validator";

const UserRegister = () => {
	const navigate = useNavigate();
	const axiosRegisterInstance = axios.create({
		baseURL: process.env.REACT_APP_API_URL + "/user-api/",
		timeout: 60000,
		headers: {
			"Content-Type": "application/json",
			accept: "application/json",
		},
	});
	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [position, setPosition] = useState();
	const [institution, setInstitution] = useState("");
	const [intended_purpose, setIntendedPurpose] = useState("");
	const [contact_number, setContactNumber] = useState("");
	const [country, setCountry] = useState("");
	const [sector, setSector] = useState("");
	const [other_sector, setOtherSector] = useState("");
	const [can_contact, setCanContact] = useState(false);
	const [can_share_outputs, setCanShareOutputs] = useState(false);
	const [can_send_updates, setCanSendUpdates] = useState(false);
	const [has_agreed_ts_and_cs, setHasAgreedTsAndCs] = useState(false);
	const [captchaResponse, setCaptchaResponse] = useState();
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

	const onVerify = useCallback((token) => {
		setCaptchaResponse(token);
	});

	useEffect(() => {
		document.querySelector(".grecaptcha-badge")?.classList.add("show");

		return () => {
			document.querySelector(".grecaptcha-badge")?.classList.remove("show");
		};
	}, []);

	useEffect(() => {
		if (navigate) {
			if (localStorage.getItem("accessToken")) {
				navigate("/");
			}
		}
	}, [navigate]);

	const canRegister = () => {
		return (
			first_name.trim() !== "" &&
			last_name.trim() !== "" &&
			email.trim() !== "" &&
			password.trim() !== "" &&
			position &&
			institution &&
			intended_purpose &&
			contact_number !== "" &&
			country !== "" &&
			sector !== "" &&
			(sector === "other" ? other_sector !== "" : true) &&
			has_agreed_ts_and_cs
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password.trim().length < 9) {
			return swal("Invalid Password", "Password length must be longer than 8", "info", {
				buttons: false,
				timer: 2500,
			});
		}
		if (!validator.isEmail(email)) {
			return swal("Invalid Email Format", "Please check your email format", "info", {
				buttons: false,
				timer: 2500,
			});
		}

		try {
			await axiosRegisterInstance.post("/user/create/", {
				first_name: first_name,
				last_name: last_name,
				email: email,
				username: first_name + last_name + Math.floor(Math.random() * 100),
				password: password,
				position: position,
				institution: institution,
				intended_purpose: intended_purpose,
				contact_number: contact_number,
				country: country,
				sector: sector,
				...(sector === "other" && { other_sector: other_sector }),
				can_contact: can_contact,
				can_share_outputs: can_share_outputs,
				can_send_updates: can_send_updates,
				has_agreed_ts_and_cs: has_agreed_ts_and_cs,
				captchaResponse,
			});

			swal("Success", "Registered", "success", {
				buttons: false,
				timer: 1000,
			}).finally(() =>
				navigate(`${routeConstants.LOGIN}/registered`, {
					isEmailVerificationPending: true,
				})
			);
		} catch (error) {
			if (error?.response?.status === 404 || error?.response?.status === 500) {
				swal("Registration", `${error?.response?.data?.detail}`, "warning", {
					timer: 10000,
					buttons: false,
				});
			} else {
				swal("Failed", "error", "error", {
					buttons: false,
					timer: 2000,
				});
			}
		}
		setRefreshReCaptcha((r) => !r);
	};

	return (
		<div className='max-w-3xl mx-auto mt-12 px-4 md:px-0'>
			<h2 className='title'>User Registration</h2>
			<p>
				Welcome! If you have not yet registered, you will need to register in order to be able to download the data files. If you are registered, you
				can{" "}
				<a href='/login' className='text-ccre-orange-900'>
					login here
				</a>
				.
			</p>
			<form noValidate onSubmit={handleSubmit}>
				<h4 className='font-bold mb-2'>Your Details</h4>
				<div className='grid grid-cols-1 md:grid-cols-3'>
					<div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='First Name'
								onChange={(e) => setFirstName(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Last Name'
								onChange={(e) => setLastName(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Contact Number'
								onChange={(e) => setContactNumber(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<select
								className='form-control placeholder-black bg-gray-200'
								name='filterCountry'
								placeholder='Country'
								onChange={(e) => setCountry(e.target.value)}
								value={country}
							>
								<option key='sector' value='' hidden>
									Country
								</option>
								{countryOptions.map((e, key) => {
									return (
										<option key={key} value={e}>
											{e}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div></div>
					<div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Job Title'
								onChange={(e) => setPosition(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Institution/Organisation'
								onChange={(e) => setInstitution(e.target.value)}
							/>
						</div>
						<div className='form-group'>
							<select
								className='form-control placeholder-black bg-gray-200'
								name='filterSpatial'
								placeholder='Sector'
								onChange={(e) => setSector(e.target.value)}
							>
								<option key='sector' value='' hidden>
									Sector
								</option>
								{sectorOptions.map((e, key) => {
									return (
										<option key={key} value={e.value}>
											{e.label}
										</option>
									);
								})}
							</select>
							{sector === "other" && (
								<input
									type='text'
									className='form-control placeholder-black bg-gray-200'
									placeholder='Enter Other Sector'
									onChange={(e) => setOtherSector(e.target.value)}
									value={other_sector}
								/>
							)}
						</div>
					</div>
				</div>
				<h4 className='font-bold mb-2'>Access</h4>
				<div className='grid grid-cols-1 md:grid-cols-3'>
					<div>
						<div className='form-group'>
							<input
								type='email'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Email'
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
					</div>
					<div></div>
					<div>
						<div className='form-group'>
							<input
								type='password'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Password'
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<h4 className='font-bold mb-2'>Intended Use</h4>
				<div className='mb-2'>Please provide a brief description of your research project (project objectives, expected outputs and timeframes).</div>
				<div className='form-group'>
					<textarea
						className='w-full px-3 mb-5 focus:outline-ccre-orange-900 focus:outline-offset-0  outline-none placeholder-black bg-gray-200'
						rows='4'
						placeholder='Enter Intended Purpose'
						onChange={(e) => setIntendedPurpose(e.target.value)}
						maxLength={995}
					/>
				</div>
				<h4 className='font-bold mb-2'>Community</h4>
				<div className='flex'>
					<div className='px-5'>
						<input onChange={(e) => setCanContact(e.target.checked)} type='checkbox' />
					</div>
					<label>Can we make contact with you in order to share information about events and seminars related to application of this data?</label>
				</div>
				<div className='flex'>
					<span className='px-5'>
						<input onChange={(e) => setCanShareOutputs(e.target.checked)} type='checkbox' />
					</span>
					<label>Would you be interested in sharing any of your outputs related to this data on our open access research portal?</label>
				</div>
				<div className='flex'>
					<span className='px-5'>
						<input onChange={(e) => setCanSendUpdates(e.target.checked)} type='checkbox' />
					</span>
					<label>Would you like to receive notices about new versions and updates to the data?</label>
				</div>
				<hr className='my-5' />
				<div className='flex'>
					<span className='px-5'>
						<input onChange={(e) => setHasAgreedTsAndCs(e.target.checked)} type='checkbox' />
					</span>
					<label>
						I agree to Terms & Conditions and Privacy Policy. <i>(Required)</i>
					</label>
				</div>
				<GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
				<div className='w-80 mx-auto my-5 px-4 md:px-0'>
					<button type='submit' className={`btn ${canRegister() ? "btn-secondary" : "btn-disabled"} w-full`} disabled={!canRegister()}>
						Register
					</button>
				</div>
			</form>
		</div>
	);
};

export default UserRegister;
