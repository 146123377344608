const numberSymbols = [
  { value: 1, symbol: "" },
  { value: 1e3, symbol: "k" },
  { value: 1e6, symbol: "M" },
  { value: 1e9, symbol: "G" },
  { value: 1e12, symbol: "T" },
  { value: 1e15, symbol: "P" },
  { value: 1e18, symbol: "E" },
];

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function numberFormatter(num, decimalPlaces) {
  if (parseFloat(num) === 0) return 0;
  if (parseFloat(parseFloat(num).toFixed(decimalPlaces)) === 0) return 0;

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = numberSymbols
    .slice()
    .reverse()
    .find(function (item) {
      return Math.abs(num) >= item.value;
    });
  return item ? Math.sign(num) * (Math.abs(num) / item.value).toFixed(1).replace(rx, "$1") + item.symbol : parseFloat(num).toFixed(decimalPlaces);
}

export function parseFromFormattedNumber(num, decimalPlaces) {
  if (/^\d+$/.test(num)) return parseFloat(parseFloat(num.replace(/\s+/g, "")).toFixed(decimalPlaces));
  if (num === "") return 0;

  var item = numberSymbols
    .slice()
    .reverse()
    .find((item) => num.charAt(num.length - 1)?.toLowerCase() === item.symbol.toLowerCase());
  return item ? parseFloat(parseFloat(num.replace(item.symbol, "").replace(/\s+/g, "")).toFixed(decimalPlaces)) * item.value : parseFloat(parseFloat(num.replace(/\s+/g, "")).toFixed(decimalPlaces));
}

export function transformKeys(object, newKey, oldKey) {
  /*
  Reference: https://stackoverflow.com/a/73824859/7496293
  Objective: To update oldKey with a newKey in a nested json at any deeper level. Recursive.
  */
  if (Array.isArray(object)) {
    // eslint-disable-next-line array-callback-return
    object.map((item) => {
      transformKeys(item, newKey, oldKey)
    })
  }
  if (typeof object === 'object' && !Array.isArray(object)) {
    Object.keys(object).forEach(key => {
      if (typeof object[key] === 'object') {
        transformKeys(object[key], newKey, oldKey)
      }
      if (key === oldKey) {
        object[newKey] = object[key]
        delete object[key]
      }
    })
  }
}

export function getLowestValue(data) {
  let min = Number.MAX_SAFE_INTEGER;
  let max = Number.MIN_SAFE_INTEGER;

  for (let i = 0; i < data.length; i++) {
    if (data[i].value < min) {
      min = data[i].value;
    }
    if (data[i].value > max) {
      max = data[i].value;
    }
  }

  const difference = max - min;
  const decrease = difference * 0.25;
  return min - decrease;
}