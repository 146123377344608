import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, newJobsDataAtom, selectedFiltersAtom, selectedSpatialLayerAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import { numberFormatter } from "../../shared/utils";
import styles from "../../pdfs/styles/PdfStyles";

function NewJobsPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(newJobsDataAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 1500);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#FB8682' }}>Economic Growth</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>NEW JOBS</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Start year: {selectedFilters?.startYear} End year: {selectedFilters?.endYear}</Text>
              </View>
              <View style={styles.section}>
                <Image style={styles.image} src={chartImageUrl} />
              </View>
              <View>
                <Text style={styles.paragraph}>
                  Which sectors created the most new jobs in {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  The sectors which create the most new jobs have a critical role to play within the local economy. Job creation can be driven by economic expansion in dynamic sectors. However total jobs growth can also be related to industry size (or dominance) because modest and even low growth will add up to more jobs in large industries. In either case, these sectors mattered most for job creation.
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef}>
          <ResponsiveContainer width={1100} height={500}>
            <BarChart
              data={data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 30,
              }}
            >
              <CartesianGrid strokeDasharray='3' vertical={false} />
              <XAxis
                interval={0}
                dataKey='activity_shortname'
                label={{
                  value: data[0]?.x_axis_label,
                  dy: 34,
                }}
              />
              <YAxis
                tickFormatter={(value) => numberFormatter(value, 2)}
                label={{
                  value: data[0]?.y_axis_label,
                  angle: -90,
                  dx: -50,
                }}
              />
              <Bar dataKey='value' fill='#e69d00' />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

export default NewJobsPdf;
