import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { printInfoMessage, routeConstants } from "../../../../shared/constants";
import { useAtom } from "jotai";
import { selectedFiltersAtom, selectedSpatialValueAtom, spatialParameterAtom, yearsRangeAtom } from "../../../../shared/state/atoms";
import swal from "sweetalert";

function TotalJobsGrowthFilters() {
  const navigate = useNavigate();
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);

  useEffect(() => {
    if (!selectedFilters.baseYear || selectedFilters.baseYear < yearsRange[0]) {
      setSelectedFilters({
        ...selectedFilters,
        baseYear: yearsRange[0],
      });
    }
  }, [selectedFilters, setSelectedFilters, yearsRange]);

  const handlePrintNavigation = async () => {
    await swal("Please Note", printInfoMessage, "info");
    window.open(`${routeConstants.PDF_PREVIEW}/totalJobsGrowthPdf`, "_blank");
  };

  useEffect(() => {
    if (spatialParameter === "province") {
      setSelectedFilters({
        ...selectedFilters,
        isProvinceDisplayed: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter]);

  useEffect(() => {
    setSelectedFilters({
      ...selectedFilters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrintAllNavigation = async () => {
    await swal("Please Note", printInfoMessage, "info");
    navigate(`${routeConstants.PDF_PREVIEW}/all`);
  };

  const handleExportData = async (exportType) => {
    window.open(`${process.env.REACT_APP_API_URL}/api-export/dashboard-total-growth-comparison/?${spatialParameter}=${selectedSpatialValue}&metro=${selectedFilters?.isMetroDisplayed ? "1" : "0"}&national=${selectedFilters?.isNationalDisplayed ? "1" : "0"}&same_province=${selectedFilters?.isProvinceDisplayed ? "1" : "0"}&similar_size=${selectedFilters?.isSimilarSizeDisplayed ? "1" : "0"}&base_year=${selectedFilters?.baseYear}&export=${exportType}`);
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div style={{ fontSize: "16px" }} className="font-bold pb-5">
          Toggles:
        </div>
        <div>Comparison Groups:</div>
        <div className="flex justify-between">
          <label className="form-check-label inline-block text-gray-800 ml-10" htmlFor="flexSwitchCheckDefault">
            National:
          </label>
          <div className="form-check form-switch">
            <input
              checked={selectedFilters?.isNationalDisplayed}
              onChange={() =>
                setSelectedFilters({
                  ...selectedFilters,
                  isNationalDisplayed: !selectedFilters?.isNationalDisplayed,
                })
              }
              className="form-check-input appearance-none w-9 rounded-full h-5 bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm checked:bg-green-600"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
          </div>
        </div>
        <div className="flex justify-between">
          <label className="form-check-label inline-block text-gray-800 ml-10" htmlFor="flexSwitchCheckDefault">
            Metros:
          </label>
          <div className="form-check form-switch">
            <input
              checked={selectedFilters?.isMetroDisplayed}
              onChange={() =>
                setSelectedFilters({
                  ...selectedFilters,
                  isMetroDisplayed: !selectedFilters?.isMetroDisplayed,
                  isSimilarSizeDisplayed: selectedFilters?.isMetroDisplayed ? selectedFilters.isSimilarSizeDisplayed : false,
                })
              }
              className="form-check-input appearance-none w-9 rounded-full h-5 bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm checked:bg-blue-500"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
          </div>
        </div>
        {/* {spatialParameter !== "province" && (
          <div className="flex justify-between">
            <label className="form-check-label inline-block text-gray-800 ml-10" htmlFor="flexSwitchCheckDefault">
              Same Province:
            </label>
            <div className="form-check form-switch">
              <input
                checked={selectedFilters?.isProvinceDisplayed}
                onChange={() =>
                  setSelectedFilters({
                    ...selectedFilters,
                    isProvinceDisplayed: !selectedFilters?.isProvinceDisplayed,
                  })
                }
                className="form-check-input appearance-none w-9 rounded-full h-5 bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm checked:bg-brown-grey"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </div>
        )} */}
        <div className="flex justify-between">
          <label className="form-check-label inline-block text-gray-800 ml-10" htmlFor="flexSwitchCheckDefault">
            {spatialParameter !== "province" ? "Similar Size:" : "Provinces"}
          </label>
          <div className="form-check form-switch">
            <input
              checked={selectedFilters?.isSimilarSizeDisplayed}
              onChange={() =>
                setSelectedFilters({
                  ...selectedFilters,
                  isSimilarSizeDisplayed: !selectedFilters?.isSimilarSizeDisplayed,
                  isMetroDisplayed: selectedFilters?.isSimilarSizeDisplayed ? selectedFilters.isMetroDisplayed : false,
                })
              }
              className="form-check-input appearance-none w-9 rounded-full h-5 bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm checked:bg-red-600"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
          </div>
        </div>
        <div style={{ borderBottom: "1px solid lightgrey" }} className="flex justify-between mb-4 pb-8">
          <label>Base Year:</label>
          <select
            className="border"
            name="filterViewas"
            value={selectedFilters?.baseYear}
            onChange={(e) =>
              setSelectedFilters({
                ...selectedFilters,
                baseYear: e.target.value,
              })
            }
          >
            {yearsRange.map((e) => {
              return (
                <option key={e} value={e}>
                  {e}
                </option>
              );
            })}
          </select>
        </div>
        <div style={{ marginTop: "30px", borderBottom: "1px solid lightgrey", flexDirection: "column" }} className="flex justify-between mb-4 pb-8">
          <div style={{ fontSize: "16px" }} className="font-bold pb-5">
            Actions:
          </div>
          <div style={{ flexDirection: "row" }}>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: "50px",
                padding: "8px",
                paddingLeft: "14px",
                color: "#F0A400",
                width: "100%",
                marginBottom: "10px",
              }}
              className="font-sans"
              onClick={handlePrintNavigation}
            >
              Print Current Dashboard
            </button>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: "50px",
                padding: "8px",
                paddingLeft: "14px",
                color: "#F0A400",
                width: "100%",
              }}
              className="font-sans"
              onClick={handlePrintAllNavigation}
            >
              Print All Dashboards
            </button>
          </div>
        </div>
        <div style={{ marginTop: "30px", borderBottom: "1px solid lightgrey", flexDirection: "column" }} className="flex justify-between mb-4 pb-8">
          <div style={{ fontSize: "16px" }} className="font-bold pb-5">
            Download Data:
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: "50px",
                padding: "8px",
                paddingLeft: "14px",
                marginRight: "5px",
                color: "#F0A400",
                width: "100%",
              }}
              className="font-sans"
              title="Export a CSV or XLSX which is the dataset used to generate the dashboard currently being viewed"
              onClick={() => handleExportData("csv")}
            >
              CSV
            </button>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: "50px",
                padding: "8px",
                paddingLeft: "14px",
                marginLeft: "5px",
                color: "#F0A400",
                width: "100%",
              }}
              className="font-sans"
              title="Export a CSV or XLSX which is the dataset used to generate the dashboard currently being viewed"
              onClick={() => handleExportData("xlsx")}
            >
              XLSX
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalJobsGrowthFilters;
