import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { printInfoMessage, routeConstants } from "../../../../shared/constants";
import { selectedFiltersAtom, selectedSpatialValueAtom, spatialParameterAtom, yearsRangeAtom } from "../../../../shared/state/atoms";
import { useAtom } from "jotai";
import swal from "sweetalert";

function JobsGrowthFilter() {
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedFilters.baseYear || selectedFilters.baseYear < yearsRange[0]) {
      setSelectedFilters({
        ...selectedFilters,
        baseYear: yearsRange[0],
      });
    }
  }, [selectedFilters, setSelectedFilters, yearsRange]);

  const handlePrintNavigation = async () => {
    await swal("Please Note", printInfoMessage, "info");
    window.open(`${routeConstants.PDF_PREVIEW}/jobsGrowthPdf`, "_blank");
  };

  const handlePrintAllNavigation = async () => {
    await swal("Please Note", printInfoMessage, "info");
    navigate(`${routeConstants.PDF_PREVIEW}/all`);
  };

  const handleExportData = async (exportType) => {
    window.open(`${process.env.REACT_APP_API_URL}/api-export/dashboard-jobs-growth/?${spatialParameter}=${selectedSpatialValue}&export=${exportType}`);
  };

  return (
    <div className="w-full">
      <div style={{ marginTop: "30px", borderBottom: "1px solid lightgrey", flexDirection: "column" }} className="flex justify-between mb-4 pb-8">
        <div style={{ fontSize: "16px" }} className="font-bold pb-5">
          Actions:
        </div>
        <div style={{ flexDirection: "row" }}>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              color: "#F0A400",
              width: "100%",
              marginBottom: "10px",
            }}
            className="font-sans"
            onClick={handlePrintNavigation}
          >
            Print Current Dashboard
          </button>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              color: "#F0A400",
              width: "100%",
            }}
            className="font-sans"
            onClick={handlePrintAllNavigation}
          >
            Print All Dashboards
          </button>
        </div>
      </div>
      <div style={{ marginTop: "30px", borderBottom: "1px solid lightgrey", flexDirection: "column" }} className="flex justify-between mb-4 pb-8">
        <div style={{ fontSize: "16px" }} className="font-bold pb-5">
          Download Data:
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              marginRight: "5px",
              color: "#F0A400",
              width: "100%",
            }}
            className="font-sans"
            title="Export a CSV or XLSX which is the dataset used to generate the dashboard currently being viewed"
            onClick={() => handleExportData("csv")}
          >
            CSV
          </button>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              marginLeft: "5px",
              color: "#F0A400",
              width: "100%",
            }}
            className="font-sans"
            title="Export a CSV or XLSX which is the dataset used to generate the dashboard currently being viewed"
            onClick={() => handleExportData("xlsx")}
          >
            XLSX
          </button>
        </div>
      </div>
    </div>
  );
}

export default JobsGrowthFilter;
