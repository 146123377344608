import { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import swal from "sweetalert";
import axiosNoAuthInstance from "../../axiosApiNoAuth";
import hexData from "../../../data/Metros_hex.geo.json";
import DashboardMap from "./DashboardMap";
import { Box, CircularProgress } from "@mui/material";
import { changeInMetroMapFirmsDataAtom, changeInMetroMapFirmsDataGreyAtom, changeInMetroMapJobsDataAtom, changeInMetroMapJobsDataGreyAtom, changeInMetroMaxFirmsValueAtom, changeInMetroMaxJobsValueAtom, changeInMetroMinFirmsValueAtom, changeInMetroMinJobsValueAtom, changeInMetroReportKeyAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialValueAtom, spatialParameterAtom, yearsRangeAtom } from "../../../shared/state/atoms";
import { useAtom } from "jotai";

function ChangeInMetro() {
  const [maxJobsValue, setMaxJobsValue] = useAtom(changeInMetroMaxJobsValueAtom);
  const [minJobsValue, setMinJobsValue] = useAtom(changeInMetroMinJobsValueAtom);
  const [maxFirmsValue, setMaxFirmsValue] = useAtom(changeInMetroMaxFirmsValueAtom);
  const [minFirmsValue, setMinFirmsValue] = useAtom(changeInMetroMinFirmsValueAtom);
  const [mapJobsData, setMapJobsData] = useAtom(changeInMetroMapJobsDataAtom);
  const [mapJobsDataGrey, setMapJobsDataGrey] = useAtom(changeInMetroMapJobsDataGreyAtom);
  const [mapFirmsData, setMapFirmsData] = useAtom(changeInMetroMapFirmsDataAtom);
  const [mapFirmsDataGrey, setMapFirmsDataGrey] = useAtom(changeInMetroMapFirmsDataGreyAtom);
  const [reportKey, setReportKey] = useAtom(changeInMetroReportKeyAtom);

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);

  const jobsOutput = "fte";
  const firmsOutput = "firms";

  useEffect(() => {
    function getMapData(output) {
      setIsLoading(true);
      var vfield, vget_field, vpoly_field, poly_data;
      poly_data = hexData;

      var vpoly = cloneDeep(poly_data);
      var vpoly_grey = cloneDeep(poly_data);
      vfield = "display";
      vget_field = "hex7";
      vpoly_field = "hex7";
      var vmax = 0;
      var vmin = Number.MAX_SAFE_INTEGER;

      vpoly.features = [];
      vpoly_grey.features = [];

      axiosNoAuthInstance
        .get(`/api/map-explorer/?taxdata_modelname=hex7_${output}&metro=${selectedPlace?.name}&year=${selectedFilters.startYear}&viewas=Absolute+Change&secondyear=${selectedFilters.endYear}&spatial=hex7&output=${output}&${spatialParameter}=${selectedSpatialValue}`)
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < poly_data.features.length; j++) {
              if (poly_data.features[j].properties[vpoly_field] === response.data[i][vget_field]) {
                if (typeof response.data[i][vfield] != "number") {
                  let vfeature_grey = cloneDeep(poly_data.features[j]);
                  vfeature_grey.properties.value = "0";
                  vfeature_grey.properties.label = [output];
                  vfeature_grey.properties.label.push(response.data[i][vfield]);
                  vpoly_grey.features.push(vfeature_grey);
                } else {
                  let vfeature = cloneDeep(poly_data.features[j]);
                  vfeature.properties.value = response.data[i][vfield];
                  if (parseFloat(vfeature.properties.value) > parseFloat(vmax)) {
                    vmax = parseFloat(vfeature.properties.value);
                  }
                  if (parseFloat(vfeature.properties.value) < parseFloat(vmin)) {
                    vmin = parseFloat(vfeature.properties.value);
                  }
                  vfeature.properties.label = [output];
                  vfeature.properties.label.push(parseFloat(response.data[i][vfield].toFixed(4)));

                  vpoly.features.push(vfeature);
                }
                continue;
              }
            }
          }

          if (output === firmsOutput) {
            setMaxFirmsValue(vmax);
            setMinFirmsValue(vmin === Number.MAX_SAFE_INTEGER ? 0 : vmin);
            setMapFirmsData(vpoly);
            setMapFirmsDataGrey(vpoly_grey);
          }

          if (output === jobsOutput) {
            setMaxJobsValue(vmax);
            setMinJobsValue(vmin === Number.MAX_SAFE_INTEGER ? 0 : vmin);
            setMapJobsData(vpoly);
            setMapJobsDataGrey(vpoly_grey);
          }

          setIsLoading(false);
          setReportKey(reportKey + 1);
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            swal("Failed", `${error?.response?.data?.detail}`, "warning", {
              timer: 10000,
              buttons: false,
            });
          } else if (error?.response == null) {
            setIsLoading(false);
            setErrorMessage(`No Data available for ${selectedPlace?.name}`);
          } else {
            swal("Failed", `${error}`, "error", {
              timer: 4000,
              buttons: false,
            });
          }
        });
    }

    if (spatialParameter && selectedSpatialValue && yearsRange.length > 0 && selectedFilters.startYear && selectedFilters.endYear) {
      getMapData(firmsOutput);
      getMapData(jobsOutput);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialValue, yearsRange, selectedFilters]);

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">{selectedPlace?.name} Economic Growth</div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">Change within Metro</div>

        {isLoading && (
          <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
            <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
              <CircularProgress color="success" size="3rem" />
            </Box>
          </div>
        )}
        {!errorMessage ? (
          <div className="flex">
            <div className="w-[45%] ml-10">
              <div className="font-bold flex justify-center mb-5">Change in Jobs {selectedFilters ? `${selectedFilters.startYear} - ${selectedFilters.endYear}` : ""}</div>
              <DashboardMap key={`Jobs ${reportKey}`} reportKey={`Jobs ${reportKey}`} data={mapJobsData} data_grey={mapJobsDataGrey} metro={selectedPlace?.name} output={jobsOutput} viewAs={"Absolute Change"} maxValue={parseFloat(maxJobsValue)} minValue={parseFloat(minJobsValue)} />
            </div>
            <div className="w-[45%] ml-10">
              <div className="font-bold flex justify-center mb-5">Change in Firms {selectedFilters ? `${selectedFilters.startYear} - ${selectedFilters.endYear}` : ""}</div>
              <DashboardMap key={`Firms ${reportKey}`} reportKey={`Firms ${reportKey}`} data={mapFirmsData} data_grey={mapFirmsDataGrey} metro={selectedPlace?.name} output={firmsOutput} viewAs={"Absolute Change"} maxValue={parseFloat(maxFirmsValue)} minValue={parseFloat(minFirmsValue)} />
            </div>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default ChangeInMetro;
