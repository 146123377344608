import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { printInfoMessage, routeConstants } from "../../../../shared/constants";
import { industriesDataAtom, selectedFiltersAtom, selectedSpatialValueAtom, sic7Atom, sic7StringAtom, spatialParameterAtom, wageDistributionFilterAtom, yearsRangeAtom } from "../../../../shared/state/atoms";
import { useAtom } from "jotai";
import swal from "sweetalert";

function WageDistributionFilter() {
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersAtom);
  const [, setWageDistributionFilterDisplay] = useAtom(wageDistributionFilterAtom);
  const [sic7, setSic7] = useAtom(sic7Atom);
  const [sic7String, setSic7String] = useAtom(sic7StringAtom);
  const [industries] = useAtom(industriesDataAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedFilters.singleYear || selectedFilters.singleYear < yearsRange[0]) {
      setSelectedFilters({
        ...selectedFilters,
        singleYear: yearsRange[yearsRange.length - 1],
      });
    }
  }, [selectedFilters, setSelectedFilters, yearsRange]);

  useEffect(() => {
    setWageDistributionFilterDisplay(industries.find((industry) => industry.code === Number(sic7))?.activity);
  }, []);

  function handleIndustryChange(event) {
    setSic7(event.target.value);

    const selectedIndustry = industries.find((industry) => industry.code === Number(event.target.value));

    setWageDistributionFilterDisplay(selectedIndustry?.activity);
    if (event.target.value === "All") {
      setSic7String("");
    } else {
      setSic7String(`&sic7_1d=${event.target.value}`);
    }
  }

  const handlePrintNavigation = async () => {
    await swal("Please Note", printInfoMessage, "info");
    window.open(`${routeConstants.PDF_PREVIEW}/wageDistributionPdf`, "_blank");
  };

  const handlePrintAllNavigation = async () => {
    await swal("Please Note", printInfoMessage, "info");
    navigate(`${routeConstants.PDF_PREVIEW}/all`);
  };

  const handleExportData = async (exportType) => {
    window.open(`${process.env.REACT_APP_API_URL}/api-export/dashboard-wage-distribution-industry/?${spatialParameter}=${selectedSpatialValue}${sic7String}&year=${selectedFilters?.singleYear}&export=${exportType}`);
  };

  return (
    <div className="w-full">
      <div>
        <div style={{ fontSize: "16px" }} className="font-bold pb-5">
          Toggles:
        </div>
        <div className="flex justify-between mb-4">
          <label>Industry:</label>
          <select className="w-2/3 border" name="industry" id="industry" placeholder="Select an industry" value={sic7} onChange={(event) => handleIndustryChange(event)}>
            <option value="All">All Industries</option>
            {industries.map((industry) => (
              <option key={industry.code} value={industry.code}>
                {industry.code} {industry.activity}
              </option>
            ))}
          </select>
        </div>
        <div style={{ borderBottom: "1px solid lightgrey" }} className="flex justify-between mb-4 pb-8">
          <label>Year:</label>
          <select
            className="border"
            name="filterViewas"
            value={selectedFilters?.singleYear}
            onChange={(e) =>
              setSelectedFilters({
                ...selectedFilters,
                singleYear: e.target.value,
              })
            }
          >
            {yearsRange.map((e) => {
              return (
                <option key={e} value={e}>
                  {e}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div style={{ marginTop: "30px", borderBottom: "1px solid lightgrey", flexDirection: "column" }} className="flex justify-between mb-4 pb-8">
        <div style={{ fontSize: "16px" }} className="font-bold pb-5">
          Actions:
        </div>
        <div style={{ flexDirection: "row" }}>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              color: "#F0A400",
              width: "100%",
              marginBottom: "10px",
            }}
            className="font-sans"
            onClick={handlePrintNavigation}
          >
            Print Current Dashboard
          </button>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              color: "#F0A400",
              width: "100%",
            }}
            className="font-sans"
            onClick={handlePrintAllNavigation}
          >
            Print All Dashboards
          </button>
        </div>
      </div>
      <div style={{ marginTop: "30px", borderBottom: "1px solid lightgrey", flexDirection: "column" }} className="flex justify-between mb-4 pb-8">
        <div style={{ fontSize: "16px" }} className="font-bold pb-5">
          Download Data:
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              marginRight: "5px",
              color: "#F0A400",
              width: "100%",
            }}
            className="font-sans"
            title="Export a CSV or XLSX which is the dataset used to generate the dashboard currently being viewed"
            onClick={() => handleExportData("csv")}
          >
            CSV
          </button>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: "50px",
              padding: "8px",
              paddingLeft: "14px",
              marginLeft: "5px",
              color: "#F0A400",
              width: "100%",
            }}
            className="font-sans"
            title="Export a CSV or XLSX which is the dataset used to generate the dashboard currently being viewed"
            onClick={() => handleExportData("xlsx")}
          >
            XLSX
          </button>
        </div>
      </div>
    </div>
  );
}

export default WageDistributionFilter;
