import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../axiosApi";
import swal from "sweetalert";
import { countryOptions, sectorOptions } from "../../shared/constants";

const UserProfile = () => {
	let { userId } = useParams();
	const [user, setUser] = useState({});
	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [position, setPosition] = useState();
	const [institution, setInstitution] = useState("");
	const [intended_purpose, setIntendedPurpose] = useState("");
	const [contact_number, setContactNumber] = useState("");
	const [country, setCountry] = useState("");
	const [sector, setSector] = useState("");
	const [other_sector, setOtherSector] = useState("");
	const [can_contact, setCanContact] = useState(false);
	const [can_share_outputs, setCanShareOutputs] = useState(false);
	const [can_send_updates, setCanSendUpdates] = useState(false);
	const [has_agreed_ts_and_cs, setHasAgreedTsAndCs] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const profileId = userId ?? JSON.parse(localStorage.getItem("user"))?.id;

	useEffect(() => {
		if (profileId) {
			getProfile();
		} else {
			swal("Failed", "No user found", "error", {
				buttons: false,
				timer: 2500,
			});
		}

		async function getProfile() {
			try {
				const response = await axiosInstance.get(`/user-api/user/${profileId}/`);

				if ("is_active" in response.data) {
					setUser(response.data);
					setFirstName(response.data.first_name);
					setLastName(response.data.last_name);
					setPosition(response.data.position);
					setInstitution(response.data.institution);
					setIntendedPurpose(response.data.intended_purpose);
					setContactNumber(response.data.contact_number);
					setCountry(response.data.country);
					setSector(response.data.sector);
					setOtherSector(response.data.other_sector);
					setCanContact(response.data.can_contact);
					setCanShareOutputs(response.data.can_share_outputs);
					setCanSendUpdates(response.data.can_send_updates);
					setHasAgreedTsAndCs(response.data.has_agreed_ts_and_cs);
				}
			} catch (error) {
				swal("Failed", "error", "error", {
					buttons: false,
					timer: 2000,
				});
			}
		}
	}, [profileId]);

	const canUpdate = () => {
		return (
			(!userId || userId.toString() === JSON.parse(localStorage.getItem("user"))?.id?.toString()) &&
			first_name.trim() &&
			last_name.trim() &&
			position &&
			institution &&
			intended_purpose &&
			contact_number &&
			country &&
			sector &&
			(sector === "other" ? other_sector : true) &&
			has_agreed_ts_and_cs
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setIsUpdating(true);
			await axiosInstance.put(`user-api/user/update/${profileId}/`, {
				...user,
				first_name: first_name,
				last_name: last_name,
				position: position,
				institution: institution,
				intended_purpose: intended_purpose,
				contact_number: contact_number,
				country: country,
				sector: sector,
				...(sector === "other" && { other_sector: other_sector }),
				can_contact: can_contact,
				can_share_outputs: can_share_outputs,
				can_send_updates: can_send_updates,
				has_agreed_ts_and_cs: has_agreed_ts_and_cs,
			});
			setIsUpdating(false);
			swal("Success", "Updated", "success", {
				buttons: false,
				timer: 2000,
			});
		} catch (error) {
			setIsUpdating(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 2000,
			});
		}
	};

	return (
		<div className='max-w-3xl mx-auto mt-12 px-4 md:px-0'>
			<h2 className='title'>Profile</h2>
			<form noValidate onSubmit={handleSubmit}>
				<h4 className='font-bold mb-2'>Your Details</h4>
				<div className='grid grid-cols-1 md:grid-cols-3'>
					<div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='First Name'
								onChange={(e) => setFirstName(e.target.value)}
								value={first_name}
							/>
						</div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Last Name'
								onChange={(e) => setLastName(e.target.value)}
								value={last_name}
							/>
						</div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Contact Number'
								onChange={(e) => setContactNumber(e.target.value)}
								value={contact_number}
							/>
						</div>
						<div className='form-group'>
							<select
								className='form-control placeholder-black bg-gray-200'
								name='filterCountry'
								placeholder='Country'
								onChange={(e) => setCountry(e.target.value)}
								value={country}
							>
								<option key='sector' value='' hidden>
									Country
								</option>
								{countryOptions.map((e, key) => {
									return (
										<option key={key} value={e}>
											{e}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div></div>
					<div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Position'
								onChange={(e) => setPosition(e.target.value)}
								value={position}
							/>
						</div>
						<div className='form-group'>
							<input
								type='text'
								className='form-control placeholder-black bg-gray-200'
								placeholder='Institution'
								onChange={(e) => setInstitution(e.target.value)}
								value={institution}
							/>
						</div>
						<div className='form-group'>
							<select
								className='form-control placeholder-black bg-gray-200'
								name='filterSector'
								placeholder='Sector'
								onChange={(e) => setSector(e.target.value)}
								value={sector}
							>
								<option key='sector' value='' hidden>
									Sector
								</option>
								{sectorOptions.map((e, key) => {
									return (
										<option key={key} value={e.value}>
											{e.label}
										</option>
									);
								})}
							</select>
							{sector === "other" && (
								<input
									type='text'
									className='form-control placeholder-black bg-gray-200'
									placeholder='Enter Other Sector'
									onChange={(e) => setOtherSector(e.target.value)}
									value={other_sector}
								/>
							)}
						</div>
					</div>
				</div>
				<h4 className='font-bold mb-2'>Intended Use</h4>
				<div className='mb-2'>Please provide a brief description of your research project (project objectives, expected outputs and timeframes).</div>
				<div className='form-group'>
					<textarea
						className='w-full px-3 mb-5 focus:outline-ccre-orange-900 focus:outline-offset-0  outline-none placeholder-black bg-gray-200'
						rows='4'
						placeholder='Enter Intended Purpose'
						onChange={(e) => setIntendedPurpose(e.target.value)}
						maxLength={1000}
						value={intended_purpose}
					/>
				</div>
				<h4 className='font-bold mb-2'>Community</h4>
				<div className='flex'>
					<div className='px-5'>
						<input onChange={(e) => setCanContact(e.target.checked)} type='checkbox' checked={can_contact} />
					</div>
					<label>Can we make contact with you in order to share information about events and seminars related to application of this data?</label>
				</div>
				<div className='flex'>
					<span className='px-5'>
						<input onChange={(e) => setCanShareOutputs(e.target.checked)} type='checkbox' checked={can_share_outputs} />
					</span>
					<label>Would you be interested in sharing any of your outputs related to this data on our open access research portal?</label>
				</div>
				<div className='flex'>
					<span className='px-5'>
						<input onChange={(e) => setCanSendUpdates(e.target.checked)} type='checkbox' checked={can_send_updates} />
					</span>
					<label>Would you like to receive notices about new versions and updates to the data?</label>
				</div>
				<div className='w-80 mx-auto my-5 px-4 md:px-0'>
					<button type='submit' className={`btn ${canUpdate() ? "btn-secondary" : "btn-disabled"} w-full`} disabled={!canUpdate()}>
						{isUpdating ? <span className='spinner-border animate-spin inline-block w-5 h-5 border-4 rounded-full'></span> : "Update"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default UserProfile;
