import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import axiosNoAuthInstance from "../../axiosApiNoAuth";
import swal from "sweetalert";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useWindowDimensions } from "../../../shared/hooks";
import { Box, CircularProgress, Grid } from "@mui/material";
import { growthBySectorMainDataAtom, growthBySectorOtherDataAtom, growthBySectorDataAtom, selectedPlaceAtom, selectedSpatialValueAtom, spatialParameterAtom, selectedSpatialLayerAtom, growthBySectorSic7Atom, industriesDataAtom } from "../../../shared/state/atoms";

function GrowthBySector() {
  const { height, width } = useWindowDimensions();
  const [firstGraphData, setFirstGraphData] = useAtom(growthBySectorMainDataAtom);
  const [otherData, setOtherData] = useAtom(growthBySectorOtherDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [sic7] = useAtom(growthBySectorSic7Atom);
  const [, setIndustries] = useAtom(industriesDataAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useAtom(growthBySectorDataAtom);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-growth-by-sector/?${spatialParameter}=${selectedSpatialValue}&sic7_1d=${sic7}`);
        }

        const mappedResponse = response?.data.map((growth) => ({ ...growth }));

        if (response) setData(mappedResponse);

        const reducedData = mappedResponse.reduce((acc, curr) => {
          const graph = curr.graph;
          const graph_label = curr.graph_label;
          const y_axis_label = curr.y_axis_label;
          const lineDetails = curr.line_details[0];

          Object.values(lineDetails).forEach((lines) => {
            lines.forEach((line) => {
              acc.push({
                graph,
                graph_label,
                y_axis_label,
                line: {
                  line_type: line.line_type,
                  place_id: line.place_id,
                  industry_level: line.industry_level,
                  label: line.label,
                  data: line.data.map((d) => ({
                    year: d.year,
                    value: d.value,
                  })),
                },
              });
            });
          });

          return acc;
        }, []);

        const groupedData = Object.values(
          reducedData.reduce((acc, item) => {
            acc[item.graph] = acc[item.graph] || [];
            acc[item.graph].push(item);
            return acc;
          }, {})
        );

        let finalData = [];

        // eslint-disable-next-line array-callback-return
        groupedData.map((item) => {
          let result = {};

          item.forEach(({ graph_label, y_axis_label, line: { label, data } }) => {
            result.graph_label = graph_label;
            result.data = result.data || [];
            result.y_axis_label = y_axis_label;

            data.forEach(({ year, value }) => {
              let yearData = result.data.find((d) => d.year === year);
              if (!yearData) {
                yearData = { year };
                result.data.push(yearData);
              }

              yearData[label] = value;
            });
          });

          finalData = [...finalData, result];
        });

        setFirstGraphData(finalData[0]);
        setOtherData(finalData.slice(1));
        setIsLoading(false);
        setErrorMessage(null);
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      }
    }

    if (selectedPlace) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpatialValue, spatialParameter, selectedPlace, sic7]);

  const CustomTooltip = ({ active, payload, label }) => {
    payload?.sort((a, b) => b.value - a.value);

    if (active && payload && payload?.length) {
      return (
        <div className="p-2 bg-white">
          <p className="m-0">{label}</p>
          {payload.map((munic) => (
            <p className="m-0" style={{ color: munic.color }}>
              {munic.name} : {munic.value}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await axiosNoAuthInstance.get(`/api/sic-codes/?nextlevel=1`);

        setIndustries(
          response?.data
            .map((industry) => ({
              ...industry,
            }))
            .sort((a, b) => b.code - a.code)
        );
      } catch (e) {
        console.log(e);
      }
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Economic Growth
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">Growth By Sector</div>
        {!errorMessage ? (
          <div>
            <div style={{ height: `${height * 0.7}px` }} className="overflow-y-auto remove-overflow-during-print">
              {isLoading && (
                <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                  <Box
                    sx={{
                      textAlign: "center",
                      position: "relative",
                      top: "40%",
                    }}
                  >
                    <CircularProgress color="success" size="3rem" />
                  </Box>
                </div>
              )}
              <Grid container rowSpacing={0} columnSpacing={0}>
                <Grid item xs={12}>
                  <div className="px-10 font-bold flex justify-center">{firstGraphData?.graph_label}</div>
                  <LineChart
                    data={firstGraphData?.data}
                    height={height * 0.45}
                    width={width * 0.7}
                    margin={{
                      top: 10,
                      right: 50,
                      left: 100,
                      bottom: 10,
                    }}
                    animationDuration={1000}
                  >
                    <XAxis dataKey="year" />
                    <YAxis
                      domain={["auto", "auto"]}
                      label={{
                        value: data[0]?.y_axis_label,
                        angle: -90,
                        dx: -50,
                      }}
                    />
                    <CartesianGrid strokeDasharray="3" vertical={false} />
                    {!isLoading && <Line type="monotone" dataKey={`${selectedPlace?.name}`} stroke="#f0a400" strokeWidth={3} />}
                    {!isLoading && <Line type="monotone" dataKey="South Africa" stroke="#009640" strokeWidth={3} />}
                    <Tooltip content={<CustomTooltip />} />
                  </LineChart>
                </Grid>
                {otherData.map((chart) => (
                  <Grid item xs={4} key={chart.graph_label}>
                    <div className="px-10 font-bold flex justify-center items-end " style={{ height: 90 }}>
                      {chart?.graph_label?.length >= 62 ? chart?.graph_label.substring(0, 62) + " ..." : chart?.graph_label}
                    </div>
                    <LineChart
                      data={chart?.data}
                      height={height * 0.4}
                      width={(width - 400) * 0.3}
                      margin={{
                        top: 10,
                        right: 10,
                        left: 15,
                        bottom: 10,
                      }}
                    >
                      <XAxis dataKey="year" />
                      <YAxis
                        domain={["auto", "auto"]}
                        label={{
                          value: data[0]?.y_axis_label,
                          angle: -90,
                          dx: -17,
                          fontSize: 12,
                        }}
                      />
                      <CartesianGrid strokeDasharray="3" vertical={false} />
                      {!isLoading && <Line type="monotone" dataKey={`${selectedPlace?.name}`} stroke="#f0a400" />}
                      {!isLoading && <Line type="monotone" dataKey="South Africa" stroke="#009640" />}
                      <Tooltip content={<CustomTooltip />} />
                    </LineChart>
                  </Grid>
                ))}
              </Grid>
            </div>
            <div className="pt-2 flex justify-center hidden-during-print">
              <KeyboardDoubleArrowDownIcon />
            </div>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default GrowthBySector;
