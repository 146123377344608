import React, { useState, useEffect } from "react";
import background from "../../images/background-optimised.jpg";
import Footer from "../../shared/components/Footer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { dashboardRoutes, routeConstants } from "../../shared/constants";
import { NavLink } from "react-router-dom";
import axiosNoAuthInstance from "../axiosApiNoAuth";
import { useAtom } from "jotai";
import { displayMunicNameAtom, municipalitiesAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom } from "../../shared/state/atoms";
import { transformKeys } from "../../shared/utils";

function DashboardLandingPage() {
  const [municipalities, setMunicipalities] = useAtom(municipalitiesAtom);
  const [, setDisplayMunicName] = useAtom(displayMunicNameAtom);
  const [availableSpatialLayers, setAvailableSpatialLayers] = useState();
  const [selectedSpatialLayer, setSelectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [selectedSpatialValue, setSelectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  // Each of the below neede for id, name, and sometimes municipality
  const [selectedPlace, setSelectedPlace] = useAtom(selectedPlaceAtom);

  // Municipalities

  useEffect(() => {
    async function loadData() {
      try {
        const response = await axiosNoAuthInstance.get("/api/municipality-list/");
        transformKeys(response.data, "name", "municname");
        response.data.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
        setMunicipalities(response.data);
      } catch (e) {
        console.log(e);
      }
    }
    loadData();
  }, []);

  const handleSelectMunicipality = (option) => {
    const municipality = municipalities.find((municipality) => municipality.cat_b === option);
    setSelectedPlace(municipality); // Runs concurrently to get at municipal info
    setDisplayMunicName(municipality.name);
    setSelectedSpatialValue(option); // Always set id type value only
  };

  const metros = municipalities.filter((obj) => obj.category === "A");

  const localMunicipalities = municipalities.filter((obj) => obj.category !== "A");

  // Spatial Layers

  useEffect(() => {
    if (!availableSpatialLayers) {
      const spatialLayers = [
        { id: "municipality", name: "Municipality" },
        { id: "district", name: "District" },
        { id: "province", name: "Province" },
      ];
      setAvailableSpatialLayers(spatialLayers);
    }
  }, [availableSpatialLayers]);

  const handleSelectSpatialLayer = (option) => {
    const spatialLayer = availableSpatialLayers.find((spatialLayer) => spatialLayer.id === option);
    setSelectedSpatialLayer(spatialLayer);
  };

  // Districts

  const districts_dup = municipalities.map((element) => {
    // Only district info in duplicate, renamed keys
    return {
      id: element.district,
      name: element.district_n,
      category: element.category,
      province: element.province,
      province_n: element.province_n,
    };
  });

  const districts = districts_dup.filter(
    // De-duplicated district info
    ({ id, name }, index) => {
      return districts_dup.findIndex((item) => item.id === id && item.name === name) === index;
    }
  );

  const handleSelectDistrict = (option) => {
    const district = districts.find((district) => district.id === option);
    setSelectedPlace(district);
    setDisplayMunicName(district.name);
    setSelectedSpatialValue(option);
  };

  // Provinces
  const provinces_dup = municipalities.map((element) => {
    // Only province info in duplicate, renamed keys
    return {
      id: element.province,
      name: element.province_n,
      category: element.category,
      province: element.province,
    };
  });

  const provinces = provinces_dup.filter(
    // De-duplicated province info
    ({ id, name }, index) => {
      return provinces_dup.findIndex((item) => item.id === id && item.name === name) === index;
    }
  );

  const groupedDistricts = [];

  // Loop through the data and group objects based on the province
  districts?.forEach((item) => {
    const { province, province_n } = item;
    const existingProvinceIndex = groupedDistricts.findIndex((group) => group.province === province);
    if (existingProvinceIndex === -1) {
      groupedDistricts.push({ province, province_n, districts: [item] });
    } else {
      groupedDistricts[existingProvinceIndex].districts.push(item);
    }
  });

  const handleSelectProvince = (option) => {
    const province = provinces.find((province) => province.id === option);
    setSelectedPlace(province);
    setDisplayMunicName(province.name);
    setSelectedSpatialValue(option);
  };

  return (
    <div className="relative">
      <div
        style={{
          height: "calc(100vh - 80.88px)",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-between"
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="flex flex-col items-center z-10 p-5">
          <h1 className="text-7xl text-white p-8">DASHBOARDS</h1>
          <div className="bg-white bg-opacity-20 border rounded p-6 border-0 flex flex-col items-center" style={{ width: "70%", backgroundColor: "#282F3980" }}>
            <h2
              style={{
                fontSize: 20,
                color: "white",
                width: "80%",
                textAlign: "center",
              }}
              className="font-sans"
            >
              The dashboards are a series of charts organised by theme which help you to discover what is distinctive about your local municipality, district and province. Take advantage of a range of interactive toggles to explore the economic profile and performance of local economies based on tax data
            </h2>
            <div className="m-10">
              {/* <h3 style={{
                  textAlign: "center",
                  paddingBottom: "5px"
                }} 
                className="font-sans text-2xl text-white">
                Select Municipality
              </h3> */}
              <div className="grid border-0 border-gray-400">
                <div className="form-group bg-gray-200 mb-10">
                  {/* Spatial Layer Select Process*/}
                  <select className="form-control hsrc-form-select border" name="spatial" value={selectedSpatialLayer?.id} onChange={(e) => handleSelectSpatialLayer(e.target.value)}>
                    <option value="" hidden>
                      Select geography...
                    </option>
                    <option value="spatial-type" disabled className="font-bold text-lg text-black">
                      Select Geography
                    </option>
                    {availableSpatialLayers?.map((e) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group bg-gray-200">
                  {/* Spatial Layer Select Process*/}

                  {/* Municipal Select Process*/}
                  {selectedSpatialLayer?.id === "municipality" && (
                    <select className="form-control hsrc-form-select border m-10" name="municipality" value={selectedPlace?.cat_b} onChange={(e) => handleSelectMunicipality(e.target.value)}>
                      <option value="" hidden>
                        Select spatial layer
                      </option>
                      <option value="metros" disabled className="font-bold text-lg text-black">
                        Metros
                      </option>
                      {metros?.map((e) => {
                        return (
                          <option key={e.cat_b} value={e.cat_b}>
                            {e.name}
                          </option>
                        );
                      })}
                      <option value="local" disabled className="font-bold text-lg text-black">
                        Local Municipalities
                      </option>
                      {localMunicipalities?.map((e) => {
                        if (e.name === "Emalahleni") {
                          return (
                            <option key={e.cat_b} value={e.cat_b}>
                              {e.name + " (" + e.province + ")"}
                            </option>
                          );
                        } else {
                          return (
                            <option key={e.cat_b} value={e.cat_b}>
                              {e.name}
                            </option>
                          );
                        }
                      })}
                    </select>
                  )}
                  {/* Municipal Select Process*/}

                  {/* District Select Process*/}
                  {selectedSpatialLayer?.id === "district" && (
                    <select className="form-control hsrc-form-select border m-10" name="district" value={selectedPlace?.id} onChange={(e) => handleSelectDistrict(e.target.value)}>
                      <option value="" hidden>
                        Select spatial layer
                      </option>
                      {groupedDistricts.map((group) => (
                        <>
                          <option value="metros" disabled className="font-bold text-lg text-black">
                            {group.province_n}
                          </option>
                          {group.districts.map((district) => (
                            <option key={district.id} value={district.id}>
                              {district.name}
                            </option>
                          ))}
                        </>
                      ))}
                    </select>
                  )}
                  {/* District Select Process*/}

                  {/* Province Select Process*/}
                  {selectedSpatialLayer?.id === "province" && (
                    <select className="form-control hsrc-form-select border" name="province" value={selectedPlace?.id} onChange={(e) => handleSelectProvince(e.target.value)}>
                      <option value="" hidden>
                        Select spatial layer
                      </option>
                      {provinces?.map((e) => {
                        return (
                          <option key={e.id} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  {/* Province Select Process*/}
                </div>
              </div>
            </div>
          </div>

          <NavLink to={`${routeConstants.DASHBOARDS}/${dashboardRoutes.JOBS_GROWTH}`}>
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: 50,
                padding: 8,
                paddingLeft: 14,
                color: "#F0A400",
                marginTop: 50,
              }}
              className="font-sans"
              disabled={!selectedSpatialValue}
            >
              Go To Dashboards
              <ArrowForwardIosIcon />
            </button>
          </NavLink>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default DashboardLandingPage;
