import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis, ZAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, industryPotentialDataAtom, industryPotentialFilterAtom, selectedFiltersAtom, selectedSpatialLayerAtom, yearsRangeAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import { numberFormatter } from "../../shared/utils";
import styles from "../../pdfs/styles/PdfStyles";

function IndustryPotentialPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(industryPotentialDataAtom);
  const [industryFilter] = useAtom(industryPotentialFilterAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 1500);
  }, []);

  return (
    <>
      {isLoading || !yearsRange ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#009640' }}>Industry Diagnostic</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>Industry Potential: Four Quadrants</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.subheading}>Start year: {selectedFilters?.startYear} End year: {selectedFilters?.endYear} | {industryFilter}</Text>
              </View>
              <View style={styles.section}>
                <Image style={{ ...styles.image, marginTop: 10 }} src={chartImageUrl} />
              </View>
              <View style={{ ...styles.legendSection, marginBottom: 10 }}>
                {data[0]?.plot_details[0]["1"][0].data.sort((a, b) => b.z - a.z).map((legendItem, index) => (
                  <View key={index} style={{ ...styles.legendLine, marginBottom: 1 }}>
                    <View style={{ ...styles.legend, backgroundColor: legendItem.color }}></View>
                    <Text style={{ ...styles.legendText, fontSize: 9 }}>{legendItem.point_label}</Text>
                  </View>
                ))}
              </View>
              <View>
                <Text break style={styles.paragraph}>
                  Which sectors show the most potential in {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  The four quadrants is a tool to sort economic performance for industries across three dimensions: size of industry (bubble size), the location quotient (LQ) or industry specialisation (x-axis, horizontal) and jobs growth (y-axis, vertical). The LQ is a statistical way of a way of quantifying how concentrated a particular industry is (relative to the national average) and therefore the extent of specialisation. Together, these dimensions are indicative of ‘winners’ and ‘losers’ where industries in the top right (i.e. solid bet) display the greatest potential. The industry toggle allows the quadrants model to be rerun to at the level of sub-sectors for any industry.
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef}>
          <ResponsiveContainer width={1100} height={400}>
            <ScatterChart
              width={730}
              height={250}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 26,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                type='number'
                dataKey='x'
                domain={[0, 3]}
                label={{
                  value: data[0]?.plot_details[0]["1"][0].x_label,
                  dy: 32,
                }}
              />
              <YAxis
                tickFormatter={(value) => numberFormatter(value, 2)}
                domain={["auto", "auto"]}
                type='number'
                dataKey='y'
                label={{
                  value: data[0]?.plot_details[0]["1"][0].y_label,
                  angle: -90,
                  dx: -50,
                }}
              />
              <ZAxis dataKey='z' range={[200, 1000]} />
              <Scatter data={data[0]?.plot_details[0]["1"][0].data}>
                {data[0]?.plot_details[0]["1"][0].data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color ?? "#e69d00"} />
                ))}
              </Scatter>
              <ReferenceLine y={0} stroke='#000000' />
              <ReferenceLine x={1} stroke='#000000' />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

export default IndustryPotentialPdf;
