import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, selectedFiltersAtom, selectedSpatialLayerAtom, wageDistributionOverviewDataAtom, yearsRangeAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import { numberFormatter } from "../../shared/utils";
import styles from "../../pdfs/styles/PdfStyles";

function WageDistributionOverviewPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(wageDistributionOverviewDataAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();
  const COLORS = ["#e69d00", "#84746c"];

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 1500);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>
                {selectedSpatialLayer?.name} | {displayMunicname}
              </Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: "#F0A400" }}>Overview</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>WAGE DISTRIBUTION</Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
              </View>
              <View style={styles.section}>
                <Image style={styles.image} src={chartImageUrl} />
              </View>
              <View>
                <Text style={styles.paragraph}>What do jobs pay for people working in {displayMunicname}?</Text>
                <Text style={styles.paragraph}>The wage distribution shows the number of people who earn at a particular level in Rands per month. The level of earnings is closely related to demand-side factors such as sector of employment as well as supply-side factors such as worker experience, education and occupation. The number of people in each wage category is important to bear in mind considering the local population that these jobs support.</Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef}>
          <ResponsiveContainer width={1100} height={525}>
            <BarChart
              data={data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 125,
              }}
            >
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <XAxis
                interval={0}
                label={{
                  value: "Wage Bands",
                  dy: 130,
                }}
                dataKey="wage_band"
                tick={{
                  fontSize: 18,
                  textAnchor: "end",
                  dy: 2,
                  dx: -5,
                  angle: -90,
                }}
                tickCount={14}
              />
              <YAxis tickFormatter={(value) => numberFormatter(value, 2)} label={{ value: `Total Jobs: ${yearsRange[yearsRange.length - 1]}`, angle: -90, dx: -50 }} />
              <Bar dataKey="value">
                {data?.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

export default WageDistributionOverviewPdf;
