import { atomWithStorage } from "jotai/utils";

const groupedMunicipalitiesAtom = atomWithStorage("groupedMunicipalities", []);
const jobsGrowthGroupedMunicipalitiesAtom = atomWithStorage("jobsGrowthGroupedMunicipalities", []);
const imageResponseAtom = atomWithStorage("imageResponse", null);

const municipalitiesAtom = atomWithStorage("municipalities", []);

// Dashboard Filter fields
const selectedMunicipalityAtom = atomWithStorage("selectedMunicipality", null);
const selectedPlaceAtom = atomWithStorage("selectedPlace", null);
const displayMunicNameAtom = atomWithStorage("displayMunicName", null);
const selectedFiltersAtom = atomWithStorage("selectedFilters", { isNationalDisplayed: true, isMetroDisplayed: false, isProvinceDisplayed: false, isSimilarSizeDisplayed: false, baseYear: "2014", startYear: "2014", endYear: "2023", singleYear: "2023" });
const yearsRangeAtom = atomWithStorage("yearsRange", []);
const spatialParameterAtom = atomWithStorage("spatialParameter", null);
const selectedSpatialValueAtom = atomWithStorage("selectedSpatialValue", null);
const selectedSpatialLayerAtom = atomWithStorage("selectedSpatialLayer", null);
const sic7Atom = atomWithStorage("sic7", 0);
const sic7StringAtom = atomWithStorage("sic7String", "");
const growthBySectorIndustryFilterAtom = atomWithStorage("growthBySectorIndustryFilter", "Manufacturing");

// Dashboard Data
const industryStructureDataAtom = atomWithStorage("industryStructureData", []);
const totalJobsGrowthDataAtom = atomWithStorage("totalJobsGrowthData", []);
const newJobsDataAtom = atomWithStorage("newJobsData", []);
const jobsGrowthDataAtom = atomWithStorage("jobsGrowthData", []);
const wageDistributionOverviewDataAtom = atomWithStorage("wageDistributionsData", []);
const growthBySectorDataAtom = atomWithStorage("growthBySectorData", []);
const growthBySectorMainDataAtom = atomWithStorage("growthBySectorMainData", []);
const growthBySectorOtherDataAtom = atomWithStorage("growthBySectorOtherData", []);
const growthBySectorSic7Atom = atomWithStorage("growthBySectorSic7", "-3");
const industryPotentialDataAtom = atomWithStorage("industryPotentialData", []);
const industryPotentialFilterAtom = atomWithStorage("industryPotentialFilter", "All Industries");
const industryPotentialSic7Atom = atomWithStorage("industryPotentialSic7", "");
const industryProfileDataAtom = atomWithStorage("industryProfileData", []);
const industryProfileIndustryTotalAtom = atomWithStorage("industryProfileIndustryTotal", 0);
const exportersDataAtom = atomWithStorage("exportersData", []);
const multiEstablishmentFirmsDataAtom = atomWithStorage("multiEstablishmentFirmsData", []);
const jobDensityMaxValueAtom = atomWithStorage("jobDensityMaxValue", 0);
const jobDensityMinValueAtom = atomWithStorage("jobDensityMinValue", 0);
const jobDensityMapDataAtom = atomWithStorage("jobDensityMapData", null);
const jobDensityMapDataGreyAtom = atomWithStorage("jobDensityMapDataGrey", null);
const jobDensityReportKeyAtom = atomWithStorage("jobDensityReportKey", 1);
const changeInMetroMaxJobsValueAtom = atomWithStorage("changeInMetroMaxJobsValue", 0);
const changeInMetroMinJobsValueAtom = atomWithStorage("changeInMetroMinJobsValue", 0);
const changeInMetroMaxFirmsValueAtom = atomWithStorage("changeInMetroMaxFirmsValue", 0);
const changeInMetroMinFirmsValueAtom = atomWithStorage("changeInMetroMinFirmsValue", 0);
const changeInMetroMapJobsDataAtom = atomWithStorage("changeInMetroMapJobsData", null);
const changeInMetroMapJobsDataGreyAtom = atomWithStorage("changeInMetroMapJobsDataGrey", null);
const changeInMetroMapFirmsDataAtom = atomWithStorage("changeInMetroMapFirmsData", null);
const changeInMetroMapFirmsDataGreyAtom = atomWithStorage("changeInMetroMapFirmsDataGrey", null);
const changeInMetroReportKeyAtom = atomWithStorage("changeInMetroReportKey", 1);
const wageInequalityDataAtom = atomWithStorage("wageInequalityData", []);
const wageLevelsDataAtom = atomWithStorage("wageInequalityData", []);
const jobsForYouthDataAtom = atomWithStorage("jobsForYouthData", []);
const wageDistributionFilterAtom = atomWithStorage("wageDistributionFilter", "All Industries");
const wageDistributionDataAtom = atomWithStorage("wageDistributionData", []);
const wageDistributionUniqueLabelsAtom = atomWithStorage("wageDistributionUniqueLabels", []);
const industriesDataAtom = atomWithStorage("industriesData", []);

export {
  groupedMunicipalitiesAtom,
  jobsGrowthGroupedMunicipalitiesAtom,
  imageResponseAtom,
  selectedMunicipalityAtom,
  displayMunicNameAtom,
  selectedFiltersAtom,
  totalJobsGrowthDataAtom,
  newJobsDataAtom,
  industryStructureDataAtom,
  yearsRangeAtom,
  jobsGrowthDataAtom,
  wageDistributionOverviewDataAtom,
  growthBySectorMainDataAtom,
  growthBySectorOtherDataAtom,
  growthBySectorIndustryFilterAtom,
  growthBySectorDataAtom,
  growthBySectorSic7Atom,
  industryProfileDataAtom,
  industryProfileIndustryTotalAtom,
  industryPotentialDataAtom,
  industryPotentialFilterAtom,
  industryPotentialSic7Atom,
  exportersDataAtom,
  multiEstablishmentFirmsDataAtom,
  jobDensityMaxValueAtom,
  jobDensityMinValueAtom,
  jobDensityMapDataAtom,
  jobDensityMapDataGreyAtom,
  jobDensityReportKeyAtom,
  wageInequalityDataAtom,
  wageLevelsDataAtom,
  jobsForYouthDataAtom,
  wageDistributionDataAtom,
  wageDistributionFilterAtom,
  wageDistributionUniqueLabelsAtom,
  selectedPlaceAtom,
  spatialParameterAtom,
  selectedSpatialValueAtom,
  selectedSpatialLayerAtom,
  sic7Atom,
  sic7StringAtom,
  industriesDataAtom,
  municipalitiesAtom,
  changeInMetroMaxJobsValueAtom,
  changeInMetroMinJobsValueAtom,
  changeInMetroMaxFirmsValueAtom,
  changeInMetroMinFirmsValueAtom,
  changeInMetroMapJobsDataAtom,
  changeInMetroMapJobsDataGreyAtom,
  changeInMetroMapFirmsDataAtom,
  changeInMetroMapFirmsDataGreyAtom,
  changeInMetroReportKeyAtom,
};
