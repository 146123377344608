import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Box, CircularProgress } from "@mui/material";
import { useAtom } from "jotai";
import { selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, spatialParameterAtom, wageInequalityDataAtom } from "../../../shared/state/atoms";

function WageInequality() {
  const [data, setData] = useAtom(wageInequalityDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-wage-inequality/?${spatialParameter}=${selectedSpatialValue}`);
        }
        if (response) {
          setData(
            response?.data.map((wage) => ({
              ...wage,
            }))
          );
          setErrorMessage(null);
        } else {
          setData([]);
          setErrorMessage(`No Data available`);
        }
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      }
    }

    if (spatialParameter && selectedSpatialValue) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialValue]);

  const determineLineColor = (legendLabel) => {
    const lowercasedLabel = legendLabel.toLowerCase();

    switch (true) {
      case lowercasedLabel.includes("selected"):
        return "#f0a400";
      case lowercasedLabel.includes("highest"):
        return "black";
      case lowercasedLabel.includes("lowest"):
        return "#84746c";
      default:
        return "#84746c";
    }
  };

  const uniqueLabels = data.reduce((acc, curr) => {
    curr.line_details.forEach((item) => {
      Object.values(item).forEach((value) => {
        if (!acc.some((x) => x.label === value[0].line_label)) {
          acc.push({ label: value[0].line_label, color: determineLineColor(value[0].legend_label) });
        }
      });
    });
    return acc;
  }, []);

  const uniqueLegendLabels = data.reduce((acc, curr) => {
    curr.line_details.forEach((item) => {
      Object.values(item).forEach((value) => {
        if (!acc.includes(value[0].legend_label)) {
          acc.push(value[0].legend_label);
        }
      });
    });
    return acc;
  }, []);

  const newData = data.map((item) => {
    const graphLabel = item.graph_label;
    const lines = item.line_details.reduce((acc, lines) => [...acc, ...Object.values(lines)], []);
    const yearValues = {};

    // eslint-disable-next-line array-callback-return
    lines.map((line) => {
      line.forEach((lineItem) => {
        lineItem.data.forEach((date) => {
          if (!yearValues[date.year]) {
            yearValues[date.year] = {
              year: date.year,
            };
          }

          yearValues[date.year][lineItem.line_label] = date.value;
        });
      });
    });

    return {
      graph_label: graphLabel,
      data: Object.values(yearValues),
    };
  });

  const COLOURS = ["#f0a400", "#84746c", "black"];

  const CustomTooltip = ({ active, payload, label }) => {
    payload?.sort((a, b) => b.value - a.value);
    const formattedMunicNumber = payload[0]?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const formattedLowestNumber = payload[1]?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const formattedHighestNumber = payload[2]?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white">
          <p className="m-0">{label}</p>
          <p className="m-0" style={{ color: payload[0]?.color }}>
            {payload[0]?.name} : {formattedMunicNumber}
          </p>
          <p className="m-0" style={{ color: payload[1]?.color }}>
            {payload[1]?.name} : {formattedLowestNumber}
          </p>
          <p className="m-0" style={{ color: payload[2]?.color }}>
            {payload[2]?.name} : {formattedHighestNumber}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Equitable Economies
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">{newData[0]?.graph_label}</div>

        {!errorMessage ? (
          <div>
            {isLoading && (
              <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                  <CircularProgress color="success" size="3rem" />
                </Box>
              </div>
            )}
            <ResponsiveContainer width="90%" height={400}>
              <LineChart
                data={newData[0]?.data}
                margin={{
                  top: 10,
                  right: 50,
                  left: 100,
                  bottom: 26,
                }}
                animationDuration={1000}
              >
                <XAxis dataKey="year" label={{ value: data[0]?.x_axis_label, dy: 32 }} />
                <YAxis domain={["auto", "auto"]} label={{ value: data[0]?.y_axis_label, angle: -90, dx: -50 }} />
                <CartesianGrid strokeDasharray="3" vertical={false} />
                {!isLoading && uniqueLabels.map((line, index) => <Line key={index} type="monotone" dataKey={`${line.label}`} stroke={line.color} strokeWidth={3} />)}
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
            <div className=" ml-16">
              {uniqueLegendLabels.map((label, index) => (
                <div className="flex mb-2 ml-16" key={label}>
                  <div className={`w-8 mx-8`} style={{ backgroundColor: COLOURS[index] }} />
                  <div>{label}</div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default WageInequality;
