import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { NavLink } from "react-router-dom";
import { routeConstants } from "../../../shared/constants";

const ResourceCard = ({ resource }) => {
  return (
    <div className="flex flex-row">
      <div className="flex">
        <img style={{ height: "293px" }} className="drop-shadow-lg" src={resource?.thumbnail_image_206x293?.full_url} alt="Cover Image of PDF" />
      </div>
      <div className="flex flex-1 ml-10 flex-col justify-between">
        <div>
          <h2
            style={{
              fontSize: 32,
              fontWeight: "bold",
            }}
            className="font-sans pb-5"
          >
            {resource?.title}
          </h2>
          <p
            className="font-sans"
            style={{
              fontSize: 16,
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            {resource?.teaser}
          </p>
        </div>
        <div>
          <NavLink
            to={{
              pathname: `${routeConstants.RESOURCES}/${resource.meta?.slug}`,
              state: { resource: resource },
            }}
          >
            <button
              style={{
                border: "2px solid #F0A400",
                borderRadius: 50,
                padding: 8,
                paddingLeft: 14,
                color: "#F0A400",
                fontWeight: 700,
                fontSize: 14,
              }}
              className="font-sans"
            >
              Read More
              <ArrowForwardIosIcon className="ml-2" fontSize="10" />
            </button>
          </NavLink>
          <button
            style={{
              border: "2px solid #F0A400",
              borderRadius: 50,
              padding: 8,
              paddingLeft: 14,
              color: "white",
              backgroundColor: "#F0A400",
              fontWeight: 700,
              fontSize: 14,
            }}
            className="font-sans ml-2"
            onClick={() => window.open(resource?.access_document?.meta?.download_url, "_blank")}
          >
            Access Resource
            <ArrowForwardIosIcon className="ml-2" fontSize="10" />
          </button>
        </div>
      </div>
      <div className="flex ml-10 flex-col" style={{ paddingTop: "44px" }}>
        <div className="flex flex-col">
          <div
            className="font-sans"
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: "#F0A400",
            }}
          >
            Author(s)
          </div>
          <div>
            {resource?.authors?.map((item) => {
              return (
                <div className="font-sans" style={{ fontSize: 11, fontWeight: 400, lineHeight: "14px" }}>
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col">
          <div
            className="font-sans"
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: "#F0A400",
            }}
          >
            Organisation(s)
          </div>
          <div>
            {resource?.organisations?.map((item) => {
              return (
                <div className="font-sans" style={{ fontSize: 11, fontWeight: 400, lineHeight: "14px" }}>
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col">
          <div
            className="font-sans"
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: "#F0A400",
            }}
          >
            Publication Date
          </div>
          <div>
            <div className="font-sans" style={{ fontSize: 11, fontWeight: 400, lineHeight: "14px" }}>
              {resource?.publication_date}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div
            className="font-sans"
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: "#F0A400",
            }}
          >
            Resource Type
          </div>
          <div>
            <div className="font-sans" style={{ fontSize: 11, fontWeight: 400, lineHeight: "14px" }}>
              {resource?.resource_type}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div
            className="font-sans"
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: "#F0A400",
            }}
          >
            Keywords
          </div>
          <div>
            {resource?.keywords?.map((item) => {
              return (
                <div className="font-sans" style={{ fontSize: 11, fontWeight: 400, lineHeight: "14px" }}>
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceCard;
