import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer"
import { wageInequalityDataAtom, displayMunicNameAtom, imageResponseAtom, selectedSpatialLayerAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import styles from "../../pdfs/styles/PdfStyles";

function WageInequalityPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(wageInequalityDataAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 1500);
  }, []);

  const determineLineColor = (legendLabel) => {
    const lowercasedLabel = legendLabel.toLowerCase();

    switch (true) {
      case lowercasedLabel.includes("selected"):
        return "#f0a400";
      case lowercasedLabel.includes("highest"):
        return "black";
      case lowercasedLabel.includes("lowest"):
        return "#84746c";
      default:
        return "#84746c";
    }
  };

  const uniqueLabels = data.reduce((acc, curr) => {
    curr.line_details.forEach((item) => {
      Object.values(item).forEach((value) => {
        if (!acc.some((x) => x.label === value[0].line_label)) {
          acc.push({ label: value[0].line_label, color: determineLineColor(value[0].legend_label) });
        }
      });
    });
    return acc;
  }, []);

  const uniqueLegendLabels = data.reduce((acc, curr) => {
    curr.line_details.forEach((item) => {
      Object.values(item).forEach((value) => {
        if (!acc.includes(value[0].legend_label)) {
          acc.push(value[0].legend_label);
        }
      });
    });
    return acc;
  }, []);

  const newData = data.map((item) => {
    const graphLabel = item.graph_label;
    const lines = item.line_details.reduce((acc, lines) => [...acc, ...Object.values(lines)], []);
    const yearValues = {};

    // eslint-disable-next-line array-callback-return
    lines.map((line) => {
      line.forEach((lineItem) => {
        lineItem.data.forEach((date) => {
          if (!yearValues[date.year]) {
            yearValues[date.year] = {
              year: date.year,
            };
          }

          yearValues[date.year][lineItem.line_label] = date.value;
        });
      });
    });

    return {
      graph_label: graphLabel,
      data: Object.values(yearValues),
    };
  });

  const COLOURS = ["#f0a400", "#84746c", "black"];

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#84746C' }}>Equitable Economies</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>Wage Inequality: Gini</Text>
              </View>
              <View style={styles.section}>
                <Image style={styles.image} src={chartImageUrl} />
              </View>
              <View style={{ ...styles.legendSection, marginBottom: 20, marginTop: 20 }}>
                <Text style={{ ...styles.legendText, position: 'absolute', top: -20, left: 220, fontSize: 14, textDecoration: 'underline', marginBottom: 2 }}>{newData[0]?.data[newData[0].data.length - 1].year}</Text>
                {Object.entries(newData[0]?.data[newData[0].data.length - 1]).map(([key, value], index) => {
                  if (key !== 'year') {
                    return (
                      <View style={{ ...styles.legendLine, width: 300, justifyContent: 'space-between' }}>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <View style={{ ...styles.legend, backgroundColor: COLOURS[index - 1] }} />
                          <Text style={styles.legendText}>{key}</Text>
                        </View>
                        <Text style={{ ...styles.legendText, width: 80 }}>{value}</Text>
                      </View>
                    )
                  }
                })}
              </View>
              <View>
                <Text style={styles.paragraph}>
                  What is the level of wage inequality in {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  The gini coefficient is a statistical calculation of inequality where a value of 0 represents absolute equality and a value of 100 absolute inequality. The wage gini calculated from tax data is a measure of inequality amongst wage earners who work in the formal sector and is very different to measuring income inequality amongst all workers or amongst households (which would also include the unemployed). Wage inequality remains a fundamental driver of income inequality in South Africa.
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef}>
          <ResponsiveContainer width={1000} height={400}>
            <LineChart
              data={newData[0]?.data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 26,
              }}
              animationDuration={1000}
            >
              <XAxis dataKey='year' label={{ value: data[0]?.x_axis_label, dy: 32 }} />
              <YAxis domain={["auto", "auto"]} label={{ value: data[0]?.y_axis_label, angle: -90, dx: -50 }} />
              <CartesianGrid strokeDasharray='3' vertical={false} />
              {uniqueLabels.map((line, index) => (
                <Line key={index} type='monotone' dataKey={`${line.label}`} stroke={line.color} strokeWidth={3} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

export default WageInequalityPdf;
