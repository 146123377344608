import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { useAtom } from "jotai";
import { Document, Page, View, Image, PDFViewer, Text } from "@react-pdf/renderer";
import { displayMunicNameAtom, imageResponseAtom, jobsForYouthDataAtom, selectedFiltersAtom, selectedSpatialLayerAtom } from "../../shared/state/atoms";
import { Box, CircularProgress } from "@mui/material";
import { numberFormatter } from "../../shared/utils";
import styles from "../../pdfs/styles/PdfStyles";

function JobsForYouthPdf() {
  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [data] = useAtom(jobsForYouthDataAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [imageResponse] = useAtom(imageResponseAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [chartImageUrl, setChartImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef();
  const COLORS = ["#f0a400", "#009640"];

  useEffect(() => {
    setTimeout(() => {
      toPng(chartRef.current)
        .then((dataUrl) => {
          setChartImageUrl(dataUrl);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Failed to convert node to image:", err);
        });
    }, 1500);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
          <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
            <CircularProgress color="success" size="3rem" />
          </Box>
        </div>
      ) : chartImageUrl ? (
        <PDFViewer style={{ flex: 1, width: "100%", height: "100vh" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
              <Text style={styles.headerText}>{selectedSpatialLayer?.name} | {displayMunicname}</Text>
              <View>
                <Text style={{ ...styles.header, backgroundColor: '#84746C' }}>Equitable Economies</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>Jobs For Youth </Text>
              </View>
              <View style={{ ...styles.section, paddingBottom: 0 }}>
                <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
              </View>
              <View style={styles.section}>
                <Image style={{ ...styles.image, marginTop: 20 }} src={chartImageUrl} />
              </View>
              <View>
                <Text style={styles.paragraph}>
                  Which industries offer the most jobs for youth in {displayMunicname}?
                </Text>
                <Text style={styles.paragraph}>
                  Industries which create more job opportunities for young people can also play a development role within society. The total number of jobs for youth (aged 15 - 25) related to the intensity of youth employment in that sector along with total industry size. A higher youth jobs intensity (share of jobs for youth within the sector) is preferable although sheer industry size can lead to jobs for youth because of volume.
                </Text>
              </View>
              <View style={styles.footerContainer}>
                <Text style={styles.footer}>
                  www.spatialtaxdata.org.za
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : null}
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={chartRef}>
          {data.map((graph, index) => (
            <div className='mb-5' key={index}>
              <div className='px-10 font-bold flex justify-center'>{graph.graph_label}</div>
              <BarChart
                data={graph.data}
                width={1000}
                height={400}
                margin={{
                  top: 20,
                  right: 50,
                  left: 100,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray='3' vertical={false} />
                <XAxis dataKey='point_short_label' />
                <YAxis
                  tickFormatter={(value) => numberFormatter(value, 2)}
                  label={{
                    value: "Total Jobs",
                    angle: -90,
                    dx: -50,
                  }}
                />
                <Bar dataKey='y' fill={COLORS[index]} />
              </BarChart>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default JobsForYouthPdf; 
