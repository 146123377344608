import { Box, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { numberFormatter } from "../../../shared/utils";
import { useAtom } from "jotai";
import { selectedFiltersAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom, spatialParameterAtom, wageDistributionOverviewDataAtom, yearsRangeAtom } from "../../../shared/state/atoms";

function WageDistributionOverview() {
  const [data, setData] = useAtom(wageDistributionOverviewDataAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const COLORS = ["#e69d00", "#84746c"];

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/dashboard-wage-distribution/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`);
        }
        if (response) {
          setData(response.data);
          setErrorMessage(null);
        } else {
          setData([]);
          setErrorMessage(`No Data available for ${selectedFilters?.singleYear}`);
        }

        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else if (error?.response == null) {
          setIsLoading(false);
          setErrorMessage("No Data");
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      }
    }

    if (spatialParameter && selectedSpatialValue && selectedFilters) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter, selectedSpatialLayer, selectedSpatialValue, yearsRange, selectedFilters]);

  const CustomTooltip = ({ active, payload, label }) => {
    const formattedNumber = payload[0]?.payload.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white" key={label}>
          <p className="m-0">{label}</p>
          <p className="m-0">Total Jobs: {formattedNumber}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">
          {selectedSpatialLayer?.name} / {selectedPlace?.name} / Overview
        </div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">Wage Distribution {yearsRange ? selectedFilters.singleYear : ""}</div>

        {!errorMessage ? (
          <div>
            {isLoading && (
              <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                  <CircularProgress color="success" size="3rem" />
                </Box>
              </div>
            )}
            <ResponsiveContainer width="90%" height={500}>
              <BarChart
                data={data}
                margin={{
                  top: 10,
                  right: 50,
                  left: 100,
                  bottom: 100,
                }}
              >
                <CartesianGrid strokeDasharray="3" vertical={false} />
                <XAxis
                  interval={0}
                  label={{
                    value: "Wage Bands",
                    dy: 106,
                  }}
                  dataKey="wage_band"
                  tick={{
                    fontSize: 12,
                    textAnchor: "end",
                    dy: 2,
                    dx: -5,
                    angle: -90,
                  }}
                  tickCount={14}
                />
                <YAxis tickFormatter={(value) => numberFormatter(value, 2)} label={{ value: `Total Jobs: ${yearsRange[yearsRange.length - 1]}`, angle: -90, dx: -50 }} />
                <Tooltip content={<CustomTooltip />} />
                {!isLoading && (
                  <Bar dataKey="value">
                    {data?.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Bar>
                )}
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default WageDistributionOverview;
